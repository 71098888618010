export const tableConf = {
    searchFields: ['id', 'first_name', 'last_name', 'nickname', 'user__username', 'user__email', 'document']
}

const customerFullName = (el) => {
    return `${el.firstName || 'SEM NOME'} ${el.lastName || ''}`
}

export const tableHeader = [
    {
        field: 'id',
        label: 'ID',
        dataType: 'integer',
        sortable: true
    },
    {
        field: 'active',
        label: 'Ativo',
        dataType: 'boolean',
        sortable: true
    },
    {
        field: 'createdAt',
        label: 'Data de Criação',
        dataType: 'datetime',
        sortable: true
    },
    {
        field: 'email',
        label: 'E-mail',
        sortable: true
    },
    {
        field: 'name',
        label: 'Nome',
        dataType: 'function',
        renderFunction: customerFullName,
        sortable: true
    },
];
