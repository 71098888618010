import React from 'react';

import { Edit as GenericEdit } from '../Generic/Edit'

import {
    Read,
    Delete,
    Update,
    entityReducer,
    icon,
    displayPropertyName,
    listPath,
    verboseName,
    verboseNamePlural,
    editFormName,
    ValidateUpdate,
    mountEditPanes,
    preFilter,
    preDispatch,
    permission
} from './Config'

import './style.css';

export default function Edit(props) {
    return (
        <GenericEdit
            {...props}

            Read={Read}
            Update={Update}
            Delete={Delete}

            verboseName={verboseName}
            verboseNamePlural={verboseNamePlural}
            icon={icon}
            listPath={listPath}
            displayPropertyName={displayPropertyName}
            
            mountEditPanes={mountEditPanes}
            preFilter={preFilter}
            preDispatch={preDispatch}

            form={editFormName}
            validate={ValidateUpdate}
            entityReducer={entityReducer}
            permission={permission}
        />
    )
}
