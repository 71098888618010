import React from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputString,
    renderInputPassword,
    renderInputEntity,
    renderInputDocument
} from '../../../../Utils/RenderUtils';

import {
    normalizeDocument
} from '../../../../Utils/NormalizeUtils';

export default function Tab01(props) {

    return (
        <React.Fragment>
            <FormSection fluid color="green" title="Informações de Acesso">
                <Form.Group widths='equal'>
                    <Field name="email" label="E-mail" component={renderInputString} />
                    <Field name="firstName" label="Nome" component={renderInputString} />
                    <Field name="lastName" label="Sobrenome" component={renderInputString} />
                </Form.Group>
                <Form.Group widths='3'>
                    <Field name="entity" label="Tipo Pessoa" component={renderInputEntity} />
                    <Field name="document" label="CPF/CNPJ" component={renderInputDocument} normalize={normalizeDocument} format={normalizeDocument} />
                </Form.Group>
                <Form.Group widths='3'>
                    <Field name="password" label="Senha" component={renderInputPassword} />
                    <Field name="passwordConfirmation" label="Confirmação de Senha" component={renderInputPassword} />
                </Form.Group>
            </FormSection>
        </React.Fragment>
    )
}
