import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Field } from 'redux-form';
import { Grid } from 'semantic-ui-react';

import { DEFAULT_TABLE_CONF } from '../../../../Context/GlobalContext';

import FormSection from '../../../../Components/Card/FormSection';
import PageLoader from '../../../../Components/Card/PageLoader';

import { List as ListPropertyGroup } from '../../../../Actions/PropertyGroup';

import {
    renderSelectPropertyGroup,
} from '../../../../Utils/RenderUtils';

export default function Tab05(props) {
    const formValues = useSelector(state => state.form[props.form].values);
    const propertyGroups = useSelector(state => state.propertyGroupList);

    const dispatch = useDispatch();

    useEffect(() => {
        if(!propertyGroups || !propertyGroups.results || propertyGroups.count > propertyGroups.results.length){
            const queryString = {
                ...DEFAULT_TABLE_CONF,
                ...{
                    rowsPerPage: 9999,
                    orderBy: 'name',
                    fields: ['uuid', 'name', 'properties']
                }
            }
    
            dispatch(ListPropertyGroup(queryString));
        }

        if(formValues.properties.length !== propertyGroups.count && propertyGroups && propertyGroups.results && propertyGroups.count === propertyGroups.results.length){
            let propertiesValues = [];

            propertyGroups.results.forEach((pg, index) => {
                propertiesValues[index] = null;

                pg.properties.forEach(prop => {
                    if(formValues.properties.includes(prop.uuid)){
                        propertiesValues[index] = prop.uuid;
                    }
                })
            });

            props.change('properties', propertiesValues);
        }
    }, null);

    if(propertyGroups.results){
        return (
            <React.Fragment>
                <FormSection fluid color="green" title="Propriedades">
                    <Grid>
                        <Grid.Row columns={4}>
                            {
                                propertyGroups.results.map((pg, index) => {
                                    return (
                                        <Grid.Column key={`select-field-${pg.uuid}`}>
                                            <Field name={`properties[${index}]`} label={pg.name} propertyGroup={pg} component={renderSelectPropertyGroup}/>
                                        </Grid.Column>
                                    )
                                })
                            }
                        </Grid.Row>
                    </Grid>
                </FormSection>
            </React.Fragment>
        )
    }

    return (
        <FormSection fluid color="green" title="Propriedades">
            <PageLoader />
        </FormSection>
    )
}
