import Tab01 from '../Components/UpdateForm/Tab01';
import Tab02 from '../Components/UpdateForm/Tab02';
import Tab03 from '../Components/UpdateForm/Tab03';
import Tab04 from '../Components/UpdateForm/Tab04';
import Tab05 from '../Components/UpdateForm/Tab05';

import { Update as UpdateImage } from '../../../Actions/Image';

export const preFilter = null;

export const preDispatch = (formValues, initialValues, dispatch) => {
    if(formValues.images){
        formValues.images.forEach((uuid, index) => {
            dispatch(UpdateImage(uuid, {sort: index}));
        })
    }

    return formValues;
}

export const mountEditPanes = (props) => {
    return [
        {
            menuItem: { key: 'general', icon: 'info', content: 'Informações Gerais' },
            render: () => <Tab01 {...props} />
        },
        {
            menuItem: { key: 'price', icon: 'dollar', content: 'Preço e Estoque' },
            render: () => <Tab02 {...props} />
        },
        {
            menuItem: { key: 'shipping', icon: 'shipping', content: 'Frete' },
            render: () => <Tab03 {...props} />
        },
        {
            menuItem: { key: 'images', icon: 'images', content: 'Fotos' },
            render: () => <Tab04 {...props} />
        },
        {
            menuItem: { key: 'property', icon: 'settings', content: 'Propriedades' },
            render: () => <Tab05 {...props} />
        }
    ]
}