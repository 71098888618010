import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import { GlobalStore } from './Context/GlobalContext';

import reducers from './Reducers';
import App from './App';

import 'react-toastify/dist/ReactToastify.min.css';
import 'semantic-ui-css/semantic.min.css';
import './apicommerce.css';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));

//const store = createStore(reducers, applyMiddleware(thunk));

ReactDOM.render(
    <GlobalStore>
        <Provider store={store}>
            <App />
        </Provider>
    </GlobalStore>,
    document.getElementById('root')
);
