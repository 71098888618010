import React, { useEffect } from 'react';
import { useDispatch, useSelector} from "react-redux";
import { Card, Button } from 'semantic-ui-react';

import * as Constants from '../../Constants';

import ImageDropzone from '../../Components/Input/ImageDropzone';

import { Read as ReadImage} from '../../Actions/Image';

export default function ImageCard(props){
    const { onClickNegative, extra, imageWidth, imageHeight, imageProps } = props;

    const images = useSelector(state => state.images);
    const dispatch = useDispatch();

    useEffect(() => {
        if(props.uuid && !images[props.uuid]){
            dispatch(ReadImage(props.uuid));
        }
    }, []);

    const buttons = (
        <div className='ui two buttons'>
            <Button basic color='green'>
                Editar
            </Button>
            <Button basic color='red' onClick={onClickNegative || (()=>{})}>
                Excluir
            </Button>
        </div>
    )

    if(props.uuid && images[props.uuid]){
        return (
            <Card
                fluid
                image={Constants.IMAGES_CTX+images[props.uuid].urls.default}
                meta={images[props.uuid].filename+"."+images[props.uuid].extension}
                extra={extra || buttons}
            />
        );
    }

    return <ImageDropzone
            imageWidth={imageWidth || 1000}
            imageHeight={imageHeight || 1000}
            imageProps={imageProps || {}}/>

}
