import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { Field } from 'redux-form';
import { Grid, Form, Button, Message } from 'semantic-ui-react';

import ApiCommerce from '../../../../Api/ApiCommerce';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputString,
    renderInputInteger,
    renderInputTimePicker
} from '../../../../Utils/RenderUtils';

import {
    normalizeInteger,
    normalizeUrl
} from '../../../../Utils/NormalizeUtils';

export default function Tab04(props) {
    const [ testEmail, setTestEmail ] = useState({
        loading: false
    });
    
    const formValues = useSelector(state => state.form[props.form].values)

    const requestTestEmail = (e) => {
        e.preventDefault();

        setTestEmail({loading: true});
        (new ApiCommerce()).post(`/email/test/`, formValues?.settings?.private?.email).then(r => {
            setTestEmail({
                loading: false,
                ...r.data
            });
        }).catch(err => {
            setTestEmail({
                loading: false,
                ...err.response.data
            });
        })
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={8}>
                    <FormSection fluid color="green" title="Horário de Funcionamento">
                        <Form.Group widths='equal'>
                            <Field name="settings.public.operation.week.sunday.start" label="Domingo (Início)" component={renderInputTimePicker} />
                            <Field name="settings.public.operation.week.sunday.end" label="Domingo (Fim)" component={renderInputTimePicker} />
                        </Form.Group>

                        <Form.Group widths='equal'>
                            <Field name="settings.public.operation.week.monday.start" label="Segunda (Início)" component={renderInputTimePicker} />
                            <Field name="settings.public.operation.week.monday.end" label="Segunda (Fim)" component={renderInputTimePicker} />
                        </Form.Group>

                        <Form.Group widths='equal'>
                            <Field name="settings.public.operation.week.tuesday.start" label="Terça (Início)" component={renderInputTimePicker} />
                            <Field name="settings.public.operation.week.tuesday.end" label="Terça (Fim)" component={renderInputTimePicker} />
                        </Form.Group>

                        <Form.Group widths='equal'>
                            <Field name="settings.public.operation.week.wednesday.start" label="Quarta (Início)" component={renderInputTimePicker} />
                            <Field name="settings.public.operation.week.wednesday.end" label="Quarta (Fim)" component={renderInputTimePicker} />
                        </Form.Group>

                        <Form.Group widths='equal'>
                            <Field name="settings.public.operation.week.thursday.start" label="Quinta (Início)" component={renderInputTimePicker} />
                            <Field name="settings.public.operation.week.thursday.end" label="Quinta (Fim)" component={renderInputTimePicker} />
                        </Form.Group>

                        <Form.Group widths='equal'>
                            <Field name="settings.public.operation.week.friday.start" label="Sexta (Início)" component={renderInputTimePicker} />
                            <Field name="settings.public.operation.week.friday.end" label="Sexta (Fim)" component={renderInputTimePicker} />
                        </Form.Group>

                        <Form.Group widths='equal'>
                            <Field name="settings.public.operation.week.saturday.start" label="Sábado (Início)" component={renderInputTimePicker} />
                            <Field name="settings.public.operation.week.saturday.end" label="Sábado (Fim)" component={renderInputTimePicker} />
                        </Form.Group>
                    </FormSection>
                </Grid.Column>

                <Grid.Column width={8}>
                    <Grid.Row>
                        <FormSection fluid color="blue" title="Operação">
                            <Form.Group widths='equal'>
                                <Field name="settings.public.operation.operationTime" label="Tempo de operação (minutos)" component={renderInputInteger} normalize={normalizeInteger}/>
                            </Form.Group>
                        </FormSection>
                    </Grid.Row>

                    <Grid.Row style={{marginTop: '20px'}}>
                        <FormSection fluid color="yellow" title="Redes Sociais">
                            <Form.Group widths='equal'>
                                <Field name="settings.public.template.socialMedia.facebook" label="Facebook" component={renderInputString} normalize={normalizeUrl}/>
                                <Field name="settings.public.template.socialMedia.instagram" label="Instagram" component={renderInputString} normalize={normalizeUrl}/>
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <Field name="settings.public.template.socialMedia.linkedin" label="LinkedIn" component={renderInputString} normalize={normalizeUrl}/>
                                <Field name="settings.public.template.socialMedia.youtube" label="Youtube" component={renderInputString} normalize={normalizeUrl}/>
                            </Form.Group>
                        </FormSection>
                    </Grid.Row>

                    <Grid.Row style={{marginTop: '20px'}}>
                        <FormSection fluid color="red" title="Envio de E-mail">
                            <Form.Group widths='equal'>
                                <Field name="settings.private.email.host" label="Host SMTP" component={renderInputString}/>
                                <Field name="settings.private.email.port" label="Porta TLS" component={renderInputString}/>
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <Field name="settings.private.email.username" label="Usuário" component={renderInputString}/>
                                <Field name="settings.private.email.password" label="Senha" component={renderInputString} type='password'/>
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <Field name="settings.private.email.from" label="Email Remetente" component={renderInputString}/>
                                <Button basic color='blue' loading={testEmail.loading} onClick={requestTestEmail}>
                                    Testar Envio de Email
                                </Button>
                            </Form.Group>


                            {(!testEmail.loading && testEmail.success !== undefined) &&
                            <Message
                                color={testEmail.success ? 'green' : 'red'}
                                header={testEmail.success ? 'Sucesso' : 'Falha'}
                                content={testEmail.success ? 'Verifique sua caixa de e-mail!' : testEmail.field ? 'Campos obrigatórios faltantes' : testEmail.detail}
                            />}
                        </FormSection>
                    </Grid.Row>

                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}
