import _ from 'lodash';

import React, { useContext } from 'react';

import { Button, Checkbox, Icon, Table } from 'semantic-ui-react';
import Merchant from '../Entity/Merchant';
import GlobalContext from '../../Context/GlobalContext';

import { datetimeIsoToPtBr } from '../../Utils/FunctionsUtils';

import { Link } from 'react-router-dom';

export default function DefaultTableBody(props) {    
    const { head, body, editPath } = props;

    const context = useContext(GlobalContext);

    const toggleRow = (row) => {
        if(_.remove(context.table.deleteList, (n) => {return n.uuid === row.uuid}).length === 0){
            context.table.deleteList.push(row);
        }
        
        context.change({
            table: {
                deleteList: context.table.deleteList
            }
        })
    }

    const clickDelete = entity => {
        context.change({
            deleteModalOpen: true,
            table: {
                deleteList: [entity]
            }
        })
    }
   
    const getTableActions = (labelId, row) => {
        let actions = [];

        if(props.permissions.update || props.permissions.read){
            actions.push(
                <Link to={`${editPath}/${row.uuid}`} className='item'>
                    <Button positive><Icon name={props.permissions.update ? 'edit': 'eye'} /></Button>
                </Link>
            )
        }

        if(((props.permissions.update || props.permissions.read) && props.permissions.delete)){
            actions.push(<Button.Or text='-'/>)
        }

        if(props.permissions.delete){
            actions.push(<Button negative onClick={() => {clickDelete(row)}}><Icon name='trash' /></Button>)
        }

        if(actions.length > 0){
            return (<Table.Cell key={`${labelId}-actions`}><Button.Group>{actions}</Button.Group></Table.Cell>)
        }

        return null;
    }

    if(body.results){
        return (
            <Table.Body>
                {body.results.map((row) => {
                    let isItemSelected = _.filter(context.table.deleteList, {uuid: row.uuid}).length > 0;
                    let labelId = `table-row-${row.uuid}`;
    
                    return (
                        <Table.Row
                        key={labelId}
                        active={isItemSelected || false}>
    
                            {(props.permissions.delete) &&
                                <Table.Cell key={`${labelId}-checkbox`}>
                                    <Checkbox checked={isItemSelected} onClick={() => toggleRow(row)}/>
                                </Table.Cell>
                            }

                            {
                                head.map((cell) => {
                                    let cellKey = `${labelId}-${cell.field}`;
    
                                    let align = cell.dataType === 'integer' || cell.dataType === 'decimal' ? 'center' : 'left';
    
                                    let prefix = cell.prefix || '';
                                    let suffix = cell.suffix || '';
                                    
                                    let value = row[cell.field];
                                    let className = cell.className || '';

                                    if(value !== null){
                                        if(cell.dataType === 'datetime'){
                                            value = datetimeIsoToPtBr(value);
                                        }
        
                                        if(cell.dataType === 'decimal'){
                                            value = value.toFixed(cell.toFixed || 2)
                                        }

                                        if(cell.dataType === 'array'){
                                            let mapValues = [];
                                            
                                            value.forEach(v => {
                                                if(cell.map[v]){
                                                    mapValues.push(cell.map[v])
                                                }
                                            })

                                            value = mapValues.join(' / ');
                                        }

                                        if(cell.dataType === 'map'){
                                            value = cell.map[value];
                                        }

                                        if(cell.dataType === 'function'){
                                            value = cell.renderFunction(row);
                                        }

                                        if(cell.dataType === 'boolean'){
                                            if(value === true){
                                                return (<Table.Cell className={className} key={cellKey} align={align}><Icon color='green' name='check'/></Table.Cell>);
                                            } else {
                                                return (<Table.Cell className={className} key={cellKey} align={align}><Icon color='red' name='delete'/></Table.Cell>);
                                            }
                                        }
        
                                        if(cell.dataType === 'merchant'){
                                            return (<Table.Cell className={className} key={cellKey} align={align}><Merchant uuid={value} field="tradingName"/></Table.Cell>);
                                        }
        
                                        return (<Table.Cell className={className} key={cellKey} align={align}>{prefix + value + suffix}</Table.Cell>);
                                    } else {
                                        value = '';
                                        if(cell.ifNull){
                                            value = cell.ifNull;
                                        }

                                        return (<Table.Cell className={className} key={cellKey} align={align}>{prefix + value + suffix}</Table.Cell>);
                                    }
                                })
                            }

                            {getTableActions(labelId, row)}

                        </Table.Row>
                    );
                })}
            </Table.Body>
        );
    }

    return null;
}
