import React from 'react';
import { useSelector } from "react-redux";
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputString,
    renderInputCheckbox,
    renderSelectMerchant,
    renderInputShippingKeySelect,
    renderInputDecimal,
    renderInputCheckboxKey
} from '../../../../Utils/RenderUtils';

import {
    normalizeZipcode,
    normalizeDecimal
} from '../../../../Utils/NormalizeUtils';

export default function Tab01(props) {
    const formValues = useSelector(state => state.form[props.form].values);

    const renderSpecificFields = () => {
        if(formValues && formValues.keys.includes('ZipRange')){
            return (
                <FormSection fluid color="yellow" title="Limitações">
                    <Form.Group widths='equal'>
                        <Field name="cubicCoefficient" label="Fator de Cubagem" component={renderInputDecimal} normalize={normalizeDecimal} />
                        <Field name="maxVolume" label="Volume Máximo (cm³)" component={renderInputDecimal} normalize={normalizeDecimal} />
                        <Field name="maxDimension" label="Dimensão Máxima (cm)" component={renderInputDecimal} normalize={normalizeDecimal} />
                        <Field name="maxWeight" label="Peso Máximo (g)" component={renderInputDecimal} normalize={normalizeDecimal} />
                    </Form.Group>
                </FormSection>
            )
        }

        return null;
    }

    return (
        <React.Fragment>
            <FormSection fluid color="green" title="Informações Gerais">
                <Form.Group widths={4}>
                    <Field name="active" label="Ativo" component={renderInputCheckbox} type="checkbox" />
                    <Field name="externalId" label="ID Externo (ERP)" component={renderInputString} />
                    <Field name="keys" label="Tipo" component={renderInputShippingKeySelect} />
                    <Field name="zipcode" label="Cep de Origem" component={renderInputString} normalize={normalizeZipcode}/>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Field name="name" label="Nome" component={renderInputString} />
                    <Field name="code" label="Código" component={renderInputString} />
                    <Field name="merchant" label="Loja" component={renderSelectMerchant} />
                    <Field name="packCost" label="Custo de Embalagem (R$)" component={renderInputDecimal} normalize={normalizeDecimal} />
                </Form.Group>
            </FormSection>

            <FormSection fluid color="blue" title="Operação">
                <Form.Group widths='equal'>
                    <Field name="keys" label="Entrega no Sábado?" keyValue="DELIVERY_SATURDAY" component={renderInputCheckboxKey} type="checkbox" />
                    <Field name="keys" label="Entrega no Domingo?" keyValue="DELIVERY_SUNDAY" component={renderInputCheckboxKey} type="checkbox" />
                </Form.Group>
            </FormSection>
            {renderSpecificFields()}
        </React.Fragment>
    )
}
