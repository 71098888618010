import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { Field } from 'redux-form';
import { Form, Button, Progress, Grid } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import { registerStore } from '../../../../Utils/CreateStore';

import {
    renderInputString,
    renderInputText,
    renderInputWysiwyg,
    renderInputCheckbox,
    renderMultiSelectAdministrator
} from '../../../../Utils/RenderUtils';

import { normalizeSlug } from '../../../../Utils/NormalizeUtils';

export default function Tab01(props) {
    const [progress, setProgress] = useState({});

    const dispatch = useDispatch();

    const setupStore = () => {
        if(window.confirm("Você tem certeza que deseja criar a estrutura dessa loja?") === true){
            registerStore(props.initialValues.uuid, dispatch, setProgress).then(response => {
                setProgress({
                    message: 'Loja cadastrada com sucesso!',
                    percent: 100
                })        
            })
        }
    }

    const loadRegisterStore = () => {
        if(progress.percent){
            return (
                <Progress percent={progress.percent} active success={progress.percent === 100} color='green'>
                    {progress.message} ({progress.percent.toFixed(0)}%)
                </Progress>
            )
        } else {
            return (
                <Grid centered>
                    <Grid.Row centered columns={5}>
                        <Grid.Column>
                            <Button basic color='green' onClick={((e)=>{e.preventDefault(); setupStore()})}>
                                Cadastar Dados
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            )
        }
    }

    return (
        <React.Fragment>
            {props.superAdmin && loadRegisterStore()}
  
            <FormSection fluid color="green" title="Informações">
                <Form.Group widths={3}>
                    <Field name="active" label="Ativo" component={renderInputCheckbox} type="checkbox" />
                    <Field name="externalId" label="Código Externo (ERP)" component={renderInputString} />
                </Form.Group>

                <Form.Group widths='equal'>
                    <Field name="tradingName" label="Nome Fantasia" component={renderInputString} />
                    <Field name="slug" label="URL" component={renderInputString} normalize={normalizeSlug} />
                    <Field name="email" label="Email" component={renderInputString} />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Field name="administrators" label="Administradores" component={renderMultiSelectAdministrator} />
                </Form.Group>
            </FormSection>

            <FormSection fluid color="blue" title="Descrições">
                <Form.Group widths='equal'>
                    <Field name="description" label="Descrição Completa" component={renderInputWysiwyg} />
                </Form.Group>

                <Form.Group widths={2}>
                    <Field name="fields.shortDescription" label="Descrição Simplificada" component={renderInputText} />
                </Form.Group>
            </FormSection>
        </React.Fragment>
    )
}
