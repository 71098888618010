import React from 'react';
import { Field } from 'redux-form';
import { useSelector } from "react-redux";
import { Grid, Form } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputString,
    renderInputInteger,
    renderInputDecimal
} from '../../../../Utils/RenderUtils';

import {
    normalizeInteger,
    normalizeDecimal
} from '../../../../Utils/NormalizeUtils';

export default function Tab05(props) {
    const allPermissions = useSelector(state => state.auth.administrator.permissions);
    const superAdmin = allPermissions?.superAdmin || false;

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={8}>
                    <Grid.Row>
                        <FormSection fluid color="green" title="Gateway de Pagamento">
                            <Form.Group widths='equal'>
                                <Field name="settings.private.payment.secretKey" label="Chave Privada" component={renderInputString}  readOnly={!superAdmin} />
                                <Field name="settings.public.payment.publicKey" label="Chave Pública" component={renderInputString}  readOnly={!superAdmin} />
                            </Form.Group>
                        </FormSection>
                    </Grid.Row>

                    <Grid.Row style={{marginTop: '20px'}}>
                        <FormSection fluid color="blue" title="Configurações de Boleto">
                            <Form.Group widths='equal'>
                                <Field name="settings.public.payment.billing.expirationDays" label="Prazo de Pagamento (dias)" component={renderInputInteger} normalize={normalizeInteger}/>
                                <Field name="settings.public.payment.billing.instruction1" label="Instrução de Pagamento (Linha 1)" component={renderInputString} />
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <Field name="settings.public.payment.billing.instruction2" label="Instrução de Pagamento (Linha 2)" component={renderInputString} />
                                <Field name="settings.public.payment.billing.instruction3" label="Instrução de Pagamento (Linha 3)" component={renderInputString} />
                            </Form.Group>
                        </FormSection>
                    </Grid.Row>
                </Grid.Column>

                <Grid.Column width={8}>
                    <Grid.Row>
                        <FormSection fluid color="yellow" title="Configurações de Parcelamento">
                            <Form.Group widths='equal'>
                                <Field name="settings.public.payment.installments.minValue" label="Parcela mínima (R$)" component={renderInputDecimal} normalize={normalizeDecimal}/>
                                <Field name="settings.public.payment.installments.maxInstallments" label="Quantidade Máxima de Parcelas" component={renderInputInteger} normalize={normalizeInteger}/>
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <Field name="settings.public.payment.installments.fixedFee" label="Juros Fixo (%)" component={renderInputDecimal} normalize={normalizeDecimal}/>
                                <Field name="settings.public.payment.installments.installmentFee" label="Juros por Parcela (%)" component={renderInputDecimal} normalize={normalizeDecimal}/>
                            </Form.Group>

                            <Form.Group widths='equal'>
                                <Field name="settings.public.payment.installments.installmentsFree" label="Parcelas sem Juros" component={renderInputInteger} normalize={normalizeInteger}/>
                            </Form.Group>
                        </FormSection>
                    </Grid.Row>

                    <Grid.Row style={{marginTop: '20px'}}>
                        <FormSection fluid color="yellow" title="Configurações de Pix">
                            <Form.Group widths='equal'>
                                <Field name="settings.public.payment.pix.expirationDays" label="Prazo de Pagamento (dias)" component={renderInputInteger} normalize={normalizeInteger}/>
                            </Form.Group>
                        </FormSection>
                    </Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}
