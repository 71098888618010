import React, { useContext, useState, useEffect } from 'react';
import GlobalContext from '../../Context/GlobalContext';

import { Button, Input, Icon, Message } from 'semantic-ui-react';

export default function DefaultTableToolbar(props) {
    const { icon, verboseName, verboseNamePlural, body } = props;

    const context = useContext(GlobalContext);
    const [searchTerm, setSearchTerm] = useState('');

    const clickDelete = () => {
        context.change({
            deleteModalOpen: true
        })
    }

    const clickCreate = () => {
        context.change({
            createModalOpen: true
        })
    }

    const clickSearch = () => {
        context.change({
            table: {
                pageSelected: 1,
                searchTerm: searchTerm
            }
        });
    }

    useEffect(() => {
        if(searchTerm === ''){
            clickSearch();
        }
    }, [searchTerm]);
    
    const renderButtons = () => {
        if(context.table.deleteList.length === 0 && props.permissions.create){
            return <Button color='green' content={`Adicionar ${verboseName}`} icon='plus' labelPosition='left' onClick={() => {clickCreate()}}/>
        }

        if(context.table.deleteList.length > 0){
            return <Button color='red' content={`Remover (${context.table.deleteList.length})`} icon='delete' labelPosition='left' onClick={() => {clickDelete()}}/>
        }
    }

    const renderTitle = () => {
        if(context.table.deleteList.length === 0){
            return (
                <React.Fragment>
                    {verboseNamePlural} ({body.count})
                    <Input id='searchField' value={searchTerm} onChange={(e) => {setSearchTerm(e.target.value)}} placeholder='ID, Nome...' style={{margin: '0 20px'}} />
                    <Button color='blue' content='Buscar' icon='search' labelPosition='left' onClick={() => {clickSearch()}}/>
                </React.Fragment>
            )
        }

        return `${context.table.deleteList.length} ${context.table.deleteList.length > 1 ? verboseNamePlural.toLowerCase()+' selecionados' : verboseName.toLowerCase() +' selecionado'}`;
    }
    
    return (
        <Message icon attached color={context.table.deleteList.length > 0 ? 'red' : null}>
            <Icon name={icon} />

            <Message.Content>
                <h3>{renderTitle()}</h3>
            </Message.Content>

            <span style={{float: 'right'}}>{renderButtons()}</span>
        </Message>
    )
}
