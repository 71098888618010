import React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { List, Image, Button, Icon } from 'semantic-ui-react';

import { Read } from '../../Actions/Image';

export default function ImageListItem(props) {
    const { uuid } = props;
 
    const entities = useSelector(state => state.images);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!entities[props.uuid]){
            dispatch(Read(props.uuid));
        }
    }, []);

    if(entities[props.uuid] && (!props.tag || entities[props.uuid].tags.includes(props.tag))){
        return (
            <List.Item>
                <Image size='tiny' src={`${entities[uuid].urls.default}`} />
                <List.Content>
                    <List.Header>
                        {entities[uuid].filename}.{entities[uuid].extension}
                    </List.Header>
                    <List.Description>
                        <Button.Group style={{marginTop: '10px'}}>
                            <Button icon onClick={(e) => {props.shiftUpAction(); e.preventDefault()}}>
                                <Icon name='arrow up' />
                            </Button>
                            <Button icon onClick={(e) => {props.shiftDownAction(); e.preventDefault()}}>
                                <Icon name='arrow down' />
                            </Button>
                            <Button icon color="red" onClick={(e) => {props.removeAction(); e.preventDefault()}}>
                                <Icon name='times' />
                            </Button>
                        </Button.Group>
                    </List.Description>
                </List.Content>
            </List.Item>
        )
    }
    
    return null;
}
