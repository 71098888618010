export { List as ListTable, Create, Read, Update, Delete } from '../../../Actions/PropertyGroup';

export * from './List';
export * from './Edit';
export * from './Create';
export * from './Validation';

export const entityReducer = 'propertyGroups';
export const entityListReducer = 'propertyGroupList';

export const verboseName = 'Propriedade'
export const verboseNamePlural = 'Propriedades'
export const icon = 'configure'
export const displayPropertyName = 'name';

export const editPath = '/propriedade'
export const listPath = '/propriedades'

export const createFormName = 'propertyGroupCreate';
export const editFormName = 'propertyGroupUpdate';

export const permission = 'property';