import React from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputString,
    renderInputCheckbox,
    renderInputDocument,
    renderInputEntity,
    renderMultiSelectMerchant,
    renderInputDatePicker,
    renderInputGenderSelect
} from '../../../../Utils/RenderUtils';

import {
    normalizeCellphone,
    normalizeDocument,
    normalizeInteger
} from '../../../../Utils/NormalizeUtils';

export default function Tab01(props) {
    return (
        <React.Fragment>
            <FormSection fluid color="green" title="Informações de Acesso">
                <Form.Group widths={3}>
                    <Field name="active" label="Ativo" component={renderInputCheckbox} type="checkbox" />
                    <Field name="externalId" label="Código Externo (ERP)" component={renderInputString} />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Field name="email" label="E-mail" readOnly component={renderInputString} />
                    <Field name="firstName" label="Nome" component={renderInputString} />
                    <Field name="lastName" label="Sobrenome" component={renderInputString} />
                </Form.Group>
            </FormSection>
            <FormSection fluid color="blue" title="Dados do Cliente">
                <Form.Group widths={4}>
                    <Field name="entity" label="Tipo Pessoa" component={renderInputEntity} />
                    <Field name="document" label="CPF/CNPJ" component={renderInputDocument} normalize={normalizeDocument} format={normalizeDocument} />
                </Form.Group>

                <Form.Group widths='equal'>
                    <Field name="mobile" label="Celular" component={renderInputString} normalize={normalizeCellphone} format={normalizeCellphone} />
                    <Field name="birthdate" label="Data de Nascimento" component={renderInputDatePicker} />
                    <Field name="gender" label="Sexo" component={renderInputGenderSelect} />
                    <Field name="inviteCode" label="Código de Convite" readOnly component={renderInputString} />
                </Form.Group>
            </FormSection>
        </React.Fragment>
    )
}
