import _ from 'lodash';

import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { Field } from 'redux-form';
import { List, Grid } from 'semantic-ui-react';

import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import arrayMove from 'array-move';

import FormSection from '../../../../Components/Card/FormSection';
import ProductCategoryListItem from '../../../../Components/Entity/ProductCategoryListItem';

import {
    renderMultiSelectProduct
} from '../../../../Utils/RenderUtils';

const SortableItem = SortableElement((props) => {
    return (
        <List.Item >
            <ProductCategoryListItem {...props} dragHandle={SortableHandle} uuid={props.item.uuid} />
        </List.Item>
    )
});

const SortableList = SortableContainer((props) => {
    return (
        <List relaxed horizontal className='productSortItem'>
            {props.items.map((item, index) => (
                <SortableItem key={`item-${item.uuid}`} removeProduct={props.removeProduct} index={index} item={item} />
            ))}
        </List>
    )
});

export default function Tab03(props) {
    const formValues = useSelector(state => state.form[props.form].values);    

    const [items, setItems] = useState([]);

    useEffect(() => {
        if(formValues.newProducts && formValues.newProducts.length > 0){            
            let formProducts = Object.assign([], formValues.products);
            formProducts.unshift({uuid: formValues.newProducts[0], sort: 0});
            
            props.change('products', formProducts);
            props.change('newProducts', [])
        }
    }, [formValues.newProducts]);

    useEffect(() => {
        setItems(formValues.products);
    }, [formValues.products]);

    const removeProduct = uuid => {        
        props.change('products', _.remove(formValues.products, (p) => {
            return p.uuid !== uuid;
        }))
    }

    const onSortEnd = ({oldIndex, newIndex}) => {
        props.change('products', arrayMove(formValues.products, oldIndex, newIndex));
    };

    return (
        <React.Fragment>
            <Grid columns={2}>
                <Grid.Row>
                    <Grid.Column>
                        <Field name="newProducts" label="Adicionar Produtos" excludeItems={Object.assign([], _.mapValues(formValues.products, 'uuid'))} component={renderMultiSelectProduct} />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <FormSection fluid color="blue" title="Produtos na Categoria">
                <SortableList useDragHandle axis='xy' items={items} removeProduct={removeProduct} onSortEnd={onSortEnd} />
            </FormSection>
        </React.Fragment>
    )
}
