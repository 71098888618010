import React, { useEffect } from 'react';

import { Form, Select } from 'semantic-ui-react'

import { useSelector, useDispatch } from "react-redux";
import { List } from '../../Actions/Manufacturer'

import { DEFAULT_TABLE_CONF } from '../../Context/GlobalContext';
import { getInputError } from '../../Utils/FunctionsUtils';

export default function SelectManufacturer(props) {
    const { input, label, meta } = props;

    const entityList = useSelector(state => state.manufacturerList);

    const dispatch = useDispatch();

    useEffect(() => {
        if(!entityList || !entityList.results || entityList.count > entityList.results.length){
            const queryString = {
                ...DEFAULT_TABLE_CONF,
                ...{
                    rowsPerPage: 9999,
                    orderBy: 'name',
                    fields: ['uuid', 'name']
                }
            }
    
            dispatch(List(queryString));
        }
    }, []);

    const getOptions = () => {
        let options = [];

        options.push({
            key: `entity-manufacturer-rows`,
            text: '--- Nenhum ---',
            value: null
        });

        (entityList.results || []).forEach((item) => {
            options.push({
                key: `${item.uuid}-entity-manufacturer-rows`,
                text: `${item.name}`,
                value: item.uuid
            })
        })

        return options;        
    }

    return (
        <Form.Field
            {...input}
            control={Select}
            search
            searchInput={{ id: 'form-select-control-manufacturer' }}
            label={{ children: label, htmlFor: 'form-select-control-manufacturer' }}
            options={getOptions()}
            onChange={(param, data) => input.onChange(data.value)}
            onBlur={(param, data) => input.onBlur(data.value)}
            error={getInputError(meta)} />
    )
}
