
import React, { useEffect, useState } from 'react';

import { Form, Dropdown, Input } from 'semantic-ui-react'

import { useSelector, useDispatch } from "react-redux";
import { List } from '../../Actions/Product'

export default function SelectProduct(props) {
    const { input, label, meta, excludeItems } = props;

    const entityList = useSelector(state => state.productList);
    const [options, setOptions] = useState([]);

    const selectorKey = props.selectorKey || 'uuid';

    const dispatch = useDispatch();

    useEffect(() => {
        if(!entityList || !entityList.results || entityList.count > entityList.results.length){
            dispatch(List({ rowsPerPage: 9999, orderBy: 'name', fields: ['uuid', 'name', 'code', selectorKey] }));
        }
    }, []);

    useEffect(() => {
        if(entityList.results){
            let optionsArray = [];

            entityList.results.forEach((item) => {
                if(!excludeItems || !excludeItems.includes(item.uuid)){
                    optionsArray.push({
                        key: `${item.uuid}-entity-product-rows`,
                        text: `${item.name} (${item.code})`,
                        value: item[selectorKey]
                    })
                }
            })

            setOptions(optionsArray);
        }
    }, [entityList, excludeItems]);

    const onChange = (param, data) => {
        input.onChange(data.value);
    }

    const onBlur = (param, data) => {
        input.onBlur(data.value);
    }

    if(entityList && entityList.results){
        return (
            <Form.Field>
                <label>{label}</label>
    
                <Dropdown
                    {...input}
                    fluid
                    search
                    selection
                    onChange={(param, data) => onChange(param, data)}
                    onBlur={(param, data) => onBlur(param, data)}
                    options={options}
                />
            </Form.Field>
        )
    } else {
        return (
            <React.Fragment>
                <label><b>{label}</b></label>
                <Input fluid loading disabled placeholder='Carregando...' />
            </React.Fragment>
        )
    }
}
