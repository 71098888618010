import React from 'react';

import { Form, Checkbox } from 'semantic-ui-react';
import { getInputError } from '../../Utils/FunctionsUtils';

export default function InputCheckbox(props) {
    const { input, label, meta, style } = props;
    delete input['value'];

    return (
        <Form.Field
            toggle
            style={style || {top: '40%'}}
            {...input}
            onChange={(e, { checked }) => input.onChange(checked)}
            label={label}
            control={Checkbox}
            error={getInputError(meta)} />
    )
}
