import React from 'react';

import { List as GenericList } from '../Generic/List'

import {
    entityListReducer,
    verboseName,
    verboseNamePlural,
    icon,
    editPath,
    tableHeader,
    ListTable,
    tableConf,
    mountCreatePanes,
    createFormName,
    ValidateCreate,
    createInitialValues,
    Delete,
    Create,
    displayPropertyName,
    permission
} from './Config'

import './style.css';

export default function List(props) {
    return (
        <GenericList
            {...props}

            ListTable={ListTable}
            Create={Create}
            Delete={Delete}

            verboseName={verboseName}
            verboseNamePlural={verboseNamePlural}
            icon={icon}
            head={tableHeader}
            editPath={editPath}
            displayPropertyName={displayPropertyName}

            mountCreatePanes={mountCreatePanes}

            entityListReducer={entityListReducer}
            tableConf={tableConf}

            form={createFormName}
            validate={ValidateCreate}
            initialValues={createInitialValues}

            permission={permission}
        />
    )
}
