export const tableConf = {
    searchFields: ['id', 'email', 'companyName', 'tradingName', 'document']
}

export const tableHeader = [
    {
        field: 'id',
        label: 'ID',
        dataType: 'integer',
        sortable: true
    },
    {
        field: 'active',
        label: 'Ativo',
        dataType: 'boolean',
        sortable: true
    },
    {
        field: 'createdAt',
        label: 'Data de Criação',
        dataType: 'datetime',
        sortable: true
    },
    {
        field: 'companyName',
        label: 'Razão Social',
        sortable: true
    },
    {
        field: 'tradingName',
        label: 'Nome Fantasia',
        sortable: true
    }
];