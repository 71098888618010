import Tab01 from '../Components/UpdateForm/Tab01';
import Tab02 from '../Components/UpdateForm/Tab02';
import Tab03 from '../Components/UpdateForm/Tab03';
import Tab04 from '../Components/UpdateForm/Tab04';
import Tab05 from '../Components/UpdateForm/Tab05';
import Tab06 from '../Components/UpdateForm/Tab06';

import { Update as UpdateAddress } from '../../../Actions/Address';
import { Update as UpdateCreditCard } from '../../../Actions/CreditCard';

export const preFilter = null;
export const preDispatch = (formValues, initialValues, dispatch) => {
    if(formValues.addresses){
        initialValues.addresses.forEach(address => {
            if(!formValues.addresses.includes(address)){
                dispatch(UpdateAddress(address, {customer: null}));
            }
        })
    }

    if(formValues.creditCards){
        initialValues.creditCards.forEach(cc => {
            if(!formValues.creditCards.includes(cc)){
                dispatch(UpdateCreditCard(cc, {customer: null}));
            }
        })
    }

    return formValues;
}

export const mountEditPanes = (props) => {
    return [
        {
            menuItem: { key: 'general', icon: 'info', content: 'Informações Gerais' },
            render: () => <Tab01 {...props} />
        },
        {
            menuItem: { key: 'images', icon: 'images', content: 'Foto' },
            render: () => <Tab02 {...props} />
        },
        {
            menuItem: { key: 'address', icon: 'home', content: 'Endereços' },
            render: () => <Tab03 {...props} />
        },
        {
            menuItem: { key: 'creditcard', icon: 'credit card', content: 'Cartões' },
            render: () => <Tab04 {...props} />
        },
        {
            menuItem: { key: 'orders', icon: 'shopping cart', content: 'Pedidos' },
            render: () => <Tab05 {...props} />
        },
        {
            menuItem: { key: 'password', icon: 'key', content: 'Trocar Senha' },
            render: () => <Tab06 {...props} />
        }
    ]
}