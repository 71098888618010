import history from '../../history';

import React, { useContext } from 'react';
import { useDispatch } from "react-redux";
import { reduxForm } from 'redux-form';
import { Form } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import GlobalContext from '../../Context/GlobalContext';

import CreateFormModal from '../../Components/Modal/CreateFormModal';

import { getToast } from '../../Utils/ToastUtils';
import ToastMessage from '../../Components/Modal/ToastMessage';

function FormCreate(props) {
    const { icon, Create,  verboseName,  editPath, mountCreatePanes } = props;
    const context = useContext(GlobalContext);
    const dispatch = useDispatch();

    const onSubmit = (formValues) => {
        if(formValues){
            dispatch(Create(formValues)).then((result) => {
                if(result.statusCode === 201){
                    context.change({createModalOpen: false})
                    getToast(<ToastMessage header='Sucesso!' content={`${verboseName} criado`}/>, toast.TYPE.SUCCESS)();
                    history.push(`${editPath}/${result.payload.uuid}`)
                }
            }).catch((err) => {
                getToast(<ToastMessage header='Erro!' content={`${err.response.data.field}: ${err.response.data.detail}`}/>, toast.TYPE.ERROR)();
            });
        }
    }

    const renderForm = () => {
        return (
            <Form onSubmit={props.handleSubmit(onSubmit)}>

                {mountCreatePanes(props)}

                <button id='hiddenSubmit' style={{display: 'none'}} type='submit'>Enviar</button>
            </Form>
        )
    }

    return (
        <CreateFormModal
            createForm={renderForm()}
            icon={icon}
            title={`Criar ${verboseName.toLowerCase()}`}
            size='large'
            saveHandler={() => {document.getElementById('hiddenSubmit').click()}}
            cancelHandler={() => {context.change({createModalOpen: false})}} />
    )
}

export default reduxForm()(FormCreate);
