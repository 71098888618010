import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Router, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import history from './history';

import Body from './Components/Body'

import ProductList from './Pages/Product/List';
import ProductEdit from './Pages/Product/Edit';

import ManufacturerList from './Pages/Manufacturer/List';
import ManufacturerEdit from './Pages/Manufacturer/Edit';

import MerchantList from './Pages/Merchant/List';
import MerchantEdit from './Pages/Merchant/Edit';

import AdministratorList from './Pages/Administrator/List';
import AdministratorEdit from './Pages/Administrator/Edit';

import VoucherList from './Pages/Voucher/List';
import VoucherEdit from './Pages/Voucher/Edit';

import BannerList from './Pages/Banner/List';
import BannerEdit from './Pages/Banner/Edit';

import CustomerList from './Pages/Customer/List';
import CustomerEdit from './Pages/Customer/Edit';

import PropertyGroupList from './Pages/PropertyGroup/List';
import PropertyGroupEdit from './Pages/PropertyGroup/Edit';

import ShippingList from './Pages/Shipping/List';
import ShippingEdit from './Pages/Shipping/Edit';

import CategoryList from './Pages/Category/List';
import CategoryEdit from './Pages/Category/Edit';

import OrderList from './Pages/Order/List';
import OrderEdit from './Pages/Order/Edit';

import InstitutionalList from './Pages/Institutional/List';
import InstitutionalEdit from './Pages/Institutional/Edit';

import NewsletterList from './Pages/Newsletter/List';
import NewsletterEdit from './Pages/Newsletter/Edit';

import Login from './Pages/Login/Login';

import { signData } from './Actions/Auth';

export default function App(props) {
    const auth = useSelector(state => state.auth);

    const dispatch = useDispatch();

    useEffect(() => {
        if(auth.access && auth.refresh){
            if(!auth.administrator.name){
                dispatch(signData());
            }
        }
    }, [auth]);

    if(!auth.access || !auth.refresh){
        history.push('/entrar');
    }

    const renderLogged = () => {
        if(auth.access && auth.refresh){
            return (
                <Body>
                    <Route path='/' exact component={ProductList}/>

                    <Route path='/produtos' exact component={ProductList}/>
                    <Route path='/produto/:uuid' exact component={ProductEdit}/>

                    <Route path='/institucionais' exact component={InstitutionalList}/>
                    <Route path='/institucional/:uuid' exact component={InstitutionalEdit}/>

                    <Route path='/fabricantes' exact component={ManufacturerList}/>
                    <Route path='/fabricante/:uuid' exact component={ManufacturerEdit}/>

                    <Route path='/lojas' exact component={MerchantList}/>
                    <Route path='/loja/:uuid' exact component={MerchantEdit}/>

                    <Route path='/administradores' exact component={AdministratorList}/>
                    <Route path='/administrador/:uuid' exact component={AdministratorEdit}/>

                    <Route path='/cupons' exact component={VoucherList}/>
                    <Route path='/cupom/:uuid' exact component={VoucherEdit}/>

                    <Route path='/banners' exact component={BannerList}/>
                    <Route path='/banner/:uuid' exact component={BannerEdit}/>

                    <Route path='/clientes' exact component={CustomerList}/>
                    <Route path='/cliente/:uuid' exact component={CustomerEdit}/>

                    <Route path='/newsletters' exact component={NewsletterList}/>
                    <Route path='/newsletter/:uuid' exact component={NewsletterEdit}/>

                    <Route path='/propriedades' exact component={PropertyGroupList}/>
                    <Route path='/propriedade/:uuid' exact component={PropertyGroupEdit}/>

                    <Route path='/transportadoras' exact component={ShippingList}/>
                    <Route path='/transportadora/:uuid' exact component={ShippingEdit}/>

                    <Route path='/categorias' exact component={CategoryList}/>
                    <Route path='/categoria/:uuid' exact component={CategoryEdit}/>

                    <Route path='/pedidos' exact component={OrderList}/>
                    <Route path='/pedido/:uuid' exact component={OrderEdit}/>
                </Body>
            )
        }
    }

    return (
        <Router history={history}>
            <ToastContainer />

            <Route path='/entrar' exact component={Login}/>

            {renderLogged()}
        </Router>
    )
}
