import React from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputPassword
} from '../../../../Utils/RenderUtils';

export default function Tab03() {
    return (
        <React.Fragment>
            <FormSection fluid color="green" title="Trocar Senha">
                <Form.Group widths={3}>
                    <Field name="password" label="Nova Senha" component={renderInputPassword} />
                    <Field name="passwordConfirmation" label="Confirmação de Senha" component={renderInputPassword} />
                </Form.Group>
            </FormSection>
        </React.Fragment>
    )
}
