import _ from 'lodash';

import React from 'react';
import { Step, Icon, Popup, Message, Grid, Button } from 'semantic-ui-react';

import { datetimeIsoToPtBr } from '../../../../Utils/FunctionsUtils';

const orderStatus = {
    CART: {
        sort: 0,
        statusTitle: 'Carrinho',
        actionText: '',
        nextStatus: 'WAITING_PAYMENT',
        infoTitle: {
            info: 'Informação',
            internalInfo: 'Informação Interna'
        }
    },
    WAITING_PAYMENT: {
        sort: 1,
        statusTitle: 'Aguardando Pagamento',
        actionText: 'Clique para marcar como aguardando pagamento',
        nextStatus: 'NEW',
        infoTitle: {
            info: 'Informação',
            internalInfo: 'Informação Interna'
        }
    },
    NEW: {
        sort: 2,
        statusTitle: 'Novo Pedido',
        actionText: 'Clique para marcar o pedido como pago',
        nextStatus: 'INVOICED',
        infoTitle: {
            info: 'Informação',
            internalInfo: 'Informação Interna'
        }
    },
    INVOICED: {
        sort: 3,
        statusTitle: 'Faturado',
        actionText: 'Clique para marcar como faturado',
        nextStatus: 'PROCESSED',
        infoTitle: {
            info: 'URL Nota Fiscal',
            internalInfo: 'Informação Interna'
        }
    },
    PROCESSED: {
        sort: 4,
        statusTitle: 'Separado',
        actionText: 'Clique para marcar como separad',
        nextStatus: 'SHIPPED',
        infoTitle: {
            info: 'Informação',
            internalInfo: 'Informação Interna'
        }
    },
    SHIPPED: {
        sort: 5,
        statusTitle: 'Enviado',
        actionText: 'Clique para marcar como enviado',
        nextStatus: 'DONE',
        infoTitle: {
            info: 'URL Rastreio',
            internalInfo: 'Informação Interna'
        }
    },
    DONE: {
        sort: 6,
        statusTitle: 'Entregue',
        actionText: 'Clique para marcar como entregue',
        infoTitle: {
            info: 'Informação',
            internalInfo: 'Informação Interna'
        }
    },
    FAILED: {
        sort: 7,
        statusTitle: 'Falha no Pagamento',
        actionText: '',
        infoTitle: {
            info: 'Informação',
            internalInfo: 'Informação Interna'
        }
    },
    CANCELLED: {
        sort: 8,
        statusTitle: 'Cancelado',
        actionText: '',
        infoTitle: {
            info: 'Motivo do Cancelamento',
            internalInfo: 'Informação Interna'
        }
    },
    REFUNDED: {
        sort: 9,
        statusTitle: 'Estornado',
        actionText: 'Clique para marcar como estornado',
        infoTitle: {
            info: 'URL Nota Fiscal de Devolução',
            internalInfo: 'Informação Interna'
        }
    },
    PREV: {
        statusTitle: 'Status Anterior',
        infoTitle: {
            info: 'Informação',
            internalInfo: 'Informação Interna'
        }
    }
}

export default function StatusSteps(props) {

    const currentStatus = orderStatus[props.initialValues.status];

    const getStepsSize = () => {
        if(currentStatus.sort <= 1){
            return 3;
        }

        return 5;
    }

    const getStatusInfo = (status) => {
        return props.initialValues.statusChanges[_.findIndex(props.initialValues.statusChanges, function(s) { return s.status == status })]
    }

    const getStatusDescription = (status) => {
        const statusInfo = getStatusInfo(status);

        status = orderStatus[status];

        let elements = [<Step.Title>{status.statusTitle}</Step.Title>];

        if(status.sort === 0){
            elements.push(<Step.Description>{datetimeIsoToPtBr(props.initialValues.createdAt)}</Step.Description>);
        } else if(currentStatus.sort+1 === status.sort) {
            elements.push(<Step.Description>{status.actionText}</Step.Description>);
        } else if(currentStatus.sort >= status.sort){
            if(statusInfo){
                const infoContent = (
                    <Message floating>
                        <Message.Header>Informações</Message.Header>
                        <Message.List>
                            <Message.Item><b>{status.infoTitle.info}:</b> {statusInfo.info}</Message.Item>
                            <Message.Item><b>{status.infoTitle.internalInfo}:</b> {statusInfo.internalInfo}</Message.Item>
                        </Message.List>
                    </Message>
                )

                elements = (
                    <React.Fragment>
                        <Step.Title>
                            {status.statusTitle}
                            {'\u00a0\u00a0'}
                            <Popup position='bottom center' pinned on='click' content={infoContent} trigger={<Icon link name='question circle' />} />
                        </Step.Title>
                        <Step.Description>
                            {datetimeIsoToPtBr(statusInfo.createdAt)}
                        </Step.Description>
                    </React.Fragment>
                )
            }
        }

        return elements;
    }

    const getStepProps = (status) => {
        if(currentStatus.sort+1 === orderStatus[status].sort){
            return {
                link: true,
                active: true
            }
        }

        return {
            active: false,
            disabled: currentStatus.sort < orderStatus[status].sort
        }
    }

    const getChangeStatusModal = (status, statusTarget) => {
        if(currentStatus.sort+1 === orderStatus[status].sort || statusTarget){
            props.setChangeStatus({
                open: true,
                status: statusTarget || 'next',
                title: orderStatus[status].statusTitle,
                infoTitle: orderStatus[status].infoTitle,
            })
        }
    }

    const getFirstSteps = () => {
        if(currentStatus.sort <= 1){
            return (
                <React.Fragment>
                    <Step {...getStepProps('CART')} onClick={() => {getChangeStatusModal('CART')}}>
                        <Icon name='shopping cart' />
                        <Step.Content>
                            {getStatusDescription('CART')}
                        </Step.Content>
                    </Step>
                    <Step {...getStepProps('WAITING_PAYMENT')} onClick={() => {getChangeStatusModal('WAITING_PAYMENT')}}>
                        <Icon name='clock outline' />
                        <Step.Content>                            
                            {getStatusDescription('WAITING_PAYMENT')}
                        </Step.Content>
                    </Step>
                    <Step {...getStepProps('NEW')} onClick={() => {getChangeStatusModal('NEW')}}>
                        <Icon name='dollar' />
                        <Step.Content>
                            {getStatusDescription('NEW')}
                        </Step.Content>
                    </Step>
                </React.Fragment>
            )
        }

        return null;
    }
    
    const getMiddleSteps = () => {
        if(currentStatus.sort > 1 && currentStatus.sort < 7){
            return (
                <React.Fragment>
                    <Step {...getStepProps('NEW')} onClick={() => {getChangeStatusModal('NEW')}}>
                        <Icon name='dollar' />
                        <Step.Content>
                            {getStatusDescription('NEW')}
                        </Step.Content>
                    </Step>

                    <Step {...getStepProps('INVOICED')} onClick={() => {getChangeStatusModal('INVOICED')}}>
                        <Icon name='file outline' />
                        <Step.Content>
                            {getStatusDescription('INVOICED')}
                        </Step.Content>
                    </Step>

                    <Step {...getStepProps('PROCESSED')} onClick={() => {getChangeStatusModal('PROCESSED')}}>
                        <Icon name='box' />
                        <Step.Content>
                            {getStatusDescription('PROCESSED')}
                        </Step.Content>
                    </Step>

                    <Step {...getStepProps('SHIPPED')} onClick={() => {getChangeStatusModal('SHIPPED')}} >
                        <Icon name='shipping' />
                        <Step.Content>
                            {getStatusDescription('SHIPPED')}
                        </Step.Content>
                    </Step>

                    <Step {...getStepProps('DONE')} onClick={() => {getChangeStatusModal('DONE')}}>
                        <Icon name='check' />
                        <Step.Content>
                            {getStatusDescription('DONE')}
                        </Step.Content>
                    </Step>
                </React.Fragment>
            )
        }
        return null;
    }

    const getCancelledSteps = () => {
        if(currentStatus.sort >= 7){
            let cancelledSteps = [];
    
            cancelledSteps.push(
                <Step {...getStepProps('CART')} onClick={() => {getChangeStatusModal('CART')}}>
                    <Icon name='shopping cart' />
                    <Step.Content>
                        {getStatusDescription('CART')}
                    </Step.Content>
                </Step>
            )
    
            if(currentStatus.sort === 7){
                cancelledSteps.push(
                    <Step {...getStepProps('FAILED')} onClick={() => {getChangeStatusModal('FAILED')}}>
                        <Icon name='times' />
                        <Step.Content>
                            {getStatusDescription('FAILED')}
                        </Step.Content>
                    </Step>
                )
            } else {
                cancelledSteps.push(
                    <React.Fragment>
                        <Step {...getStepProps('CANCELLED')} onClick={() => {getChangeStatusModal('CANCELLED')}}>
                            <Icon name='times' />
                            <Step.Content>
                                {getStatusDescription('CANCELLED')}
                            </Step.Content>
                        </Step>
    
                        <Step {...getStepProps('REFUNDED')} onClick={() => {getChangeStatusModal('REFUNDED', 'REFUNDED')}}>
                            <Icon name='exchange' />
                            <Step.Content>
                                {getStatusDescription('REFUNDED')}
                            </Step.Content>
                        </Step>
                    </React.Fragment>
                )
            }
            return cancelledSteps;
        }
        return null;
    }

    const getActionButtons = () => {
        if(currentStatus.sort > 0 && currentStatus.sort <= 6){
            return (
                <React.Fragment>
                    <Button color='grey' content='Voltar Passo' icon='redo' labelPosition='left' onClick={(e) => {e.preventDefault(); getChangeStatusModal('PREV', 'previous')}} />
                    <Button color='red' content='Cancelar Pedido' icon='times' labelPosition='left' onClick={(e) => {e.preventDefault(); getChangeStatusModal('CANCELLED', 'CANCELLED')}} />
                </React.Fragment>
            )
        }
    }

    return (
        <React.Fragment>
            <Step.Group size='small' stackable='tablet' widths={getStepsSize()}>
                {getFirstSteps()}
                {getMiddleSteps()}
                {getCancelledSteps()}
            </Step.Group>

            <Grid centered>
                <Grid.Column textAlign='center'>
                    {getActionButtons()}
                </Grid.Column>
            </Grid>
        </React.Fragment>
    )
}
