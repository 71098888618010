export { List as ListTable, Create, Read, Update, Delete } from '../../../Actions/Newsletter';

export * from './List';
export * from './Edit';
export * from './Create';
export * from './Validation';

export const entityReducer = 'newsletters';
export const entityListReducer = 'newsletterList';

export const verboseName = 'Newsletter'
export const verboseNamePlural = 'Newsletters'
export const icon = 'newspaper'
export const displayPropertyName = 'name';

export const editPath = '/newsletter'
export const listPath = '/newsletters'

export const createFormName = 'newsletterCreate';
export const editFormName = 'newsletterUpdate';

export const permission = 'newsletter';
