export { List as ListTable, Create, Read, Update, Delete } from '../../../Actions/Institutional';

export * from './List';
export * from './Edit';
export * from './Create';
export * from './Validation';

export const entityReducer = 'institutionals';
export const entityListReducer = 'institutionalList';

export const verboseName = 'Institucional'
export const verboseNamePlural = 'Institucionais'
export const icon = 'clipboard outline'
export const displayPropertyName = 'title';

export const editPath = '/institucional'
export const listPath = '/institucionais'

export const createFormName = 'institutionalCreate';
export const editFormName = 'institutionalUpdate';

export const permission = 'institutional';