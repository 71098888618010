import _ from 'lodash';

import React from 'react';
import { useSelector } from "react-redux";
import { List } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';
import SubCategoryListItem from '../../../../Components/Entity/SubCategoryListItem';

export default function Tab04(props) {
    const formValues = useSelector(state => state.form[props.form].values);

    const removeItem = uuid => {
        props.change('categories', _.remove(formValues.categories, function(n) { return n !== uuid }));
    }

    const changeItemPosition = (uuid, shift) => {
        let newItem = Object.assign([], formValues.categories);

        let index = newItem.indexOf(uuid);
        shift = index + shift;

        if(shift >= 0 && shift < formValues.categories.length){
            let shiftUuid = newItem[shift];
            newItem[shift] = uuid;
            newItem[index] = shiftUuid;
    
            props.change('categories', newItem);
        }
    }

    return (
        <React.Fragment>
            <FormSection fluid color="green">
                <List divided relaxed>
                    {
                        formValues.categories.map((imageUuid) => {
                            return <SubCategoryListItem
                                        key={`image-${imageUuid}`}
                                        uuid={imageUuid}
                                        shiftUpAction={() => {changeItemPosition(imageUuid, -1)}}
                                        shiftDownAction={() => {changeItemPosition(imageUuid, 1)}}
                                        removeAction={() => {removeItem(imageUuid)}} />
                        })
                    }
                </List>
            </FormSection>
        </React.Fragment>
    )
}
