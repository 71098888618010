import React from 'react';

export const DEFAULT_TABLE_CONF = {
    pageSelected: 1,
    rowsPerPage: 10,
    order: '',
    orderBy: 'id',
    rowsPerPageOptions: [10, 25, 50],
    searchTerm: '',
    searchFields: ['id'],
    deleteList: [],
}

export const DEFAULT_CONTEXT = {
    table: DEFAULT_TABLE_CONF,
    deleteModalOpen: false,
    createModalOpen: false,
    displaySidebar: true,
}

const Context = React.createContext(DEFAULT_CONTEXT);

export class GlobalStore extends React.Component {
    state = DEFAULT_CONTEXT;

    onStateChange = (ctx) => {
        if(ctx.table){
            ctx.table = {...this.state.table, ...ctx.table}
        }

        this.setState({...this.state, ...ctx});
    }

    render() {
        return (
            <Context.Provider value={{...this.state, change: this.onStateChange}}>
                {this.props.children}
            </Context.Provider>
        )
    }
}

export default Context;
