import _ from 'lodash';
import faker from 'faker';

import {
    CategoryFaker,
    ManufacturerFaker,
    PropertyFaker,
    ProductFaker,
    ShippingFaker,
    BannerFaker,
    VoucherFaker
} from './FakerData';

import { Create as CreateCategory, UpdateProduct as UpdateProductCategory } from '../Actions/Category';
import { Create as CreateManufacturer } from '../Actions/Manufacturer';
import { Create as CreateProperty } from '../Actions/PropertyGroup';
import { Create as CreateProduct } from '../Actions/Product';
import { Create as CreateImage } from '../Actions/Image';
import { Create as CreateShipping } from '../Actions/Shipping';
import { Create as CreateBanner } from '../Actions/Banner';
import { Create as CreateVoucher } from '../Actions/Voucher';

async function toDataURL(url){
    var xhr = new XMLHttpRequest();

    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send()

    return new Promise((resolve) => {
        xhr.onload = function() {
            var reader = new FileReader();

            reader.readAsDataURL(xhr.response);
            resolve(new Promise((resolveFile) => {
                reader.onloadend = function() {
                    resolveFile(reader.result);
                }
            }))
        };
    })
}

async function registerImage(width, weight, tags, dispatch, terms){
    var image;
    var URL = 'https://loremflickr.com/'+width+'/'+weight

    if(terms){
        URL = URL+'/'+terms
    }

    await toDataURL(URL).then(imageB64 => {
        image = imageB64;
    })

    return (await dispatch(CreateImage({filebase: image, tags: tags}))).payload.uuid
}

export async function registerStore(merchantUuid, dispatch, setProgress){
    var categories = [];
    var manufacturers = [];
    var propertyGroups = [];
    var products = [];

    var count = 1;
    var data = null;

    // Criando Árvore de Categorias
    while(count-1 < 6){

        setProgress({
            message: 'Criando categorias principais - '+count+'/6',
            percent: count*2
        })

        data = CategoryFaker({merchant: merchantUuid});

        if(_.findIndex(categories, ['name', data.name]) < 0){
            data.images = (await Promise.all([
                registerImage(150, 150, ['MENU_ICON'], dispatch, 'logo,icon'),
                registerImage(1920, 500, ['BANNER_DESKTOP'], dispatch, 'commerce,department'),
                registerImage(500, 250, ['BANNER_MOBILE'], dispatch, 'commerce,department'),
                registerImage(300, 400, ['HOME_CARD'], dispatch, 'department,banner')
            ]))
    
            categories.push((await dispatch(CreateCategory(data))).payload);
            count += 1
        }
    }

    count = 1;
    while(count-1 < 16){
        setProgress({
            message: 'Criando árvore de categorias - '+count+'/16',
            percent: 12 + count*0.625
        })

        data = CategoryFaker({merchant: merchantUuid});
        data.mainCategory = categories[faker.random.number(categories.length-1)].uuid

        if(_.findIndex(categories, ['name', data.name]) < 0){
            data.images = (await Promise.all([
                registerImage(150, 150, ['MENU_ICON'], dispatch, 'logo,icon'),
                registerImage(1920, 500, ['BANNER_DESKTOP'], dispatch, 'commerce,department'),
                registerImage(500, 250, ['BANNER_MOBILE'], dispatch, 'commerce,department'),
                registerImage(300, 400, ['HOME_CARD'], dispatch, 'department,banner')
            ]))
    
            categories.push((await dispatch(CreateCategory(data))).payload);
            count += 1
        }
    }

    count = 1;
    // Criando Fabricantes
    while(count-1 < 30){
        setProgress({
            message: 'Criando fabricantes - '+count+'/30',
            percent: 22 + count*0.2
        })

        data = ManufacturerFaker({merchant: merchantUuid});

        if(_.findIndex(manufacturers, ['name', data.name]) < 0){
            data.images = (await Promise.all([
                registerImage(1920, 320, ['BANNER'], dispatch, 'company,banner'),
                registerImage(1000, 1000, ['LOGO'], dispatch, 'company,logo')
            ]))
    
            manufacturers.push((await dispatch(CreateManufacturer(data))).payload);
            count += 1;
        }
    }

    count = 1;
    // Criando Propriedades
    while(count-1 < 5){
        setProgress({
            message: 'Criando propriedades - '+count+'/5',
            percent: 28 + count
        })

        data = PropertyFaker({merchant: merchantUuid}, null, 10);

        if(_.findIndex(propertyGroups, ['name', data.name]) < 0){
            propertyGroups.push((await dispatch(CreateProperty(data))).payload);
            count += 1;
        }
    }

    count = 1;
    // Criando Produtos
    while(count-1 < 300){
        setProgress({
            message: 'Criando produtos - '+count+'/300',
            percent: 33 + count*0.2
        })

        var properties = [];

        _.sortedUniq([faker.random.number(4), faker.random.number(4), faker.random.number(4)]).forEach(j => {
            var propsOptions = propertyGroups[j].properties;
            properties.push(propsOptions[faker.random.number(propsOptions.length-1)].uuid);
        })

        var manufacturer = manufacturers[faker.random.number(manufacturers.length-1)].uuid;

        data = ProductFaker({
            merchant: merchantUuid,
            properties: properties,
            manufacturer: manufacturer
        });

        if(_.findIndex(products, ['name', data.name]) < 0){
            data.images = (await Promise.all([
                registerImage(1000, 1000, ['DEFAULT1'], dispatch, 'commerce,product'),
                registerImage(1000, 1000, ['DEFAULT2'], dispatch, 'commerce,item'),
                registerImage(1000, 1000, ['DEFAULT3'], dispatch, 'commerce,cart')
            ]))
    
            products.push((await dispatch(CreateProduct(data))).payload);
            count += 1;
        }
    }

    setProgress({
        message: 'Associando produtos às categorias',
        percent: 94
    })

    // Associando produtos e categorias
    await Promise.all(categories.map(cat => {
        var catProd = [];

        for(var i=0; i<products.length/categories.length; i++){
            catProd.push({
                sort: faker.random.number(30),
                uuid: products[faker.random.number(products.length-1)].uuid
            })
        }

        return dispatch(UpdateProductCategory(cat.uuid, catProd));
    }));

    setProgress({
        message: 'Criando transportadoras',
        percent: 95
    })

    // Criando Transportadoras
    data = ShippingFaker({
        merchant: merchantUuid,
        name: 'Correios PAC',
        code: 'PAC',
        keys: ['CorreiosPac', 'DELIVERY_SATURDAY']
    });
    await dispatch(CreateShipping(data));

    data = ShippingFaker({
        merchant: merchantUuid,
        name: 'Correios Sedex',
        code: 'SEDEX',
        keys: ['CorreiosSedex', 'DELIVERY_SATURDAY', 'DELIVERY_SUNDAY']
    });
    await dispatch(CreateShipping(data));

    data = ShippingFaker({
        merchant: merchantUuid,
        name: 'Motoboy',
        code: 'MTB',
        keys: ['ZipRange', 'DELIVERY_SATURDAY', 'DELIVERY_SUNDAY'],
        maxVolume: 512000,
        maxDimension: 80,
        maxWeight: 5000,
        zipRanges: [
            {
                name: 'Montes Claros',
                initZipcode: '39400-001',
                endZipcode: '39429-999',
                deliveryTime: 120,
                prices: [
                    {
                        initWeight: 0,
                        endWeight: 5000,
                        cost: 8,
                        price: 15
                    }
                ]
            }
        ]
    });
    await dispatch(CreateShipping(data));

    data = ShippingFaker({
        merchant: merchantUuid,
        name: 'Transportadora X',
        code: 'TRANSP-X',
        keys: ['ZipRange', 'DELIVERY_SATURDAY'],
        zipRanges: [
            {
                name: 'Montes Claros',
                initZipcode: '39400-001',
                endZipcode: '39429-999',
                deliveryTime: 1440,
                overWeightCost: 1,
                cubicCoefficient: 300,
                prices: [
                    {
                        initWeight: 0,
                        endWeight: 10000,
                        cost: 5,
                        price: 8
                    },
                    {
                        initWeight: 10000,
                        endWeight: 25000,
                        cost: 10,
                        price: 15
                    },
                    {
                        initWeight: 25000,
                        endWeight: 50000,
                        cost: 15,
                        price: 22
                    },
                    {
                        initWeight: 50000,
                        endWeight: 80000,
                        cost: 20,
                        price: 30
                    },
                    {
                        initWeight: 80000,
                        endWeight: 100000,
                        cost: 35,
                        price: 48
                    }
                ]
            },
            {
                name: 'Belo Horizonte',
                initZipcode: '30000-000',
                endZipcode: '34999-000',
                deliveryTime: 4320,
                overWeightCost: 3,
                prices: [
                    {
                        initWeight: 0,
                        endWeight: 10000,
                        cost: 15,
                        price: 24
                    },
                    {
                        initWeight: 10000,
                        endWeight: 25000,
                        cost: 30,
                        price: 45
                    },
                    {
                        initWeight: 25000,
                        endWeight: 50000,
                        cost: 45,
                        price: 66
                    },
                    {
                        initWeight: 50000,
                        endWeight: 80000,
                        cost: 60,
                        price: 90
                    },
                    {
                        initWeight: 80000,
                        endWeight: 100000,
                        cost: 105,
                        price: 144
                    }
                ]
            }
        ]
    });
    await dispatch(CreateShipping(data));

    setProgress({
        message: 'Criando Banners - 1/3',
        percent: 96
    });

    // Criando Banners
    data = BannerFaker({
        merchant: merchantUuid,
        images: (await Promise.all([
            registerImage(1920, 500, ['CAROUSEL_DESKTOP'], dispatch, 'product,commerce'),
            registerImage(500, 250, ['CAROUSEL_MOBILE'], dispatch, 'product,commerce')
        ])),
        keys: ['CAROUSEL'],
        url: '/categoria'+categories[faker.random.number(categories.length-1)].path
    });

    await dispatch(CreateBanner(data));

    setProgress({
        message: 'Criando Banners - 2/3',
        percent: 96
    });

    data = BannerFaker({
        merchant: merchantUuid,
        keys: ['CAROUSEL'],
        images: (await Promise.all([
            registerImage(1920, 500, ['CAROUSEL_DESKTOP'], dispatch, 'product,commerce'),
            registerImage(500, 250, ['CAROUSEL_MOBILE'], dispatch, 'product,commerce')
        ])),
        fields: {
            buttons: [
                {
                    text: 'Ver produto',
                    url: '/produto/'+products[faker.random.number(products.length-1)].slug
                },
                {
                    text: 'Ver categoria',
                    url: '/categoria'+categories[faker.random.number(categories.length-1)].path
                },
            ]
        } 
    });
    
    await dispatch(CreateBanner(data));

    setProgress({
        message: 'Criando Banners - 3/3',
        percent: 97
    });

    data = BannerFaker({
        merchant: merchantUuid,
        keys: ['CAROUSEL'],
        images: await Promise.all([
            registerImage(1920, 500, ['CAROUSEL_DESKTOP'], dispatch, 'product,commerce'),
            registerImage(500, 250, ['CAROUSEL_MOBILE'], dispatch, 'product,commerce')
        ]),
        description: null,
        fields: {
            buttons: [
                {
                    text: 'Ver produto',
                    url: '/produto/'+products[faker.random.number(products.length-1)].slug
                }
            ]
        } 
    });

    await dispatch(CreateBanner(data));

    setProgress({
        message: 'Criando Mini Banners - 1/3',
        percent: 97
    });

    data = BannerFaker({
        merchant: merchantUuid,
        keys: ['MINI'],
        images: await Promise.all([
            registerImage(350, 100, ['MINI'], dispatch, 'commerce,icon')
        ]),
        title: faker.company.catchPhraseAdjective(),
    });

    await dispatch(CreateBanner(data));

    setProgress({
        message: 'Criando Mini Banners - 2/3',
        percent: 97
    });

    data = BannerFaker({
        merchant: merchantUuid,
        keys: ['MINI'],
        images: await Promise.all([
            registerImage(350, 100, ['MINI'], dispatch, 'shipping,icon')
        ]),
        title: faker.company.catchPhraseAdjective(),
    });

    await dispatch(CreateBanner(data));

    setProgress({
        message: 'Criando Mini Banners - 3/3',
        percent: 98
    });
    
    data = BannerFaker({
        merchant: merchantUuid,
        keys: ['MINI'],
        images: await Promise.all([
            registerImage(350, 100, ['MINI'], dispatch, 'department,icon')
        ]),
        title: faker.company.catchPhraseAdjective(),
    });

    await dispatch(CreateBanner(data));

    setProgress({
        message: 'Criando Vouchers e Campanhas - 1/4',
        percent: 98
    });

    // Criando Vouchers
    data = VoucherFaker({
        merchant: merchantUuid,
        keys: ['SINGLE', 'FIRST_ORDER'],
        name: 'Boas Vindas',
        code: 'BOASVINDAS100',
        absoluteDiscount: 100,
        triggerValue: 100,
        amount: 10000,
        images: await Promise.all([
            registerImage(500, 500, ['BANNER'], dispatch, 'department,icon'),
            registerImage(100, 50, ['TAG'], dispatch, 'department,icon')
        ])
    });

    await dispatch(CreateVoucher(data));

    setProgress({
        message: 'Criando Vouchers e Campanhas - 2/4',
        percent: 98
    });

    data = VoucherFaker({
        merchant: merchantUuid,
        keys: ['BATCH'],
        name: '50% em Produtos de Categoria',
        percentualDiscount: 50,
        amount: 100,
        categories: [
            categories[faker.random.number(categories.length-1)].uuid,
            categories[faker.random.number(categories.length-1)].uuid,
            categories[faker.random.number(categories.length-1)].uuid
        ],
        images: await Promise.all([
            registerImage(500, 500, ['BANNER'], dispatch, 'department,icon'),
            registerImage(100, 50, ['TAG'], dispatch, 'department,icon')
        ])
    });

    await dispatch(CreateVoucher(data));

    setProgress({
        message: 'Criando Vouchers e Campanhas - 3/4',
        percent: 99
    });

    data = VoucherFaker({
        merchant: merchantUuid,
        keys: ['CAMPAIGN'],
        name: 'Frete Grátis Santa Rita I e II',
        shippingDiscount: 100,
        amount: 1,
        triggerZipcodeInit: '39400-382',
        triggerZipcodeEnd: '39400-713',
        images: await Promise.all([
            registerImage(500, 500, ['BANNER'], dispatch, 'department,icon'),
            registerImage(100, 50, ['TAG'], dispatch, 'department,icon')
        ])
    });

    await dispatch(CreateVoucher(data));

    setProgress({
        message: 'Criando Vouchers e Campanhas - 4/4',
        percent: 99
    });

    data = VoucherFaker({
        merchant: merchantUuid,
        keys: ['SINGLE'],
        name: 'Brinde a partir de 3 itens',
        code: 'PEGARBRINDE',
        gifts: [
            products[faker.random.number(products.length-1)].uuid
        ],
        triggerAmount: 3,
        amount: 10000,
        images: await Promise.all([
            registerImage(500, 500, ['BANNER'], dispatch, 'department,icon'),
            registerImage(100, 50, ['TAG'], dispatch, 'department,icon')
        ])
    });

    await dispatch(CreateVoucher(data));
}