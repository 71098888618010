import React from "react";
import { reduxForm, Field } from 'redux-form';
import { useDispatch, useSelector } from "react-redux";
import history from '../../history';

import { renderInputString, renderInputPassword } from '../../Utils/RenderUtils';

import { signIn } from '../../Actions/Auth';

import {
    Button,
    Form,
    Grid,
    Header,
    Image,
    Segment
} from "semantic-ui-react";

import "./Login.css";

function Login(props) {
    const auth = useSelector(state => state.auth);
    const dispatch = useDispatch();

    if(auth.access && auth.refresh){
        history.push('/');
    }

    const doLogin = (formValues) => {
        if(formValues){
            dispatch(signIn(formValues.username, formValues.password));
        }
    }

    return (
        <Grid className="login-grid" textAlign="center" verticalAlign="middle">
            <Grid.Column style={{ maxWidth: 450 }}>
                <Segment raised >
                    <Header as="h1" color="green" textAlign="left">
                        <Image circular src='/logo-novo.png' />
                        <Header.Content>
                            Cyberdelia
                            <Header.Subheader>Acessar painel administrativo</Header.Subheader>
                        </Header.Content>
                    </Header>

                    <Form size="large" onSubmit={props.handleSubmit(doLogin)}>
                        <Field name="username" fluid icon="user" iconPosition="left" placeholder="E-mail address" component={renderInputString} />
                        <Field name="password" fluid icon="lock" iconPosition="left" placeholder="Password" component={renderInputPassword} />
                        
                        <Button color="green" fluid size="large" onClick={() => { doLogin() }}>
                            Login
                        </Button>
                    </Form>
                </Segment>
            </Grid.Column>
        </Grid>
    );
}

export default reduxForm({
    form: 'loginForm'
})(Login);