export { List as ListTable, Create, Read, Update, Delete } from '../../../Actions/Voucher';

export * from './List';
export * from './Edit';
export * from './Create';
export * from './Validation';

export const entityReducer = 'vouchers';
export const entityListReducer = 'voucherList';

export const verboseName = 'Cupom'
export const verboseNamePlural = 'Cupons'
export const icon = 'ticket'
export const displayPropertyName = 'name';

export const editPath = '/cupom'
export const listPath = '/cupons'

export const createFormName = 'voucherCreate';
export const editFormName = 'voucherUpdate';

export const permission = 'voucher';