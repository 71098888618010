import React from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputDecimal
} from '../../../../Utils/RenderUtils';

import {
    normalizeDecimal
} from '../../../../Utils/NormalizeUtils';

export default function Tab03() {

    return (
        <React.Fragment>
            <FormSection fluid color="green" title="Dimensões do Produto">
                <Form.Group widths={4}>
                    <Field name="weight" label="Peso (g)" component={renderInputDecimal} normalize={normalizeDecimal} />
                    <Field name="height" label="Altura (cm)" component={renderInputDecimal} normalize={normalizeDecimal} />
                    <Field name="width" label="Largura (cm)" component={renderInputDecimal} normalize={normalizeDecimal} />
                    <Field name="length" label="Comprimento (cm)" component={renderInputDecimal} normalize={normalizeDecimal} />
                </Form.Group>
            </FormSection>

            <FormSection fluid color="blue" title="Dimensões de Entrega">
                <Form.Group widths={4}>
                    <Field name="shippingWeight" label="Peso (g)" component={renderInputDecimal} normalize={normalizeDecimal} />
                    <Field name="shippingHeight" label="Altura (cm)" component={renderInputDecimal} normalize={normalizeDecimal} />
                    <Field name="shippingWidth" label="Largura (cm)" component={renderInputDecimal} normalize={normalizeDecimal} />
                    <Field name="shippingLength" label="Comprimento (cm)" component={renderInputDecimal} normalize={normalizeDecimal} />
                </Form.Group>

            </FormSection>

        </React.Fragment>
    )
}
