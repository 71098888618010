import React from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputString,
    renderInputCheckbox,
    renderSelectMerchant,
    renderInputShippingKeySelect
} from '../../../../Utils/RenderUtils';

import {
    normalizeZipcode
} from '../../../../Utils/NormalizeUtils';

export default function Tab01(props) {
    return (
        <React.Fragment>
            <FormSection fluid color="blue" title="Informações Gerais">
                <Form.Group widths={4}>
                    <Field name="active" label="Ativo" component={renderInputCheckbox} type="checkbox" />
                    <Field name="keys" label="Tipo" component={renderInputShippingKeySelect} />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Field name="name" label="Nome" component={renderInputString} />
                    <Field name="code" label="Código" component={renderInputString} />
                    <Field name="zipcode" label="Cep de Origem" component={renderInputString} normalize={normalizeZipcode}/>
                    <Field name="merchant" label="Loja" component={renderSelectMerchant} />
                </Form.Group>
            </FormSection>
        </React.Fragment>
    )
}
