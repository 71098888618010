import InputString from '../Components/Input/InputString';
import InputText from '../Components/Input/InputText';
import SelectCategory from '../Components/Input/SelectCategory';
import SelectProduct from '../Components/Input/SelectProduct';
import SelectMerchant from '../Components/Input/SelectMerchant';
import SelectManufacturer from '../Components/Input/SelectManufacturer';
import InputEntity from '../Components/Input/InputEntity';
import InputCheckbox from '../Components/Input/InputCheckbox';
import InputCheckboxKey from '../Components/Input/InputCheckboxKey';
import InputWysiwyg from '../Components/Input/InputWysiwyg';
import InputDateTimePicker from '../Components/Input/InputDateTimePicker';
import InputDatePicker from '../Components/Input/InputDatePicker';
import InputTimePicker from '../Components/Input/InputTimePicker';
import MultiSelectCategory from '../Components/Input/MultiSelectCategory';
import MultiSelectAdministrator from '../Components/Input/MultiSelectAdministrator';
import MultiSelectMerchant from '../Components/Input/MultiSelectMerchant';
import MultiSelectProduct from '../Components/Input/MultiSelectProduct';
import SelectPropertyGroup from '../Components/Input/SelectPropertyGroup';
import InputSelect from '../Components/Input/InputSelect';
import InputSelectKeys from '../Components/Input/InputSelectKeys';

export const renderInputString = formProps => {
    return <InputString {...formProps}/>
}

export const renderInputPassword = formProps => {
    return <InputString {...formProps} stringType="password"/>
}

export const renderInputInteger = formProps => {
    return <InputString {...formProps} stringType="integer"/>
}

export const renderInputDecimal = formProps => {
    return <InputString {...formProps} stringType="decimal"/>
}

export const renderSelectMerchant = formProps => {
    return <SelectMerchant {...formProps}/>
}

export const renderSelectCategory = formProps => {
    return <SelectCategory {...formProps}/>
}

export const renderSelectProduct = formProps => {
    return <SelectProduct {...formProps}/>
}

export const renderSelectPropertyGroup = formProps => {
    return <SelectPropertyGroup {...formProps}/>
}

export const renderSelectManufacturer = formProps => {
    return <SelectManufacturer {...formProps}/>
}

export const renderInputDocument = formProps => {
    return <InputString {...formProps}/>
}

export const renderInputEntity = formProps => {
    return <InputEntity {...formProps}/>
}

export const renderInputCheckbox = formProps => {
    return <InputCheckbox {...formProps}/>
}

export const renderInputCheckboxKey = formProps => {
    return <InputCheckboxKey {...formProps}/>
}

export const renderInputWysiwyg = formProps => {
    return <InputWysiwyg {...formProps}/>
}

export const renderInputDateTimePicker = formProps => {
    return <InputDateTimePicker {...formProps}/>
}

export const renderInputDatePicker = formProps => {
    return <InputDatePicker {...formProps}/>
}

export const renderInputTimePicker = formProps => {
    return <InputTimePicker {...formProps}/>
}

export const renderInputText = formProps => {
    return <InputText {...formProps}/>
}

export const renderMultiSelectCategory = formProps => {
    return <MultiSelectCategory {...formProps}/>
}

export const renderMultiSelectAdministrator = formProps => {
    return <MultiSelectAdministrator {...formProps}/>
}

export const renderMultiSelectMerchant = formProps => {
    return <MultiSelectMerchant {...formProps}/>
}

export const renderMultiSelectProduct = formProps => {
    return <MultiSelectProduct {...formProps}/>
}

export const renderInputGenderSelect = formProps => {
    const keyOptions = [
        {
            label: '--- Não informar ---',
            value: null
        },
        {
            label: 'Masculino',
            value: 'MALE'
        },
        {
            label: 'Feminino',
            value: 'FEMALE'
        },
        {
            label: 'Outro',
            value: 'OTHER'
        }
    ]

    return <InputSelect keyOptions={keyOptions} {...formProps}/>
}

export const renderInputShippingKeySelect = formProps => {
    const keyOptions = [
        {
            label: 'Faixa de Cep',
            value: 'ZipRange'
        },
//        {
//            label: 'Distância',
//            value: 'RadiusRange'
//        },
        {
            label: 'Correios PAC',
            value: 'CorreiosPac'
        },
        {
            label: 'Correios Sedex',
            value: 'CorreiosSedex'
        },
        {
            label: 'Correios Sedex 10',
            value: 'CorreiosSedex10'
        },
        {
            label: 'Correios Sedex 12',
            value: 'CorreiosSedex12'
        },
        {
            label: 'Correios Sedex Hoje',
            value: 'CorreiosSedexHoje'
        }
    ]

    return <InputSelectKeys keyOptions={keyOptions} {...formProps}/>
}

export const renderInputVoucherKeys = formProps => {
    const keyOptions = [
        {
            label: 'Cupom Único',
            value: 'SINGLE'
        },
        {
            label: 'Cupom em Lote',
            value: 'BATCH'
        },
        {
            label: 'Campanha',
            value: 'CAMPAIGN'
        },
    ]

    return <InputSelectKeys keyOptions={keyOptions} {...formProps}/>
}

export const renderInputBannerKeys = formProps => {
    const keyOptions = [
        {
            label: 'Carrossel Principal',
            value: 'CAROUSEL'
        },
        {
            label: 'Mini Banner',
            value: 'MINI'
        },
        {
            label: 'Inline Banner',
            value: 'INLINE'
        }
    ]
    return <InputSelectKeys keyOptions={keyOptions} {...formProps}/>
}

export const renderInputTemplateSelect = formProps => {
    const keyOptions = [
        {
            label: 'Default',
            value: 'Default'
        },
        {
            label: 'Avenue',
            value: 'Avenue'
        },
        {
            label: 'Hosoren',
            value: 'Hosoren'
        },
        {
            label: 'Material',
            value: 'Material'
        },
        {
            label: 'Jhosef (Delivery)',
            value: 'Jhosef'
        },
    ]

    return <InputSelect keyOptions={keyOptions} {...formProps}/>
}

export const renderInputPaymentGatewaySelect = formProps => {
    const keyOptions = [
        {
            label: 'Pagar.me',
            value: 'PAGARME'
        }
    ]

    return <InputSelect keyOptions={keyOptions} {...formProps}/>
}

export const renderInputEntityPagarmeSelect = formProps => {
    const keyOptions = [
        {
            label: 'Física',
            value: 'individual'
        },
        {
            label: 'Jurídica',
            value: 'company'
        }
    ]

    return <InputSelect keyOptions={keyOptions} {...formProps}/>
}

export const renderInputBankAccountTypeSelect = formProps => {
    const keyOptions = [
        {
            label: 'Corrente',
            value: 'checking'
        },
        {
            label: 'Poupança',
            value: 'savings'
        }
    ]

    return <InputSelect keyOptions={keyOptions} {...formProps}/>
}

export const renderInputBankNameSelect = formProps => {
    const keyOptions = [
        {
            value: '100',
            label: 'PLANNER CV S.A.'
        },
        {
            value: '101',
            label: 'RENASCENCA DTVM LTDA'
        },
        {
            value: '102',
            label: 'XP INVESTIMENTOS CCTVM S/A'
        },
        {
            value: '104',
            label: 'CAIXA ECONOMICA FEDERAL'
        },
        {
            value: '105',
            label: 'LECCA CFI S.A.'
        },
        {
            value: '107',
            label: 'BCO BOCOM BBM S.A.'
        },
        {
            value: '108',
            label: 'PORTOCRED S.A. - CFI'
        },
        {
            value: '111',
            label: 'OLIVEIRA TRUST DTVM S.A.'
        },
        {
            value: '113',
            label: 'MAGLIANO S.A. CCVM'
        },
        {
            value: '114',
            label: 'CENTRAL COOPERATIVA DE CRÉDITO NO ESTADO DO ESPÍRITO SANTO'
        },
        {
            value: '117',
            label: 'ADVANCED CC LTDA'
        },
        {
            value: '118',
            label: 'STANDARD CHARTERED BI S.A.'
        },
        {
            value: '119',
            label: 'BCO WESTERN UNION'
        },
        {
            value: '120',
            label: 'BCO RODOBENS S.A.'
        },
        {
            value: '121',
            label: 'BCO AGIBANK S.A.'
        },
        {
            value: '122',
            label: 'BCO BRADESCO BERJ S.A.'
        },
        {
            value: '124',
            label: 'BCO WOORI BANK DO BRASIL S.A.'
        },
        {
            value: '125',
            label: 'PLURAL BCO BM'
        },
        {
            value: '126',
            label: 'BR PARTNERS BI'
        },
        {
            value: '127',
            label: 'CODEPE CVC S.A.'
        },
        {
            value: '128',
            label: 'MS BANK S.A. BCO DE CÂMBIO'
        },
        {
            value: '129',
            label: 'UBS BRASIL BI S.A.'
        },
        {
            value: '130',
            label: 'CARUANA SCFI'
        },
        {
            value: '131',
            label: 'TULLETT PREBON BRASIL CVC LTDA'
        },
        {
            value: '132',
            label: 'ICBC DO BRASIL BM S.A.'
        },
        {
            value: '133',
            label: 'CRESOL CONFEDERAÇÃO'
        },
        {
            value: '134',
            label: 'BGC LIQUIDEZ DTVM LTDA'
        },
        {
            value: '136',
            label: 'UNICRED'
        },
        {
            value: '138',
            label: 'GET MONEY CC LTDA'
        },
        {
            value: '139',
            label: 'INTESA SANPAOLO BRASIL S.A. BM'
        },
        {
            value: '140',
            label: 'EASYNVEST - TÍTULO CV SA'
        },
        {
            value: '142',
            label: 'BROKER BRASIL CC LTDA.'
        },
        {
            value: '143',
            label: 'TREVISO CC S.A.'
        },
        {
            value: '144',
            label: 'BEXS BCO DE CAMBIO S.A.'
        },
        {
            value: '145',
            label: 'LEVYCAM CCV LTDA'
        },
        {
            value: '146',
            label: 'GUITTA CC LTDA'
        },
        {
            value: '149',
            label: 'FACTA S.A. CFI'
        },
        {
            value: '157',
            label: 'ICAP DO BRASIL CTVM LTDA.'
        },
        {
            value: '159',
            label: 'CASA CREDITO S.A. SCM'
        },
        {
            value: '163',
            label: 'COMMERZBANK BRASIL S.A. - BCO MÚLTIPLO'
        },
        {
            value: '169',
            label: 'BCO OLÉ BONSUCESSO CONSIGNADO S.A.'
        },
        {
            value: '173',
            label: 'BRL TRUST DTVM SA'
        },
        {
            value: '174',
            label: 'PERNAMBUCANAS FINANC S.A. CFI'
        },
        {
            value: '177',
            label: 'GUIDE'
        },
        {
            value: '180',
            label: 'CM CAPITAL MARKETS CCTVM LTDA'
        },
        {
            value: '182',
            label: 'DACASA FINANCEIRA S/A - SCFI'
        },
        {
            value: '183',
            label: 'SOCRED SA - SCMEPP'
        },
        {
            value: '184',
            label: 'BCO ITAÚ BBA S.A.'
        },
        {
            value: '188',
            label: 'ATIVA S.A. INVESTIMENTOS CCTVM'
        },
        {
            value: '189',
            label: 'HS FINANCEIRA'
        },
        {
            value: '190',
            label: 'SERVICOOP'
        },
        {
            value: '191',
            label: 'NOVA FUTURA CTVM LTDA.'
        },
        {
            value: '194',
            label: 'PARMETAL DTVM LTDA'
        },
        {
            value: '196',
            label: 'FAIR CC S.A.'
        },
        {
            value: '197',
            label: 'STONE PAGAMENTOS S.A.'
        },
        {
            value: '208',
            label: 'BANCO BTG PACTUAL S.A.'
        },
        {
            value: '212',
            label: 'BANCO ORIGINAL'
        },
        {
            value: '213',
            label: 'BCO ARBI S.A.'
        },
        {
            value: '217',
            label: 'BANCO JOHN DEERE S.A.'
        },
        {
            value: '218',
            label: 'BCO BS2 S.A.'
        },
        {
            value: '222',
            label: 'BCO CRÉDIT AGRICOLE BR S.A.'
        },
        {
            value: '224',
            label: 'BCO FIBRA S.A.'
        },
        {
            value: '233',
            label: 'BANCO CIFRA'
        },
        {
            value: '237',
            label: 'BCO BRADESCO S.A.'
        },
        {
            value: '241',
            label: 'BCO CLASSICO S.A.'
        },
        {
            value: '243',
            label: 'BCO MÁXIMA S.A.'
        },
        {
            value: '246',
            label: 'BCO ABC BRASIL S.A.'
        },
        {
            value: '249',
            label: 'BANCO INVESTCRED UNIBANCO S.A.'
        },
        {
            value: '250',
            label: 'BCV'
        },
        {
            value: '253',
            label: 'BEXS CC S.A.'
        },
        {
            value: '254',
            label: 'PARANA BCO S.A.'
        },
        {
            value: '260',
            label: 'NU PAGAMENTOS S.A.'
        },
        {
            value: '265',
            label: 'BCO FATOR S.A.'
        },
        {
            value: '266',
            label: 'BCO CEDULA S.A.'
        },
        {
            value: '268',
            label: 'BARI CIA HIPOTECÁRIA'
        },
        {
            value: '269',
            label: 'HSBC BANCO DE INVESTIMENTO'
        },
        {
            value: '270',
            label: 'SAGITUR CC LTDA'
        },
        {
            value: '271',
            label: 'IB CCTVM S.A.'
        },
        {
            value: '272',
            label: 'AGK CC S.A.'
        },
        {
            value: '273',
            label: 'CCR DE SÃO MIGUEL DO OESTE'
        },
        {
            value: '274',
            label: 'MONEY PLUS SCMEPP LTDA'
        },
        {
            value: '276',
            label: 'SENFF S.A. - CFI'
        },
        {
            value: '278',
            label: 'GENIAL INVESTIMENTOS CVM S.A.'
        },
        {
            value: '279',
            label: 'CCR DE PRIMAVERA DO LESTE'
        },
        {
            value: '280',
            label: 'AVISTA S.A. CFI'
        },
        {
            value: '281',
            label: 'CCR COOPAVEL'
        },
        {
            value: '283',
            label: 'RB CAPITAL INVESTIMENTOS DTVM LTDA.'
        },
        {
            value: '285',
            label: 'FRENTE CC LTDA.'
        },
        {
            value: '286',
            label: 'CCR DE OURO'
        },
        {
            value: '288',
            label: 'CAROL DTVM LTDA.'
        },
        {
            value: '289',
            label: 'DECYSEO CC LTDA.'
        },
        {
            value: '290',
            label: 'PAGSEGURO'
        },
        {
            value: '292',
            label: 'BS2 DTVM S.A.'
        },
        {
            value: '293',
            label: 'LASTRO RDV DTVM LTDA'
        },
        {
            value: '296',
            label: 'VISION S.A. CC'
        },
        {
            value: '298',
            label: 'VIPS CC LTDA.'
        },
        {
            value: '299',
            label: 'SOROCRED CFI S.A.'
        },
        {
            value: '300',
            label: 'BCO LA NACION ARGENTINA'
        },
        {
            value: '301',
            label: 'BPP IP S.A.'
        },
        {
            value: '306',
            label: 'PORTOPAR DTVM LTDA'
        },
        {
            value: '307',
            label: 'TERRA INVESTIMENTOS DTVM'
        },
        {
            value: '309',
            label: 'CAMBIONET CC LTDA'
        },
        {
            value: '310',
            label: 'VORTX DTVM LTDA.'
        },
        {
            value: '315',
            label: 'PI DTVM S.A.'
        },
        {
            value: '318',
            label: 'BCO BMG S.A.'
        },
        {
            value: '319',
            label: 'OM DTVM LTDA'
        },
        {
            value: '320',
            label: 'BCO CCB BRASIL S.A.'
        },
        {
            value: '321',
            label: 'CREFAZ SCMEPP LTDA'
        },
        {
            value: '322',
            label: 'CCR DE ABELARDO LUZ'
        },
        {
            value: '323',
            label: 'MERCADO PAGO'
        },
        {
            value: '325',
            label: 'ÓRAMA DTVM S.A.'
        },
        {
            value: '329',
            label: 'QI SCD S.A.'
        },
        {
            value: '330',
            label: 'BANCO BARI S.A.'
        },
        {
            value: '331',
            label: 'FRAM CAPITAL DTVM S.A.'
        },
        {
            value: '332',
            label: 'ACESSO'
        },
        {
            value: '335',
            label: 'BANCO DIGIO'
        },
        {
            value: '336',
            label: 'BCO C6 S.A.'
        },
        {
            value: '340',
            label: 'SUPER PAGAMENTOS E ADMINISTRACAO DE MEIOS ELETRONICOS S.A.'
        },
        {
            value: '341',
            label: 'ITAÚ UNIBANCO S.A.'
        },
        {
            value: '342',
            label: 'CREDITAS SCD'
        },
        {
            value: '343',
            label: 'FFA SCMEPP LTDA.'
        },
        {
            value: '348',
            label: 'BCO XP S.A.'
        },
        {
            value: '349',
            label: 'AMAGGI S.A. CFI'
        },
        {
            value: '352',
            label: 'TORO CTVM LTDA'
        },
        {
            value: '354',
            label: 'NECTON INVESTIMENTOS S.A CVM'
        },
        {
            value: '355',
            label: 'ÓTIMO SCD S.A.'
        },
        {
            value: '364',
            label: 'GERENCIANET PAGAMENTOS DO BRASIL LTDA'
        },
        {
            value: '366',
            label: 'BCO SOCIETE GENERALE BRASIL'
        },
        {
            value: '370',
            label: 'BCO MIZUHO S.A.'
        },
        {
            value: '376',
            label: 'BCO J.P. MORGAN S.A.'
        },
        {
            value: '383',
            label: 'JUNO'
        },
        {
            value: '389',
            label: 'BCO MERCANTIL DO BRASIL S.A.'
        },
        {
            value: '394',
            label: 'BCO BRADESCO FINANC. S.A.'
        },
        {
            value: '399',
            label: 'KIRTON BANK'
        },
        {
            value: '403',
            label: 'CORA CDS S.A.'
        },
        {
            value: '412',
            label: 'BCO CAPITAL S.A.'
        },
        {
            value: '422',
            label: 'BCO SAFRA S.A.'
        },
        {
            value: '456',
            label: 'BCO MUFG BRASIL S.A.'
        },
        {
            value: '464',
            label: 'BCO SUMITOMO MITSUI BRASIL S.A.'
        },
        {
            value: '473',
            label: 'BCO CAIXA GERAL BRASIL S.A.'
        },
        {
            value: '477',
            label: 'CITIBANK N.A.'
        },
        {
            value: '479',
            label: 'BCO ITAUBANK S.A.'
        },
        {
            value: '487',
            label: 'DEUTSCHE BANK S.A.BCO ALEMAO'
        },
        {
            value: '488',
            label: 'JPMORGAN CHASE BANK'
        },
        {
            value: '492',
            label: 'ING BANK N.V.'
        },
        {
            value: '495',
            label: 'BCO LA PROVINCIA B AIRES BCE'
        },
        {
            value: '505',
            label: 'BCO CREDIT SUISSE S.A.'
        },
        {
            value: '545',
            label: 'SENSO CCVM S.A.'
        },
        {
            value: '600',
            label: 'BCO LUSO BRASILEIRO S.A.'
        },
        {
            value: '604',
            label: 'BCO INDUSTRIAL DO BRASIL S.A.'
        },
        {
            value: '610',
            label: 'BCO VR S.A.'
        },
        {
            value: '611',
            label: 'BCO PAULISTA S.A.'
        },
        {
            value: '612',
            label: 'BCO GUANABARA S.A.'
        },
        {
            value: '613',
            label: 'OMNI BANCO S.A.'
        },
        {
            value: '623',
            label: 'BANCO PAN'
        },
        {
            value: '626',
            label: 'BCO FICSA S.A.'
        },
        {
            value: '630',
            label: 'SMARTBANK'
        },
        {
            value: '633',
            label: 'BCO RENDIMENTO S.A.'
        },
        {
            value: '634',
            label: 'BCO TRIANGULO S.A.'
        },
        {
            value: '637',
            label: 'BCO SOFISA S.A.'
        },
        {
            value: '643',
            label: 'BCO PINE S.A.'
        },
        {
            value: '652',
            label: 'ITAÚ UNIBANCO HOLDING S.A.'
        },
        {
            value: '653',
            label: 'BCO INDUSVAL S.A.'
        },
        {
            value: '654',
            label: 'BCO A.J. RENNER S.A.'
        },
        {
            value: '655',
            label: 'BCO VOTORANTIM S.A.'
        },
        {
            value: '707',
            label: 'BCO DAYCOVAL S.A'
        },
        {
            value: '712',
            label: 'BCO OURINVEST S.A.'
        },
        {
            value: '739',
            label: 'BCO CETELEM S.A.'
        },
        {
            value: '741',
            label: 'BCO RIBEIRAO PRETO S.A.'
        },
        {
            value: '743',
            label: 'BANCO SEMEAR'
        },
        {
            value: '745',
            label: 'BCO CITIBANK S.A.'
        },
        {
            value: '746',
            label: 'BCO MODAL S.A.'
        },
        {
            value: '747',
            label: 'BCO RABOBANK INTL BRASIL S.A.'
        },
        {
            value: '748',
            label: 'BCO COOPERATIVO SICREDI S.A.'
        },
        {
            value: '751',
            label: 'SCOTIABANK BRASIL'
        },
        {
            value: '752',
            label: 'BCO BNP PARIBAS BRASIL S A'
        },
        {
            value: '753',
            label: 'NOVO BCO CONTINENTAL S.A. - BM'
        },
        {
            value: '754',
            label: 'BANCO SISTEMA'
        },
        {
            value: '755',
            label: 'BOFA MERRILL LYNCH BM S.A.'
        },
        {
            value: '756',
            label: 'BANCOOB'
        },
        {
            value: '757',
            label: 'BCO KEB HANA DO BRASIL S.A.'
        },
        {
            value: '000',
            label: 'Banco não informado'
        },
        {
            value: '001',
            label: 'BCO DO BRASIL S.A.'
        },
        {
            value: '003',
            label: 'BCO DA AMAZONIA S.A.'
        },
        {
            value: '004',
            label: 'BCO DO NORDESTE DO BRASIL S.A.'
        },
        {
            value: '007',
            label: 'BNDES'
        },
        {
            value: '010',
            label: 'CREDICOAMO'
        },
        {
            value: '011',
            label: 'C.SUISSE HEDGING-GRIFFO CV S/A'
        },
        {
            value: '012',
            label: 'BANCO INBURSA'
        },
        {
            value: '014',
            label: 'STATE STREET BR S.A. BCO COMERCIAL'
        },
        {
            value: '015',
            label: 'UBS BRASIL CCTVM S.A.'
        },
        {
            value: '016',
            label: 'CCM DESP TRÂNS SC E RS'
        },
        {
            value: '017',
            label: 'BNY MELLON BCO S.A.'
        },
        {
            value: '018',
            label: 'BCO TRICURY S.A.'
        },
        {
            value: '021',
            label: 'BCO BANESTES S.A.'
        },
        {
            value: '024',
            label: 'BCO BANDEPE S.A.'
        },
        {
            value: '025',
            label: 'BCO ALFA S.A.'
        },
        {
            value: '029',
            label: 'BANCO ITAÚ CONSIGNADO S.A.'
        },
        {
            value: '033',
            label: 'BCO SANTANDER (BRASIL) S.A.'
        },
        {
            value: '036',
            label: 'BCO BBI S.A.'
        },
        {
            value: '037',
            label: 'BCO DO EST. DO PA S.A.'
        },
        {
            value: '040',
            label: 'BCO CARGILL S.A.'
        },
        {
            value: '041',
            label: 'BCO DO ESTADO DO RS S.A.'
        },
        {
            value: '047',
            label: 'BCO DO EST. DE SE S.A.'
        },
        {
            value: '060',
            label: 'CONFIDENCE CC S.A.'
        },
        {
            value: '062',
            label: 'HIPERCARD BM S.A.'
        },
        {
            value: '063',
            label: 'BANCO BRADESCARD'
        },
        {
            value: '064',
            label: 'GOLDMAN SACHS DO BRASIL BM S.A'
        },
        {
            value: '065',
            label: 'BCO ANDBANK S.A.'
        },
        {
            value: '066',
            label: 'BCO MORGAN STANLEY S.A.'
        },
        {
            value: '069',
            label: 'BCO CREFISA S.A.'
        },
        {
            value: '070',
            label: 'BRB - BCO DE BRASILIA S.A.'
        },
        {
            value: '074',
            label: 'BCO. J.SAFRA S.A.'
        },
        {
            value: '075',
            label: 'BCO ABN AMRO S.A.'
        },
        {
            value: '076',
            label: 'BCO KDB BRASIL S.A.'
        },
        {
            value: '077',
            label: 'BANCO INTER'
        },
        {
            value: '078',
            label: 'HAITONG BI DO BRASIL S.A.'
        },
        {
            value: '079',
            label: 'BCO ORIGINAL DO AGRO S/A'
        },
        {
            value: '080',
            label: 'B&amp;T CC LTDA.'
        },
        {
            value: '081',
            label: 'BANCOSEGURO S.A.'
        },
        {
            value: '082',
            label: 'BANCO TOPÁZIO S.A.'
        },
        {
            value: '083',
            label: 'BCO DA CHINA BRASIL S.A.'
        },
        {
            value: '084',
            label: 'UNIPRIME NORTE DO PARANÁ - CC'
        },
        {
            value: '085',
            label: 'COOP CENTRAL AILOS'
        },
        {
            value: '089',
            label: 'CCR REG MOGIANA'
        },
        {
            value: '091',
            label: 'CCCM UNICRED CENTRAL RS'
        },
        {
            value: '092',
            label: 'BRK S.A. CFI'
        },
        {
            value: '093',
            label: 'PÓLOCRED SCMEPP LTDA.'
        },
        {
            value: '094',
            label: 'BANCO FINAXIS'
        },
        {
            value: '095',
            label: 'TRAVELEX BANCO DE CÂMBIO S.A.'
        },
        {
            value: '096',
            label: 'BCO B3 S.A.'
        },
        {
            value: '097',
            label: 'CREDISIS CENTRAL DE COOPERATIVAS DE CRÉDITO LTDA.'
        },
        {
            value: '098',
            label: 'CREDIALIANÇA CCR'
        },
        {
            value: '099',
            label: 'UNIPRIME CENTRAL CCC LTDA.'
        }
    ]

    return <InputSelect keyOptions={keyOptions} {...formProps}/>
}
