import _ from 'lodash';
import faker from 'faker';
import { capitalizeFirstLetter } from './FunctionsUtils';

export function ProductFaker(complement) {
    const product = {
        active: true,
        code: faker.random.alphaNumeric(8).toUpperCase(),
        barcode: faker.random.number({ min: 1000000000000, max: 9999999999999}).toString(),
        name: faker.commerce.productName(),
        description: faker.lorem.paragraphs(8),
        weight: faker.random.number({ min: 300, max: 3000}),
        height: faker.random.number({ min: 15, max: 120}),
        width: faker.random.number({ min: 15, max: 120}),
        length: faker.random.number({ min: 15, max: 120}),
        stock: faker.random.number({ min: 30, max: 200}),
        stockBusy: faker.random.number(30),
        stockHold: faker.random.number(10),
        prices: [
            {
                amount: 0,
                price: faker.random.float({ min: 8, max: 200})
            }
        ],
        keys: [],
        fields: {
            shortDescription: faker.commerce.productDescription()
        }
    }

    product.fields.shortName = product.name.split(' ')[product.name.split(' ').length-1]

    if(product.prices[0].price > 120){
        product.prices[0].oldPrice = faker.random.float({ min: product.prices[0].price+1, max: product.prices[0].price*1.3})
    }

    if(product.stock-product.stockBusy > 20){
        product.keys.push('OVERSTOCK');
    }

    return {...product, ...(complement || {})}
}

export function CategoryFaker(complement) {
    const category = {
        active: true,
        name: faker.commerce.department(),
        sort: faker.random.number(10),
        description: faker.lorem.paragraphs(4),
        keys: ['DISPLAY_MENU'],
        fields: {
            shortDescription: faker.lorem.paragraphs(2)
        },
    }

    return {...category, ...(complement || {})}
}

export function ManufacturerFaker(complement) {
    const manufacturer = {
        active: true,
        name: faker.company.companyName(),
        sort: faker.random.number(10),
        description: faker.lorem.paragraphs(4),
        fields: {
            shortDescription: faker.lorem.paragraphs(2)
        },
    }

    return {...manufacturer, ...(complement || {})}
}

export function PropertyFaker(complement, name, size) {
    const options = {
        'Material': {
            helper: faker.commerce.productMaterial
        },
        'Cor': {
            helper: () => capitalizeFirstLetter(faker.commerce.color())
        },
        'Adjetivo': {
            helper: faker.commerce.productAdjective
        },
        'Área de Operação': {
            helper: faker.name.jobArea
        },
        'Tipo de Operação': {
            helper: faker.name.jobType
        }
    }

    name = name || ['Material', 'Cor', 'Adjetivo', 'Área de Operação', 'Tipo de Operação'][faker.random.number(4)];
    size = size || 5;
    const helper = Object.keys(options).includes(name) ? options[name].helper : faker.commerce.productAdjective;

    const property = {
        active: true,
        name: name,
        sort: faker.random.number(10),
        keys: ['FILTER'],
        properties: [],
    }

    for(var i=0; i<size; i++){
        const propName = helper();

        if(_.findIndex(property.properties, ['name', propName]) < 0){
            property.properties.push(
                {
                    active: true,
                    name: propName,
                    sort: faker.random.number(10)
                }
            )    
        }
    }

    return {...property, ...(complement || {})}
}

export function ShippingFaker(complement) {
    const shipping = {
        active: true,
        name: faker.company.companyName(),
        code: faker.random.alphaNumeric(8).toUpperCase(),
        zipcode: '39400-390',
        description: faker.lorem.paragraphs(4),
        packCost: 3
    }

    return {...shipping, ...(complement || {})}
}

export function BannerFaker(complement) {
    const banner = {
        active: true,
        title: faker.company.catchPhrase(),
        description: faker.lorem.paragraph(),
    }

    return {...banner, ...(complement || {})}
}

export function VoucherFaker(complement) {
    const voucher = {
        active: true,
        code: faker.random.alpha(8).toUpperCase(),
        description: faker.lorem.paragraph(),
    }

    return {...voucher, ...(complement || {})}
}