import _ from 'lodash';

import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { change } from 'redux-form';

import { Form, Select } from 'semantic-ui-react'
import slugify from 'react-slugify';

import { getInputError } from '../../Utils/FunctionsUtils';

export default function InputSelectKeys(props) {
    const { input, label, meta, disabled, keyOptions} = props;
    const dispatch = useDispatch();

    const formValues = useSelector(state => state.form[props.meta.form].values[input.name]);

    const valueOptions = keyOptions.map(e => {
        return e.value;
    });

    const [inputValue, setInputValue] = useState(_.compact(valueOptions.map(e => {
        if(formValues.includes(e)){
            return e;
        }
    })));

    const getOptions = () => {
        let options = [];

        keyOptions.forEach((item, index) => {
            options.push({
                key: `${item.value}-option-${index}-${slugify(label)}`,
                text: `${item.label}`,
                value: item.value
            })
        })

        return options;
    }

    const handleChange = (value, type) => {
        _.remove(formValues, function(n) {
            return valueOptions.includes(n);
        });

        let newValues = Object.assign([], formValues);
        newValues.push(value);

        dispatch(change(meta.form, input.name, newValues));
        setInputValue([value])
    }

    return (
        <Form.Field
            {...input}
            disabled={disabled}
            control={Select}
            value={inputValue[0]}
            label={{ children: label, htmlFor: `form-select-control-${slugify(label)}` }}
            options={getOptions()}
            onChange={(param, data) => handleChange(data.value, 'change')}
            onBlur={(param, data) => handleChange(data.value, 'blur')}
            error={getInputError(meta)} />
    )
}
