import Tab01 from '../Components/CreateForm/Tab01';

export const mountCreatePanes = (props) => {
    return <Tab01 {...props} />
}

export const createInitialValues = {
    permissions: {
        "order": {"list":true, "read":true, "create":false, "delete":true, "update":true},
        "banner": {"list":true, "read":true, "create":true, "delete":true, "update":true},
        "address": {"list":true, "read":true, "create":true, "delete":true, "update":true},
        "product": {"list":true, "read":true, "create":true, "delete":true, "update":true},
        "voucher": {"list":true, "read":true, "create":true, "delete":true, "update":true},
        "category": {"list":true, "read":true, "create":true, "delete":true, "update":true},
        "customer": {"list":true, "read":true, "create":false, "delete":true, "update":true},
        "merchant": {"list":true, "read":true, "create":false, "delete":false, "update":true},
        "property": {"list":true, "read":true, "create":true, "delete":true, "update":true},
        "shipping": {"list":true, "read":true, "create":true, "delete":true, "update":true},
        "creditCard": {"list":true, "read":true, "create":true, "delete":true, "update":true},
        "manufacturer": {"list":true, "read":true, "create":true, "delete":true, "update":true},
        "subscription": {"list":true, "read":true, "create":true, "delete":true, "update":true},
        "administrator": {"list":true, "read":true, "create":true, "delete":true, "update":true},
        "institutional": {"list":true, "read":true, "create":true, "delete":true, "update":true},
        "subscriptionPlan": {"list":true, "read":true, "create":true, "delete":true, "update":true}
    }
}
