import React from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputString,
    renderInputInteger,
    renderInputCheckbox,
    renderInputWysiwyg,
    renderSelectMerchant
} from '../../../../Utils/RenderUtils';

import { normalizeInteger, normalizeSlug } from '../../../../Utils/NormalizeUtils';

export default function Tab01() {
    return (
        <React.Fragment>
            <FormSection fluid color="green" title="Informações Gerais">
                <Form.Group widths={2}>
                    <Field name="active" label="Ativo" component={renderInputCheckbox} type="checkbox" />
                    <Field name="sort" label="Prioridade" component={renderInputInteger} normalize={normalizeInteger} />
                    <Field name="merchant" label="Loja" component={renderSelectMerchant} />
                </Form.Group>

                <Form.Group widths={2}>
                    <Field name="groupName" label="Grupo" component={renderInputString} />
                    <Field name="title" label="Título da Página" component={renderInputString} />
                    <Field name="slug" label="URL" component={renderInputString} normalize={normalizeSlug} />
                </Form.Group>

                <Form.Group widths='equal'>
                    <Field name="content" label="Conteúdo da Página" component={renderInputWysiwyg} />
                </Form.Group>
            </FormSection>
        </React.Fragment>
    )
}
