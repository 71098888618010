export const ValidateCreate = (formValues) => {
    const errors = {};

    if(!formValues.name){
        errors.name = 'Esse campo é obrigatório'
    }

    if(!formValues.email){
        errors.email = 'Esse campo é obrigatório'
    }

    return errors;
}

export const ValidateUpdate = (formValues) => {
    return ValidateCreate(formValues);
}
