export const tableConf = {
    searchFields: ['id', 'title']
}

export const tableHeader = [
    {
        field: 'id',
        label: 'ID',
        dataType: 'integer',
        sortable: true
    },
    {
        field: 'active',
        label: 'Ativo',
        dataType: 'boolean',
        sortable: true
    },
    {
        field: 'createdAt',
        label: 'Data de Criação',
        dataType: 'datetime',
        sortable: true
    },
    {
        field: 'title',
        label: 'Título',
        dataType: 'string',
        sortable: true,
        className: 'big-content-cell'
    },
    {
        field: 'keys',
        label: 'Tipo',
        dataType: 'array',
        sortable: true,
        map: {
            CAROUSEL: 'Carrossel Principal',
            MINI: 'Mini Banner',
            INLINE: 'Banner Inline'
        }
    }
];
