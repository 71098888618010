import Tab01 from '../Components/CreateForm/Tab01';

export const mountCreatePanes = (props) => {
    return <Tab01 {...props} />
}

export const createInitialValues = {
    active: false,
    amount: 1,
    keys: [
        'SINGLE'
    ]
}
