import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { Icon, Modal, Button, Header, Form} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { getToast } from '../../../../Utils/ToastUtils';
import ToastMessage from '../../../../Components/Modal/ToastMessage';

import {
    Read as ReadOrder,
    ChangeStatus
} from '../../../../Actions/Order';

export default function ChangeStatusModal(props) {
    const [formValues, setFormValues] = useState({});

    const dispatch = useDispatch();

    const handleChange = (e, {name, value}) => {
        setFormValues({...formValues, ...{[name]: value}})
    };

    const handleSubmit = () => {
        dispatch(ChangeStatus(props.initialValues.uuid, props.changeStatus.status, formValues)).then((result) => {
            if(result.statusCode === 201){
                setFormValues({});
                props.setChangeStatus({open: false});
                getToast(<ToastMessage header='Sucesso!' content={`Status alterado`}/>, toast.TYPE.SUCCESS)();
                dispatch(ReadOrder(props.initialValues.uuid));
            }
        });
    }

    const getInputs = () => {
        if(props.changeStatus.infoTitle){
            return(
                <React.Fragment>
                    <Form.Input fluid label={props.changeStatus.infoTitle.info} name='info' value={formValues.info} onChange={handleChange} />
                    <Form.Input fluid label={props.changeStatus.infoTitle.internalInfo} name='internalInfo' value={formValues.internalInfo} onChange={handleChange} />
                </React.Fragment>
            )
        }
    }

    return (
        <Modal
            style={{height: 'auto'}}
            open={props.changeStatus.open}
            size={'large'}>

            <Header icon>
                <Icon name='exchange' />
                Mudar pedido para {props.changeStatus.title}
            </Header>

            <Modal.Content>
                <Form onSubmit={handleSubmit}>
                    <Form.Group widths='equal'>
                        {getInputs()}
                    </Form.Group>
                </Form>
            </Modal.Content>

            <Modal.Actions>
                <Button inverted color='green' onClick={() => { handleSubmit(); }}>
                    <Icon name='plus' /> Salvar
                </Button>
                <Button inverted color='red' onClick={() => { props.setChangeStatus({open: false}); }}>
                    Cancelar
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
