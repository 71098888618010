import React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { List, Button, Icon } from 'semantic-ui-react';

import { Read } from '../../Actions/Category';

export default function SubCategoryListItem(props) {
    const { uuid } = props;
 
    const entities = useSelector(state => state.categories);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!entities[props.uuid]){
            dispatch(Read(props.uuid));
        }
    }, []);

    if(entities[props.uuid]){
        return (
            <List.Item>
                <List.Icon name='sort alphabet up' size='large' verticalAlign='middle' />
                <List.Content>
                    <List.Header>{entities[uuid].name}</List.Header>
                    <List.Description>
                        <Button.Group style={{marginTop: '10px'}}>
                            <Button icon onClick={(e) => {props.shiftUpAction(); e.preventDefault()}}>
                                <Icon name='arrow up' />
                            </Button>
                            <Button icon onClick={(e) => {props.shiftDownAction(); e.preventDefault()}}>
                                <Icon name='arrow down' />
                            </Button>
                            <Button icon color="red" onClick={(e) => {props.removeAction(); e.preventDefault()}}>
                                <Icon name='times' />
                            </Button>
                        </Button.Group>
                    </List.Description>
                </List.Content>
            </List.Item>
        )
    }
    
    return null;
}
