export { List as ListTable, Create, Read, Update, Delete } from '../../../Actions/Shipping';

export * from './List';
export * from './Edit';
export * from './Create';
export * from './Validation';

export const entityReducer = 'shippings';
export const entityListReducer = 'shippingList';

export const verboseName = 'Transportadora'
export const verboseNamePlural = 'Transportadoras'
export const icon = 'shipping fast'
export const displayPropertyName = 'name';

export const editPath = '/transportadora'
export const listPath = '/transportadoras'

export const createFormName = 'shippingCreate';
export const editFormName = 'shippingUpdate';

export const permission = 'shipping';