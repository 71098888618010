import Tab01 from '../Components/UpdateForm/Tab01';
import Tab02 from '../Components/UpdateForm/Tab02';
import Tab03 from '../Components/UpdateForm/Tab03';
import Tab04 from '../Components/UpdateForm/Tab04';
import Tab05 from '../Components/UpdateForm/Tab05';
import Tab06 from '../Components/UpdateForm/Tab06';

import { Update as UpdateImage } from '../../../Actions/Image';

export const preFilter = null;

export const preDispatch = (formValues, initialValues, dispatch) => {
    if(formValues.images){
        formValues.images.forEach((uuid, index) => {
            dispatch(UpdateImage(uuid, {sort: index}));
        })
    }

    return formValues;
}

export const mountEditPanes = (props) => {
    return [
        {
            menuItem: { key: 'general', icon: 'info', content: 'Dados Gerais' },
            render: () => <Tab01 {...props} />
        },
        {
            menuItem: { key: 'images', icon: 'images', content: 'Imagens' },
            render: () => <Tab02 {...props} />
        },
        {
            menuItem: { key: 'trademark', icon: 'trademark', content: 'Informações Empresariais' },
            render: () => <Tab03 {...props} />
        },
        {
            menuItem: { key: 'settings', icon: 'settings', content: 'Configurações da Loja' },
            render: () => <Tab04 {...props} />
        },
        {
            menuItem: { key: 'payment', icon: 'dollar', content: 'Configurações de Pagamento' },
            render: () => <Tab05 {...props} />
        },
        {
            menuItem: { key: 'template', icon: 'trademark', content: 'Aparência' },
            render: () => <Tab06 {...props} />
        }
    ]
}