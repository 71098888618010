import _ from 'lodash';

import React from 'react';
import { useSelector } from "react-redux";
import { Field } from 'redux-form';
import { Form, Button, Icon, Segment, Accordion, Card, Header } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputString,
    renderInputInteger,
    renderInputDecimal
} from '../../../../Utils/RenderUtils';

import {
    normalizeInteger,
    normalizeZipcode,
    normalizeDecimal
} from '../../../../Utils/NormalizeUtils';

export default function Tab03(props) {
    const formValues = useSelector(state => state.form[props.form].values);

    const removeElement = (index) => {
        let newList = _.cloneDeep(formValues.zipRanges);
        newList.splice(index, 1);
        props.change('zipRanges', newList);
    }

    const addZipPrice = (index) => {
        let newList = _.cloneDeep(formValues.zipRanges);
        newList[index].prices.push({});
        props.change('zipRanges', newList);
    }

    const removeZipPrice = (indexZip, indexPrice) => {
        let newList = _.cloneDeep(formValues.zipRanges);
        newList[indexZip].prices.splice(indexPrice, 1);
        props.change('zipRanges', newList);
    }

    const renderZiprangeField = (index) => {
        const renderZipPriceField = () => {
            let zipPriceFields = [];

            formValues.zipRanges[index].prices.map((entity, indexPrice) => {
                zipPriceFields.push(
                    <Card key={`zipRangesprices-${index}-${indexPrice}`}>
                        <Card.Content>
                            <Card.Description>
                                <Form.Group widths={2}>
                                    <Field name={`zipRanges[${index}].prices[${indexPrice}].initWeight`} label="Peso Inicial (g)" component={renderInputDecimal} normalize={normalizeDecimal} />
                                    <Field name={`zipRanges[${index}].prices[${indexPrice}].endWeight`} label="Peso Final (g)" component={renderInputDecimal} normalize={normalizeDecimal} />
                                </Form.Group>
                                <Form.Group widths={2}>
                                    <Field name={`zipRanges[${index}].prices[${indexPrice}].cost`} label="Custo do Frete (R$)" component={renderInputDecimal} normalize={normalizeDecimal} />
                                    <Field name={`zipRanges[${index}].prices[${indexPrice}].price`} label="Valor do Frete (R$)" component={renderInputDecimal} normalize={normalizeDecimal} />
                                </Form.Group>
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <div className='ui two buttons'>
                                <Button basic icon color="red" onClick={(e) => {removeZipPrice(index, indexPrice); e.preventDefault()}}>
                                    <Icon name='times' /> Remover
                                </Button>
                            </div>
                        </Card.Content>
                    </Card>
                )
            })

            return zipPriceFields;
        }

        return (
            {
                key: `zipRanges-${index}`,
                title: {
                    content: (
                        <React.Fragment>
                            <Button icon size="mini" color="red" onClick={(e) => {removeElement(index); e.preventDefault()}}>
                                <Icon name='times' /> Remover
                            </Button>
                            <span style={{marginLeft: '10px'}}>{formValues.zipRanges[index].name}</span>
                        </React.Fragment>
                    )
                },
                content: {
                    content: (
                        <React.Fragment>
                            <Segment>
                                <Header as='h3'>
                                    <Icon name='info' />
                                    <Header.Content style={{padding: 'inherit'}}>Informações</Header.Content>
                                </Header>
                                <Form.Group>
                                    <Field name={`zipRanges[${index}].name`} label="Nome" component={renderInputString} />
                                    <Field name={`zipRanges[${index}].initZipcode`} label="Cep Inicial" component={renderInputString} normalize={normalizeZipcode} format={normalizeZipcode} />
                                    <Field name={`zipRanges[${index}].endZipcode`} label="Cep Final" component={renderInputString} normalize={normalizeZipcode} format={normalizeZipcode} />
                                </Form.Group>
                                <Form.Group>
                                    <Field name={`zipRanges[${index}].deliveryTime`} label="Tempo de entrega (m)" component={renderInputInteger} normalize={normalizeInteger} />
                                    <Field name={`zipRanges[${index}].overWeightCost`} label="Custo peso extra (R$/Kg)" component={renderInputDecimal} normalize={normalizeDecimal}/>
                                    <Field name={`zipRanges[${index}].adValorem`} label="Ad Valorem (%)" component={renderInputDecimal} normalize={normalizeDecimal} />    
                                </Form.Group>
                            </Segment>

                            <Segment>
                                <Header as='h3'>
                                    <Icon name='dollar' />
                                    <Header.Content style={{padding: 'inherit'}}>Preços</Header.Content>
                                </Header>

                                <Card.Group>
                                    {renderZipPriceField()}
                                </Card.Group>

                                <Button style={{marginTop: '15px'}} basic positive onClick={(e) => {addZipPrice(index); e.preventDefault();}}>
                                    <Icon name='dollar' />
                                    Adicionar Faixa de Preço
                                </Button>
                            </Segment>
                        </React.Fragment>
                    )
                }
            }
        )
    }

    const renderZipRanges = () => {
        let accordionPanels = [];

        formValues.zipRanges.map((entity, index) => {
            accordionPanels.push(renderZiprangeField(index));
        })

        return <Accordion fluid styled panels={accordionPanels} />
    }
    return (
        <React.Fragment>
            <FormSection fluid color="green" title="Faixas de CEP" key={`allzips-${formValues.zipRanges.length}`}>

                {renderZipRanges()}

                <Button style={{marginTop: '15px'}} positive onClick={(e) => {props.change('zipRanges', _.concat(formValues.zipRanges, [{shipping: formValues.uuid, name: '', prices: []}])); e.preventDefault();}}>
                    <Icon name='list' />
                    Adicionar Faixa de Frete
                </Button>
            </FormSection>
        </React.Fragment>
    )
}
