import React from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputDecimal,
    renderMultiSelectProduct
} from '../../../../Utils/RenderUtils';

import {
    normalizeDecimal,
    normalizePercentual
} from '../../../../Utils/NormalizeUtils';

export default function Tab02() {

    return (
        <React.Fragment>
            <FormSection fluid color="green" title="Descontos nos Produtos">
                <Form.Group widths={4}>
                    <Field name="percentualDiscount" label="Desconto Percentual (%)" component={renderInputDecimal} normalize={normalizePercentual} />
                    <Field name="absoluteDiscount" label="Desconto Absoluto (R$)" component={renderInputDecimal} normalize={normalizeDecimal} />
                </Form.Group>
            </FormSection> 

            <FormSection fluid color="blue" title="Descontos no Frete">
                <Form.Group widths={4}>
                    <Field name="shippingDiscount" label="Desconto (%)" component={renderInputDecimal} normalize={normalizeDecimal} />
                </Form.Group>
            </FormSection>

            <FormSection fluid color="yellow" title="Brindes">
                <Form.Group widths='equal'>
                    <Field name="gifts" label="Produtos" component={renderMultiSelectProduct} />
                </Form.Group>
            </FormSection>
        </React.Fragment>
    )
}
