import ApiCommerce from '../Api/ApiCommerce';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_DATA = 'SIGN_DATA';
export const SIGN_REFRESH = 'SIGN_REFRESH';
export const SIGN_ERROR = 'SIGN_ERROR';

export const signIn = (username, password) => async dispatch => {
    const response = await new ApiCommerce().post('/auth/', {username: username, password: password});

    if(response.status === 200){
        dispatch({ type: SIGN_IN, payload: response.data });
    } else {
        dispatch({ type: SIGN_ERROR, payload: response.data });
    }
}

export const signOut = () => {
    return { type: SIGN_OUT };
}

export const signData = () => async dispatch => {
    const response = await new ApiCommerce().get('/auth/me/');

    if(response.status === 200){
        dispatch({ type: SIGN_DATA, payload: response.data });
    } else {
        dispatch({ type: SIGN_ERROR, payload: response.data });
    }
}

export const refreshToken = (refresh) => async dispatch => {
    const response = await new ApiCommerce().post('/auth/refresh/', {refresh: refresh});

    if(response.status === 200){
        dispatch({ type: SIGN_REFRESH, payload: response.data });
        dispatch(signData());
    } else {
        dispatch({ type: SIGN_ERROR, payload: response.data });
    }
}