import _ from 'lodash';

import React from 'react';
import { useSelector } from "react-redux";
import { Field } from 'redux-form';
import { Form, Button, Icon} from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';
import InputSelect from '../../../../Components/Input/InputSelect';

import {
    renderInputString,
    renderInputInteger,
    renderInputCheckbox,
    renderInputWysiwyg,
    renderSelectMerchant,
    renderInputBannerKeys,
    renderSelectProduct,
    renderSelectCategory
} from '../../../../Utils/RenderUtils';

import { normalizeInteger } from '../../../../Utils/NormalizeUtils';

const destinationOptions = formProps => {
    const keyOptions = [
        {
            label: '--- Nenhum ---',
            value: null
        },
        {
            label: 'Produto',
            value: 'PRODUCT'
        },
        {
            label: 'Categoria',
            value: 'CATEGORY'
        },
        {
            label: 'URL Manual',
            value: 'URL'
        }
    ]

    return <InputSelect keyOptions={keyOptions} {...formProps} />
}

export default function Tab01(props) {
    const fields = useSelector(state => state.form[props.form].values.fields);
    const buttons = Object.assign([], (fields.buttons || []));

    return (
        <React.Fragment>
            <FormSection fluid color="green" title="Informações Gerais">
                <Form.Group widths={2}>
                    <Field name="active" label="Ativo" component={renderInputCheckbox} type="checkbox" />
                    <Field name="sort" label="Prioridade" component={renderInputInteger} normalize={normalizeInteger} />
                </Form.Group>

                <Form.Group widths={2}>
                    <Field name="title" label="Título do Banner" component={renderInputString} />
                    <Field name="url" label="URL de destino" component={renderInputString} />
                </Form.Group>

                <Form.Group widths={2}>
                    <Field name="merchant" label="Loja" component={renderSelectMerchant} />
                    <Field name="keys" label="Tipo" component={renderInputBannerKeys} />
                </Form.Group>

                <Form.Group widths='equal'>
                    <Field name="description" label="Descrição" component={renderInputWysiwyg} />
                </Form.Group>
            </FormSection>
            <FormSection fluid color="blue" title="URL" key={`allbuttons-${buttons.length}`}>
                <Form.Group widths={3}>
                    <Field name={`fields.destination.type`} label="Tipo de Link" component={destinationOptions} />
                    {fields.destination && <>
                        {fields.destination.type === 'PRODUCT' && <Field name={`fields.destination.value`} label="Produto" selectorKey='slug' component={renderSelectProduct} />}
                        {fields.destination.type === 'CATEGORY' && <Field name={`fields.destination.value`} label="Categoria" selectorKey='path' component={renderSelectCategory} />}
                        {fields.destination.type === 'URL' && <Field name={`fields.destination.value`} label="Url" component={renderInputString} />}
                    </>}
                </Form.Group>
            </FormSection>
        </React.Fragment>
    )
}
