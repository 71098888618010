import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

export default function PageLoader(props) {

    return (
        <Dimmer active inverted>
            <Loader>{props.content || 'Carregando'}</Loader>
        </Dimmer>
    )
}
