import React from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputString,
    renderInputCheckbox,
    renderInputDocument,
    renderInputEntity,
    renderMultiSelectMerchant,
} from '../../../../Utils/RenderUtils';

import {
    normalizeCellphone,
    normalizeDocument
} from '../../../../Utils/NormalizeUtils';

export default function Tab01(props) {
    return (
        <React.Fragment>
            <FormSection fluid color="green" title="Informações de Acesso">
                <Form.Group widths={3}>
                    <Field name="active" label="Ativo" component={renderInputCheckbox} type="checkbox" />
                    <Field name="username" label="Usuário" readOnly component={renderInputString} />
                    <Field name="email" label="E-mail" component={renderInputString} />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Field name="merchants" label="Lojas" component={renderMultiSelectMerchant} />
                </Form.Group>
            </FormSection>
            <FormSection fluid color="blue" title="Dados do Administrador">
                <Form.Group widths='equal'>
                    <Field name="externalId" label="Código Externo (ERP)" component={renderInputString} />
                    <Field name="name" label="Nome" readOnly component={renderInputString} />
                    <Field name="mobile" label="Celular" component={renderInputString} normalize={normalizeCellphone} format={normalizeCellphone} />
                </Form.Group>
                <Form.Group widths={3}>
                    <Field name="entity" label="Tipo Pessoa" component={renderInputEntity} />
                    <Field name="document" label="CPF/CNPJ" component={renderInputDocument} normalize={normalizeDocument} format={normalizeDocument} />
                </Form.Group>
            </FormSection>
        </React.Fragment>
    )
}
