import _ from 'lodash';

import React, { useContext } from 'react';
import GlobalContext from '../../Context/GlobalContext';

import { Checkbox, Table } from 'semantic-ui-react';

export default function DefaultTableHeader(props) {
    const { head, body } = props;

    const context = useContext(GlobalContext);

    const sortTable = property => {
        context.change({
            table: {
                orderBy: property,
                order: context.table.orderBy !== property || context.table.order === '-' ? '' : '-'
            }
        });
    }

    const checkboxIndeterminate = body.results.length > 0 && _.filter(body.results, (n) => {return _.filter(context.table.deleteList, {uuid: n.uuid}).length > 0}).length > 0;    
    const checkboxChecked = (body.results.length && _.filter(body.results, (n) => {return _.filter(context.table.deleteList, {uuid: n.uuid}).length > 0}).length === body.results.length);

    const selectAllRows = () => {
        body.results.forEach(function (row) {
            let rowFromContext = _.filter(context.table.deleteList, {uuid: row.uuid});

            if(checkboxChecked && rowFromContext.length > 0){
                if(_.filter(context.table.deleteList, {uuid: row.uuid}).length > 0){
                    _.remove(context.table.deleteList, n => {return n.uuid===row.uuid});
                }
            } else if(!checkboxChecked && rowFromContext.length === 0){
                context.table.deleteList.push(row);
            }
        })

        context.change({
            table: {
                deleteList: context.table.deleteList
            }
        });
    }

    const renderHeaderCell = (headCell) => {
        const isSorted = context.table.orderBy.split('__')[0] === headCell.field;
        const sortedDirection = context.table.order === '-' ? 'descending' : 'ascending';

        return (
            <Table.HeaderCell
            key={`header-${headCell.field}`}
            sorted={isSorted ? sortedDirection : null}
            onClick={headCell.sortable ? () => sortTable(headCell.sortQuery || headCell.field) : null}>
                {headCell.label}
            </Table.HeaderCell>
        )
    }

    return (
        <Table.Header>
            <Table.Row>
                {props.permissions.delete &&
                    <Table.HeaderCell key='check-header'>
                        <Checkbox
                        indeterminate={(checkboxIndeterminate && !checkboxChecked) || false}
                        checked={checkboxChecked || false}
                        onChange={selectAllRows}/>
                    </Table.HeaderCell>
                }

                {
                    head.map((headCell) => {
                        return renderHeaderCell(headCell);
                    })
                }

                {(props.permissions.delete || props.permissions.update || props.permissions.read) &&
                    <Table.HeaderCell key='actions-header' />
                }
            </Table.Row>
        </Table.Header>
    );
}


