import { toast } from 'react-toastify';

export const getToast = (message, type=toast.TYPE.INFO, time=3, position=toast.POSITION.TOP_RIGHT) => {
    return () => {
        toast.info(message, {
            position: position,
            autoClose: time*1000,
            closeButton: true,
            hideProgressBar: false,
            pauseOnHover: true,
            closeOnClick: true,
            type: type,
            delay: 0
        })
    }
}