import React, {  useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';

import {  Button,  Image, Card, List } from 'semantic-ui-react';

import { Read as ReadProduct } from '../../../../Actions/Product';
import { Read as ReadImage } from '../../../../Actions/Image';

export default function ItemProduct(props) {
    const products = useSelector(state => state.products);
    const images = useSelector(state => state.images);

    const dispatch = useDispatch();

    useEffect(() => {
        if(!products[props.item.product]){
            dispatch(ReadProduct(props.item.product))
        }
    }, []);

    useEffect(() => {
        if(products[props.item.product] && products[props.item.product].images[0]){
            if(!images[products[props.item.product].images[0]]){
                dispatch(ReadImage(products[props.item.product].images[0]))
            }
        }
    }, [products[props.item.product]]);

    const getProductImage = () => {
        if(products[props.item.product] && images[products[props.item.product].images[0]]){
            return <Image src={images[products[props.item.product].images[0]].urls.default} wrapped ui={false} />
        }

        return <Image src='/produto-indisponivel.png' wrapped ui={false} />        
    }

    const getProductName = () => {
        if(products[props.item.product]){
            return <Card.Header>{products[props.item.product].name}</Card.Header>
        }

        return <Card.Header>{props.item.name}</Card.Header>
    }

    const getButtonLink = () => {
        if(products[props.item.product]){
            return (
                <Link to={`/produto/${props.item.product}`}>
                    <div className='ui two buttons'>
                        <Button basic color='green'>
                            Ver Produto
                        </Button>
                    </div>
                </Link>
            )
        }

        return (
            <div className='ui two buttons'>
                <Button basic color='negative' onClick={(e) => { e.preventDefault(); }}>
                    Este produto foi removido
                </Button>
            </div>
        )
    }

    const getDiscountTotal = () => {
        return props.item.unitAbsoluteDiscount + (props.item.unitPrice*props.item.unitPercentualDiscount/100);
    }

    return (
        <Card>
            {getProductImage()}

            <Card.Content>
                {getProductName()}
                <Card.Description>
                    <List relaxed>
                        <List.Item>
                            <List.Content floated='right'>
                                {props.item.amount.toFixed(2)} unidades
                            </List.Content>

                            <List.Icon name='boxes' />

                            <List.Content>
                                Quantidade
                            </List.Content>
                        </List.Item>

                        <List.Item>
                            <List.Content floated='right'>
                                R$ {props.item.unitPrice.toFixed(2)}
                            </List.Content>

                            <List.Icon name='dollar' />

                            <List.Content>
                                Preço Unitário
                            </List.Content>
                        </List.Item>

                        <List.Item className="order-red-list-item">
                            <List.Content floated='right'>
                                - R$ {getDiscountTotal().toFixed(2)}
                            </List.Content>

                            <List.Icon name='minus' />

                            <List.Content>
                                Desconto na Unidade
                            </List.Content>
                        </List.Item>

                        <List.Item className="order-green-list-item">
                            <List.Content floated='right'>
                                R$ {((props.item.unitPrice - getDiscountTotal()) * props.item.amount).toFixed(2)}
                            </List.Content>

                            <List.Icon name='dollar' />

                            <List.Content>
                                Total
                            </List.Content>
                        </List.Item>
                    </List>
                </Card.Description>
            </Card.Content>
            <Card.Content extra>
                {getButtonLink()}
            </Card.Content>
        </Card>
    )
}
