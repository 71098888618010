import React from 'react';

import { Form, TextArea } from 'semantic-ui-react';
import { getInputError } from '../../Utils/FunctionsUtils';

export default function InputText(props) {
    const { input, label, meta } = props;

    return (
        <Form.Field {...input} label={label} control={TextArea} error={getInputError(meta)} />
    )
}
