import React from 'react';
import { Card, Header } from 'semantic-ui-react';

export default function FormSection(props) {
    const renderTitle = () => {
        if(props.title){
            return (
                <Card.Content>
                    <Card.Header>
                        <Header color={props.color}>
                            {props.title}
                        </Header>
                    </Card.Header>
                </Card.Content>
            )
        }

        return null;
    }
    return (
        <Card {...props}>
            {renderTitle()}

            <Card.Content>
                {props.children}
            </Card.Content>
        </Card>
    )
}
