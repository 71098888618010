import _ from 'lodash';

import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Field } from 'redux-form';
import { Form, Button, Icon} from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputInteger,
    renderInputDecimal,
    renderInputDateTimePicker,
    renderInputCheckboxKey
} from '../../../../Utils/RenderUtils';

import {
    normalizeInteger,
    normalizeDecimal,
    normalizePercentual
} from '../../../../Utils/NormalizeUtils';

export default function Tab02(props) {
    const prices = useSelector(state => state.form[props.form].values.prices);

    const formValues = useSelector(state => state.form[props.form].values);

    const [discountType, setDiscountType] = useState(formValues.absoluteDiscount ? 'absolute' : 'percentual');

    useEffect(() => {
        if(discountType !== 'absolute'){
            props.change('absoluteDiscount', null);
        }

        if(discountType !== 'percentual'){
            props.change('percentualDiscount', null);
        }
    }, [discountType]);

    const clearDiscount = () => {
        props.change('percentualDiscount', null);
        props.change('absoluteDiscount', null);
        props.change('discountInitDate', null);
        props.change('discountEndDate', null);
    }

    const renderPriceField = (index) => {
        return (
            <Form.Group key={`prices-${index}`}>
                <Field name={`prices[${index}].amount`} label="Quantidade" component={renderInputInteger} normalize={normalizeInteger} />
                <Field name={`prices[${index}].price`} label="Preço (R$)" component={renderInputDecimal} normalize={normalizeDecimal} />
                <Button icon color="red" onClick={(e) => {prices.splice(index, 1); props.change('prices', Object.assign([], prices)); e.preventDefault();}}>
                    <Icon name='times' />
                </Button>
            </Form.Group>
        )
    }

    return (
        <React.Fragment>
            <FormSection fluid color="green" title="Preços" key={`allprices-${prices.length}`}>
                {
                    prices.map((price, index) => {
                        return renderPriceField(index);
                    })
                }

                <Button positive onClick={(e) => {props.change('prices', _.concat(prices, [{}])); e.preventDefault();}}>
                    <Icon name='dollar' />
                    Adicionar Preço
                </Button>
            </FormSection>

            <FormSection fluid color="blue" title="Descontos">
                <Form.Group widths={4}>
                    <Button.Group>
                        <Button positive={discountType === 'percentual'} onClick={(e) => {setDiscountType('percentual'); e.preventDefault()}}>Desconto Percentual</Button>
                        <Button.Or text='ou'/>
                        <Button positive={discountType === 'absolute'} onClick={(e) => {setDiscountType('absolute'); e.preventDefault()}}>Desconto Absoluto</Button>
                    </Button.Group>
                </Form.Group>

                <Form.Group widths={4}>
                    <Field name="discountInitDate" label="Data de Início do Desconto" component={renderInputDateTimePicker} />
                    <Field name="discountEndDate" label="Data de Fim do Desconto" component={renderInputDateTimePicker} />
                </Form.Group>

                <Form.Group widths={4}>
                    <Field disabled={discountType !== 'percentual'} name="percentualDiscount" label="Desconto Percentual (%)" component={renderInputDecimal} normalize={normalizePercentual} />
                    <Field disabled={discountType !== 'absolute'} name="absoluteDiscount" label="Desconto Absoluto (R$)" component={renderInputDecimal} normalize={normalizeDecimal} />
                </Form.Group>

                { (parseFloat(formValues.percentualDiscount) || parseFloat(formValues.absoluteDiscount) || formValues.discountInitDate || formValues.discountEndDate) &&
                    <Form.Group widths={4}>
                        <Button negative onClick={(e) => {clearDiscount(); e.preventDefault();}}>
                            <Icon name='dollar' />
                            Limpar Descontos
                        </Button>
                    </Form.Group>
                }
            </FormSection>

            <FormSection fluid color="yellow" title="Estoques">
                <Form.Group widths={3}>
                    <Field name="keys" label="Venda Aberta?" keyValue="OVERSTOCK" component={renderInputCheckboxKey} type="checkbox" />
                    <Field name="stock" label="Estoque" component={renderInputInteger} normalize={normalizeInteger} />
                    <Field name="stockBusy" label="Estoque Comprometido" component={renderInputInteger} normalize={normalizeInteger} />
                    <Field name="stockHold" label="Estoque de Segurança" component={renderInputInteger} normalize={normalizeInteger} />
                </Form.Group>
            </FormSection>
        </React.Fragment>
    )
}
