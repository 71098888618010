export { List as ListTable, Create, Read, Update, Delete } from '../../../Actions/Product';

export * from './List';
export * from './Edit';
export * from './Create';
export * from './Validation';

export const entityReducer = 'products';
export const entityListReducer = 'productList';

export const verboseName = 'Produto'
export const verboseNamePlural = 'Produtos'
export const icon = 'boxes'
export const displayPropertyName = 'name';

export const editPath = '/produto'
export const listPath = '/produtos'

export const createFormName = 'productCreate';
export const editFormName = 'productUpdate';

export const permission = 'product';