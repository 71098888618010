import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector} from "react-redux";

import GlobalContext, { DEFAULT_TABLE_CONF } from '../../Context/GlobalContext';

import DefaultTable from '../../Components/Table/DefaultTable';
import PageLoader from '../../Components/Card/PageLoader';

import FormCreate from './FormCreate';
import FormDelete from './FormDelete';

export function List(props) {
    const { entityListReducer, ListTable, tableConf, renderTableComponent, listMaxSize } = props;
    const context = useContext(GlobalContext);
    const entityList = useSelector(state => state[entityListReducer]);

    const allPermissions = useSelector(state => state.auth.administrator.permissions);
    const superAdmin = allPermissions ? allPermissions.superAdmin || false : false;

    const defaultPermissions = {list: superAdmin || false, create: superAdmin || false, read: superAdmin || false, update: superAdmin || false, delete: superAdmin || false};
    const permissions = !superAdmin && allPermissions && props.permission ? allPermissions[props.permission] || defaultPermissions : defaultPermissions;

    const dispatch = useDispatch();

    useEffect(() => {
        context.change({
            table: {
                ...DEFAULT_TABLE_CONF,
                ...tableConf,
            }
        });
    }, []);

    useEffect(() => {
        dispatch(ListTable(context.table));
    }, [context.table]);

    const renderTable = () => {
        if(renderTableComponent){
            return renderTableComponent({...props, ...{superAdmin: superAdmin, permissions: permissions}}, entityList);
        }

        return <DefaultTable {...props} body={entityList} superAdmin={superAdmin} permissions={permissions} />
    }

    if(entityList && entityList.results && entityList.results.length <= (listMaxSize || 50)){
        return (
            <React.Fragment>
                <FormDelete {...props} />
                <FormCreate {...props} />

                {renderTable()}

            </React.Fragment>
        )
    } else {
        return <PageLoader />
    }
}
