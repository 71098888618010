export const tableConf = {
    searchFields: ['id', 'code', 'name']
}

export const tableHeader = [
    {
        field: 'id',
        label: 'ID',
        dataType: 'integer',
        sortable: true
    },
    {
        field: 'active',
        label: 'Ativo',
        dataType: 'boolean',
        sortable: true
    },
    {
        field: 'createdAt',
        label: 'Data de Criação',
        dataType: 'datetime',
        sortable: true
    },
    {
        field: 'code',
        label: 'SKU',
        sortable: true
    },
    {
        field: 'name',
        label: 'Nome',
        sortable: true
    },
    {
        field: 'mainPrice',
        label: 'Preço',
        dataType: 'decimal',
        sortable: false,
        sortQuery: 'prices__0__price',
        prefix: 'R$',
        toFixed: 2
    },
    {
        field: 'stock',
        label: 'Estoque',
        dataType: 'decimal',
        sortable: true,
        suffix: ' un',
        toFixed: 1
    }
];
