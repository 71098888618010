import React, { useState, useEffect } from 'react';
import { TimeInput } from 'semantic-ui-calendar-react';

import { useDispatch } from "react-redux";
import { change } from 'redux-form';

export default function InputTimePicker(props) {
    const { input, label, meta } = props;
    const [inputState, setInputState] = useState(input);

    const dispatch = useDispatch();

    const handleChange = (event, {name, value}) => {
        dispatch(change(meta.form, name, value));
    }
    
    useEffect(() => {
        if(input.value){
            if(!input.value.includes('/')){
                setInputState({...input, ...{value: input.value}});
            }
        } else {
            setInputState({...input, ...{value: input.value}})
        }
    }, [input.value]);

    return (
        <TimeInput
            {...inputState}
            label={label}
            closable
            clearable
            closeOnMouseLeave={false}
            preserveViewMode={false}
            startMode="day"
            dateFormat="HH:mm"
            onChange={handleChange}
            popupPosition="bottom right" />
    )
}
