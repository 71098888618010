import _ from 'lodash';

import Tab01 from '../Components/UpdateForm/Tab01';
import Tab02 from '../Components/UpdateForm/Tab02';
import Tab03 from '../Components/UpdateForm/Tab03';
import Tab04 from '../Components/UpdateForm/Tab04';
import {
    Update as UpdateCategory,
    UpdateProduct as UpdateCategoryProduct,
    DeleteProduct as DeleteCategoryProduct
} from '../../../Actions/Category';

export const preFilter = null;

export const preDispatch = (formValues, initialValues, dispatch) => {
    if(formValues.categories){
        const removedCategories = _.remove(initialValues.categories, function(n) {
            return !formValues.categories.includes(n);
        });

        removedCategories.forEach(uuid => {
            dispatch(UpdateCategory(uuid, {mainCategory: null}));
        });

        formValues.categories.forEach((uuid, index) => {
            dispatch(UpdateCategory(uuid, {sort: index}));
        });
    }

    if(formValues.products){
        
        const productsDelete = _.remove(initialValues.products, function(n) {
            return !Object.assign([], _.mapValues(formValues.products, 'uuid')).includes(n.uuid);
        });

        dispatch(DeleteCategoryProduct(initialValues.uuid, productsDelete));

        let productsUpdate = [];

        formValues.products.forEach((product, index) => {
            product.sort = index;
            productsUpdate.push(product);
        });

        dispatch(UpdateCategoryProduct(initialValues.uuid, productsUpdate));
    }

    return formValues;
}

export const mountEditPanes = (props) => {
    return [
        {
            menuItem: { key: 'general', icon: 'info', content: 'Informações Gerais' },
            render: () => <Tab01 {...props} />
        },
        {
            menuItem: { key: 'images', icon: 'images', content: 'Fotos' },
            render: () => <Tab02 {...props} />
        },
        {
            menuItem: { key: 'products', icon: 'boxes', content: 'Produtos' },
            render: () => <Tab03 {...props} />
        },
        {
            menuItem: { key: 'subcategories', icon: 'sort alphabet up', content: 'Sub-categorias' },
            render: () => <Tab04 {...props} />
        }
    ]
}