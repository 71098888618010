import history from '../../history';

import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector} from "react-redux";
import { Message, Icon, Segment, Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import GlobalContext from '../../Context/GlobalContext';

import PageLoader from '../../Components/Card/PageLoader';

import { getToast } from '../../Utils/ToastUtils';
import ToastMessage from '../../Components/Modal/ToastMessage';

import FormUpdate from './FormUpdate';
import FormDelete from './FormDelete';

export function Edit(props) {
    const { Read, Delete, entityReducer, icon, displayPropertyName, listPath, verboseName } = props;
    const context = useContext(GlobalContext);
    const entity = useSelector(state => state[entityReducer]);

    const allPermissions = useSelector(state => state.auth.administrator.permissions);
    const superAdmin = allPermissions ? allPermissions.superAdmin || false : false;

    const defaultPermissions = {list: superAdmin || false, create: superAdmin || false, read: superAdmin || false, update: superAdmin || false, delete: superAdmin || false};
    const permissions = !superAdmin && allPermissions && props.permission ? allPermissions[props.permission] || defaultPermissions : defaultPermissions;

    const dispatch = useDispatch();

    const uuid = props.match.params.uuid;

    const clickDelete = entityDelete => {
        context.change({deleteModalOpen: true, table: {deleteList: [entityDelete]}});
    }

    const onDelete = () => {
        context.change({deleteModalOpen: false, table: {deleteList: []}});

        dispatch(Delete(uuid)).then(result => {
            if(result.statusCode === 204){
                getToast(<ToastMessage header='Sucesso!' content={`${verboseName} removido`}/>, toast.TYPE.INFO)();
                history.push(listPath);
            }
        });
    }

    useEffect(() => {
        dispatch(Read(uuid));
    }, []);

    const getActionButtons = () => {
        let actionButtons = [];

        if(permissions.update){
            actionButtons.push(<Button color='green' content='Salvar' icon='plus' labelPosition='left' onClick={() => {document.getElementById('hiddenSubmit').click()}}/>)
        }

        actionButtons.push(
            <Link to={listPath} className='item'>
                <Button color='grey' content='Voltar' icon='arrow left' labelPosition='left' />
            </Link>
        )

        if(permissions.delete){
            actionButtons.push(<Button color='red' content='Remover' icon='trash' labelPosition='left' onClick={() => {clickDelete(entity[uuid])}}/>);
        }
        
        return actionButtons;
    }

    if(entity[uuid]){
        return(
            <React.Fragment>
                
                <FormDelete {...props} onDelete={onDelete}/>

                <Segment>
                    <Message icon attached >
                        <Icon name={icon} />

                        <Message.Content>
                            <h3>{entity[uuid][displayPropertyName]}</h3>
                        </Message.Content>

                        <span style={{float: 'right'}}>
                            {getActionButtons()}
                        </span>
                    </Message>
                </Segment>

                <FormUpdate
                    {...props}
                    
                    uuid={uuid}
                    entity={entity[uuid]}
                    initialValues={entity[uuid]}

                    superAdmin={superAdmin}
                    permissions={permissions} />
            </React.Fragment>
        )
    } else {
        return <PageLoader />
    }
}
