export { List as ListTable, Create, Read, Update, Delete } from '../../../Actions/Administrator';

export * from './List';
export * from './Edit';
export * from './Create';
export * from './Validation';

export const entityReducer = 'administrators';
export const entityListReducer = 'administratorList';

export const verboseName = 'Administrador'
export const verboseNamePlural = 'Administradores'
export const icon = 'user secret'
export const displayPropertyName = 'name';

export const editPath = '/administrador'
export const listPath = '/administradores'

export const createFormName = 'administratorCreate';
export const editFormName = 'administratorUpdate';

export const permission = 'administrator';
