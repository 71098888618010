import React, {  useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Card } from 'semantic-ui-react';

import { Read as ReadProduct } from '../../../../Actions/Product';

import FormSection from '../../../../Components/Card/FormSection';

import ItemProduct from './ItemProduct';

export default function Tab02(props) {
    const products = useSelector(state => state.products);

    const dispatch = useDispatch();

    useEffect(() => {
        props.initialValues.items.forEach(item => {
            if(!products[item.product]){
                dispatch(ReadProduct(item.product))
            }
        })
    }, []);

    return (
        <FormSection fluid color="green" title="Produtos">
            <Card.Group>
                {props.initialValues.items.map(item => {
                    return <ItemProduct {...props} item={item} />
                })}
            </Card.Group>
        </FormSection>
    )
}
