import React, { useState, useEffect } from 'react';
import { DateTimeInput } from 'semantic-ui-calendar-react';

import { useDispatch } from "react-redux";
import { change } from 'redux-form';
import { datetimePtBrToIso, datetimeIsoToPtBr } from '../../Utils/FunctionsUtils';

export default function InputDateTimePicker(props) {
    const { input, label, meta } = props;
    const [inputState, setInputState] = useState(input);

    const dispatch = useDispatch();

    const handleChange = (event, {name, value}) => {
        if(value){
            dispatch(change(meta.form, name, datetimePtBrToIso(value)));
        } else {
            dispatch(change(meta.form, name, value));
        }
    }
    
    useEffect(() => {
        if(input.value){
            if(!input.value.includes('/')){
                setInputState({...input, ...{value: datetimeIsoToPtBr(input.value)}});
            }
        } else {
            setInputState({...input, ...{value: input.value}})
        }
    }, [input.value]);

    return (
        <DateTimeInput
            {...inputState}
            label={label}
            closable
            clearable
            closeOnMouseLeave={false}
            preserveViewMode={false}
            startMode="day"
            dateTimeFormat="DD/MM/YYYY HH:mm"
            onChange={handleChange}
            popupPosition="bottom right" />
    )
}
