import _ from 'lodash';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch} from "react-redux";
import { Icon, Button, Card } from 'semantic-ui-react';

import { Read } from '../../../../Actions/Order';

import FormSection from '../../../../Components/Card/FormSection';

import {
    dateIsoToPtBr
} from '../../../../Utils/FunctionsUtils';

const keyMap = {
    CART: '(Carrinho)',
    WAITING_PAYMENT: '(Aguardando Pagamento)',
    NEW: '(Novo)',
    PAID: '(Novo)',
    INVOICED: '(Faturado)',
    PROCESSED: '(Processado)',
    SHIPPED: '(Enviado)',
    DONE: '(Entregue)',
    FAILED: '(Falha)',
    CANCELLED: '(Cancelado)',
    REFUNDED: '(Reembolsado)'
}

export default function Tab03(props) {
    const formValues = useSelector(state => state.form[props.form].values);

    const entities = useSelector(state => state.orders);

    const dispatch = useDispatch();

    const renderItem = (item) => {
        return (
            <Card>
                <Card.Content>
                    <Card.Header><Icon name='shopping cart'/>{item.code} {keyMap[item.status]}</Card.Header>
                    <Card.Meta>
                        <p>Data: <b>{dateIsoToPtBr(item.createdAt)}</b></p>
                        <p>Produtos: <b>{item.items.length}</b></p>
                        <p>Total: <b>R$ {item.total}</b></p>
                    </Card.Meta>
                </Card.Content>
                <Card.Content extra>
                    <Link to={`/pedido/${item.uuid}`}>
                        <div className='ui two buttons'>
                            <Button basic color='green'>
                                Visualizar
                            </Button>
                        </div>
                    </Link>
                </Card.Content>
            </Card>
        )
    }

    const renderList = () => {
        let itemList = []

        formValues.orders.forEach(uuid => {
            if(entities[uuid]){
                itemList.push(renderItem(entities[uuid]));
            }
        })

        return itemList;
    }
    
    useEffect(() => {
        formValues.orders.forEach(uuid => {
            if(!entities[uuid]){
                dispatch(Read(uuid));
            }
        })
    }, [formValues.orders]);

    return (
        <FormSection fluid color="green" title="Pedidos">
            <Card.Group>
                {renderList()}
            </Card.Group>
        </FormSection>
    )
}
