import _ from 'lodash';

import {
    LIST, CREATE, READ, UPDATE, DELETE, USE
} from '../Actions/Banner';

const EntityReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE:
            return { ...state, [action.payload.uuid]: action.payload };
        case READ:
            return { ...state, [action.payload.uuid]: action.payload };
        case UPDATE:
            return { ...state, [action.payload.uuid]: action.payload };
        case DELETE:
            return _.omit(state, action.payload.uuid);
        default:
            return state;
    }
}

const ListReducer = (state = {}, action) => {
    switch(action.type){
        case LIST:
            return action.payload;
        case DELETE:
            return { ...state, ...{results: _.remove(state.results, (n) => {return n.uuid !== action.payload.uuid}) }};
        default:
            return state;
    }
}

const NewReducer = (state = {}, action) => {
    switch(action.type){
        case CREATE:
            return { ...state, [action.payload.uuid]: action.payload };
        case USE:
            return _.omit(state, action.payload.uuid);
        default:
            return state;
    }
}

export {
    EntityReducer,
    ListReducer,
    NewReducer
}