import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { Read as ReadMerchant } from '../../Actions/Merchant';

export default function Merchant(props) {
    const merchants = useSelector(state => state.merchants);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!merchants[props.uuid]){
            dispatch(ReadMerchant(props.uuid));
        }
    }, []);

    if(merchants[props.uuid]){
        return merchants[props.uuid][props.field]
    }

    return null;
}
