import Tab01 from '../Components/UpdateForm/Tab01';

export const preFilter = null;
export const preDispatch = null;

export const mountEditPanes = (props) => {
    return [
        {
            menuItem: { key: 'general', icon: 'info', content: 'Informações Gerais' },
            render: () => <Tab01 {...props} />
        }
    ]
}