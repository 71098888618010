import _ from 'lodash';

import React, { useEffect } from 'react';
import { useSelector, useDispatch} from "react-redux";
import { Icon, Button, Card } from 'semantic-ui-react';

import { Read } from '../../../../Actions/CreditCard';

import FormSection from '../../../../Components/Card/FormSection';

const keyMap = {
    DEFAULT: ' (Padrão)',
    MAIN: ' (Principal)',
}

export default function Tab03(props) {
    const formValues = useSelector(state => state.form[props.form].values);

    const entities = useSelector(state => state.creditCards);

    const dispatch = useDispatch();

    const deleteItem = (uuid) => {
        let newList = _.cloneDeep(formValues.creditCards);

        _.remove(newList, el => {
            return el === uuid
        })

        props.change('creditCards', newList);
    }

    const renderItem = (item) => {
        return (
            <Card>
                <Card.Content>
                    <Card.Header><Icon name='credit card'/>{item.brand} {keyMap[item.keys]}</Card.Header>
                    <Card.Meta>
                        <p>{item.cardBin}</p>
                        <p>{item.expirationMonth} / {item.expirationYear}</p>
                    </Card.Meta>
                </Card.Content>
                <Card.Content extra>
                    <div className='ui two buttons'>
                        <Button basic color='red' onClick={(e) => {e.preventDefault(); deleteItem(item.uuid)}} >
                            Remover
                        </Button>
                    </div>
                </Card.Content>
            </Card>
        )
    }

    const renderList = () => {
        let itemList = []

        formValues.creditCards.forEach(uuid => {
            if(entities[uuid]){
                itemList.push(renderItem(entities[uuid]));
            }
        })

        return itemList;
    }
    
    useEffect(() => {
        formValues.creditCards.forEach(uuid => {
            if(!entities[uuid]){
                dispatch(Read(uuid));
            }
        })
    }, [formValues.creditCards]);

    return (
        <FormSection fluid color="green" title="Cartões de Crédito">
            <Card.Group>
                {renderList()}
            </Card.Group>
        </FormSection>
    )
}
