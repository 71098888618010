import IMask from 'imask';
import slugify from 'react-slugify';

export const normalizeUrl = value => {
    
    if(value){
        const mask = IMask.createPipe({mask: 'https://*********************************************************************************'});
        return mask(value);
    }

    return value;
}

export const normalizeInteger = value => {
    if(value && value.toString().match(/\d/g)){
        value = value.toString().match(/\d/g).join('');
    }

    return value;
}

export const normalizeSlug = value => {
    if(value){
        value = slugify(value);
    }

    return value;
}

export const normalizeDocument = (value, previousValue, allValues) => {
    if(value){
        let mask = IMask.createPipe({mask: '00.000.000/0000-00'});
    
        if(value.replace('.', '').replace('-', '').replace('/', '').length <= 12){
            mask = IMask.createPipe({mask: '000.000.000-000'});
        }
    
        return mask(value);
    }
    return null;
}

export const normalizeCellphone = (value) => {
    if(value){
        value = IMask.createPipe({mask: '+{55} (00) 00000-0000'})(value)
    }

    return value;
}

export const normalizeZipcode = (value) => {
    if(value){
        value = IMask.createPipe({mask: '00000-000'})(normalizeInteger(value))
    }

    return value;
}

export const normalizeDecimal = value => {
    if(value){
        value = parseInt(normalizeInteger(value), 10).toString();

        if(value.length > 2){
            const position = value.length - 2;
            value = [value.slice(0, position), '.', value.slice(position)].join('');
        } else if(value.length === 1){
            value = '0.0'+value;
        } else if(value.length === 2){
            value = '0.'+value;
        }
    }

    return value;
}

export const normalizePercentual = value => {
    if(value){
        value = normalizeDecimal(value);

        if(parseInt(value) > 100){
            value = '100.00';
        }
    }

    return value;
}
