import Tab01 from '../Components/UpdateForm/Tab01';
import Tab02 from '../Components/UpdateForm/Tab02';
import Tab03 from '../Components/UpdateForm/Tab03';

export const preFilter = null;

export const preDispatch = null;

export const mountEditPanes = (props) => {
    return [
        {
            menuItem: { key: 'general', icon: 'info', content: 'Informações Gerais' },
            render: () => <Tab01 {...props} />
        },
        {
            menuItem: { key: 'images', icon: 'images', content: 'Foto' },
            render: () => <Tab02 {...props} />
        },
        {
            menuItem: { key: 'password', icon: 'key', content: 'Trocar Senha' },
            render: () => <Tab03 {...props} />
        }
    ]
}