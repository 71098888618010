import _ from 'lodash';

import React, { useEffect } from 'react';
import { useSelector} from "react-redux";
import { Field } from 'redux-form';
import { Icon, Button, Card } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputInteger
} from '../../../../Utils/RenderUtils';

import {
    normalizeInteger
} from '../../../../Utils/NormalizeUtils';

export default function Tab05(props) {
    const formValues = useSelector(state => state.form[props.form].values);

    const deleteCode = (code) => {
        let newCodes = _.cloneDeep(formValues.codes);
        _.remove(newCodes, cd => {
            return cd.code === code.code
        })

        props.change('codes', newCodes);
    }

    const invalidateCode = (code) => {
        let newCodes = _.cloneDeep(formValues.codes);
        newCodes[_.findIndex(newCodes, cd => {
            return cd.code === code.code;
        })].used = code.amount;

        props.change('codes', newCodes);
    }

    const renderVoucherItem = (item) => {
        let colorStyle = {
            color: 'green'
        }

        if(item.used === item.amount){
            colorStyle.color = 'red'
        }


        let extraContent = (
            <div className='ui two buttons'>
                {(() => {
                    if(item.amount > item.used){
                        return (
                            <Button basic color='blue' onClick={(e) => {e.preventDefault(); invalidateCode(item)}} >
                                Invalidar
                            </Button>
                        )
                    }
                })()}

                {(() => {
                    if(item.used === 0){
                        return (
                            <Button basic color='red' onClick={(e) => {e.preventDefault(); deleteCode(item)}} >
                                Remover
                            </Button>
                        )
                    }
                })()}
            </div>
        )
        
        if(props.initialValues.keys.includes('SINGLE')){
            extraContent = null;
        }

        if(item.new){
            extraContent = <span>O código será gerado após a alteração ser confirmada</span>;
        }

        return (
            <Card inverted color={colorStyle.color}>
                <Card.Content>
                    <Card.Header color={colorStyle.color}><Icon name='ticket'/>{item.code}</Card.Header>
                    <Card.Meta>Quantidade: {item.used}/{props.initialValues.keys.includes('SINGLE') ? formValues.amount : item.amount}</Card.Meta>
                </Card.Content>
                <Card.Content extra>
                    {extraContent}
                </Card.Content>
            </Card>
        )
    }

    const renderVoucherList = () => {
        let voucherList = []

        formValues.codes.forEach(item => {
            voucherList.push(renderVoucherItem(item));
        })

        return voucherList;
    }
    
    useEffect(() => {
        let newCodes = _.cloneDeep(formValues.codes);
        let value = parseInt(formValues.amount) >= props.initialValues.used ? parseInt(formValues.amount) : props.initialValues.used;

        _.remove(newCodes, code => {
            return code.new === true;
        })

        newCodes = newCodes.slice()
        if(props.initialValues.keys.includes('BATCH')){
            for(let i=0; i < value - props.initialValues.amount; i++){
                newCodes.unshift({code: formValues.code+'-----', used: 0, amount: 1, new: true});
            }
        } else if(props.initialValues.keys.includes('SINGLE')){
            newCodes[0].amount = value;
        }

        props.change('codes', newCodes);
    }, [formValues.amount]);

    return (
        <FormSection fluid color="green" title="Quantidades e Códigos">
            <Field name="amount" label="Quantidade de Cupons" component={renderInputInteger} normalize={normalizeInteger} width={4}/>

            <Card.Group>
                {renderVoucherList()}
            </Card.Group>
        </FormSection>
    )
}
