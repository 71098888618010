import { combineReducers } from 'redux';

import { EntityReducer as ProductReducer, ListReducer as ProductListReducer, NewReducer as ProductNewReducer } from './ProductReducer';
import { EntityReducer as MerchantReducer, ListReducer as MerchantListReducer, NewReducer as MerchantNewReducer } from './MerchantReducer';
import { EntityReducer as ManufacturerReducer, ListReducer as ManufacturerListReducer, NewReducer as ManufacturerNewReducer } from './ManufacturerReducer';
import { EntityReducer as AdministratorReducer, ListReducer as AdministratorListReducer, NewReducer as AdministratorNewReducer } from './AdministratorReducer';
import { EntityReducer as ImageReducer, ListReducer as ImageListReducer, NewReducer as ImageNewReducer } from './ImageReducer';
import { EntityReducer as CategoryReducer, ListReducer as CategoryListReducer, NewReducer as CategoryNewReducer } from './CategoryReducer';
import { EntityReducer as PropertyGroupReducer, ListReducer as PropertyGroupListReducer, NewReducer as PropertyGroupNewReducer } from './PropertyGroupReducer';
import { EntityReducer as VoucherReducer, ListReducer as VoucherListReducer, NewReducer as VoucherNewReducer } from './VoucherReducer';
import { EntityReducer as BannerReducer, ListReducer as BannerListReducer, NewReducer as BannerNewReducer } from './BannerReducer';
import { EntityReducer as CustomerReducer, ListReducer as CustomerListReducer, NewReducer as CustomerNewReducer } from './CustomerReducer';
import { EntityReducer as AddressReducer, ListReducer as AddressListReducer, NewReducer as AddressNewReducer } from './AddressReducer';
import { EntityReducer as CreditCardReducer, ListReducer as CreditCardListReducer, NewReducer as CreditCardNewReducer } from './CreditCardReducer';
import { EntityReducer as OrderReducer, ListReducer as OrderListReducer, NewReducer as OrderNewReducer } from './OrderReducer';
import { EntityReducer as ShippingReducer, ListReducer as ShippingListReducer, NewReducer as ShippingNewReducer } from './ShippingReducer';
import { EntityReducer as InstitutionalReducer, ListReducer as InstitutionalListReducer, NewReducer as InstitutionalNewReducer } from './InstitutionalReducer';
import { EntityReducer as NewsletterReducer, ListReducer as NewsletterListReducer, NewReducer as NewsletterNewReducer } from './NewsletterReducer';

import AuthReducer from './AuthReducer';

import { reducer as formReducer } from 'redux-form';

export default combineReducers({
    products: ProductReducer,
    productList: ProductListReducer,
    productsNew: ProductNewReducer,

    merchants: MerchantReducer,
    merchantList: MerchantListReducer,
    merchantsNew: MerchantNewReducer,

    manufacturers: ManufacturerReducer,
    manufacturerList: ManufacturerListReducer,
    manufacturersNew: ManufacturerNewReducer,

    administrators: AdministratorReducer,
    administratorList: AdministratorListReducer,
    administratorsNew: AdministratorNewReducer,

    images: ImageReducer,
    imageList: ImageListReducer,
    imagesNew: ImageNewReducer,

    categories: CategoryReducer,
    categoryList: CategoryListReducer,
    categoriesNew: CategoryNewReducer,

    propertyGroups: PropertyGroupReducer,
    propertyGroupList: PropertyGroupListReducer,
    propertyGroupsNew: PropertyGroupNewReducer,

    vouchers: VoucherReducer,
    voucherList: VoucherListReducer,
    vouchersNew: VoucherNewReducer,

    banners: BannerReducer,
    bannerList: BannerListReducer,
    bannersNew: BannerNewReducer,

    customers: CustomerReducer,
    customerList: CustomerListReducer,
    customerNew: CustomerNewReducer,

    addresses: AddressReducer,
    addressList: AddressListReducer,
    addressNew: AddressNewReducer,

    creditCards: CreditCardReducer,
    creditCardList: CreditCardListReducer,
    creditCardNew: CreditCardNewReducer,

    orders: OrderReducer,
    orderList: OrderListReducer,
    orderNew: OrderNewReducer,

    shippings: ShippingReducer,
    shippingList: ShippingListReducer,
    shippingNew: ShippingNewReducer,

    institutionals: InstitutionalReducer,
    institutionalList: InstitutionalListReducer,
    institutionalNew: InstitutionalNewReducer,

    newsletters: NewsletterReducer,
    newsletterList: NewsletterListReducer,
    newsletterNew: NewsletterNewReducer,

    auth: AuthReducer,
    form: formReducer,
});
