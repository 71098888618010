export const ValidateCreate = (formValues) => {
    const errors = {};

    if(!formValues.title){
        errors.code = 'Esse campo é obrigatório'
    }

    if(!formValues.groupName){
        errors.name = 'Esse campo é obrigatório'
    }

    if(!formValues.slug){
        errors.slug = 'Esse campo é obrigatório'
    }
    return errors;
}

export const ValidateUpdate = (formValues) => {
    return ValidateCreate(formValues);
}
