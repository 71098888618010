import React, {useContext } from 'react';
import GlobalContext from '../../Context/GlobalContext';

import { Button, Header, Icon, Modal } from 'semantic-ui-react';

export default function CreateFormModal(props) {
    const { icon, title, size, saveHandler, cancelHandler, createForm } = props;
    const context = useContext(GlobalContext);

    return (
        <Modal
            style={{height: 'auto'}}
            open={context.createModalOpen}
            size={size || 'large'}>

            <Header icon>
                <Icon name={icon} />
                {title}
            </Header>

            <Modal.Content>
                {createForm}
            </Modal.Content>

            <Modal.Actions>
                <Button inverted color='green' onClick={() => { saveHandler(); }}>
                    <Icon name='plus' /> Salvar
                </Button>
                <Button inverted color='red' onClick={() => { cancelHandler(); }}>
                    Cancelar
                </Button>
            </Modal.Actions>
        </Modal>
    );
}