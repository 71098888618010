import Tab01 from '../Components/UpdateForm/Tab01';
import Tab02 from '../Components/UpdateForm/Tab02';
import Tab03 from '../Components/UpdateForm/Tab03';

export const preFilter = null;
export const preDispatch = null;

export const mountEditPanes = (props) => {
    let currentPanes = [
        {
            menuItem: { key: 'general', icon: 'info', content: 'Informações Gerais' },
            render: () => <Tab01 {...props} />
        }
    ]

    if(props.formValues && props.formValues.keys.includes('ZipRange')){
        currentPanes.push({
            menuItem: { key: 'values', icon: 'list', content: 'Faixas de Cep' },
            render: () => <Tab02 {...props} />
        })
    }

    if(props.formValues && props.formValues.keys.includes('RadiusRange')){
        currentPanes.push({
            menuItem: { key: 'values', icon: 'list', content: 'Faixas de Raio' },
            render: () => <Tab03 {...props} />
        })
    }

    return currentPanes;
}