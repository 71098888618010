import _ from 'lodash';

import React from 'react';

import { Form, Input } from 'semantic-ui-react';
import { getInputError } from '../../Utils/FunctionsUtils';

export default function InputString(props) {
    const { input, label, meta, disabled, stringType } = props;

    const inputProperties = _.omit(props, ['stringType'])

    if(typeof input.value === 'number'){
        if(stringType === 'decimal'){
            input.value = input.value.toFixed(2);
        }

        if(stringType === 'integer'){
            input.value = parseInt(input.value);
        }
    }

    if(stringType === 'password'){
        input.type = 'password';
    }

    return (
        <Form.Field {...{...inputProperties, ...input}} disabled={disabled} label={label} control={Input} error={getInputError(meta)} />
    )
}
