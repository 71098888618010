import React from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputString,
    renderInputEntity,
    renderInputDocument,
    renderInputDatePicker
} from '../../../../Utils/RenderUtils';

import {
    normalizeCellphone,
    normalizeDocument
} from '../../../../Utils/NormalizeUtils';

export default function Tab03(props) {
    return (
        <React.Fragment>
            <FormSection fluid color="green" title="Dados da Compania">
                <Form.Group widths='equal'>
                    <Field name="companyName" label="Razão Social" component={renderInputString} />
                    <Field name="mobile" label="Celular" component={renderInputString} normalize={normalizeCellphone} />
                    <Field name="domain" label="Domínio Web" component={renderInputString} />
                </Form.Group>

                <Form.Group widths='equal'>
                    <Field name="entity" label="Tipo Pessoa" style={{marginTop: '21px'}} component={renderInputEntity} />
                    <Field name="document" label="CPF/CNPJ" component={renderInputDocument} normalize={normalizeDocument} />
                    <Field name="foundationDate" label="Data de Fundação" component={renderInputDatePicker} />
                </Form.Group>
            </FormSection>
        </React.Fragment>
    )
}
