import React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { SortableHandle } from 'react-sortable-hoc';

import { Card, Button, List } from 'semantic-ui-react';

import { Read } from '../../Actions/Product';

const DragHandle = SortableHandle(() => <List.Icon link name='bars' size='large' verticalAlign='middle' />);

export default function ProductCategoryListItem(props) {
    const entities = useSelector(state => state.products);
    const dispatch = useDispatch();

    useEffect(() => {
        if(!entities[props.uuid]){
            dispatch(Read(props.uuid, {fields: ['name', 'code', 'uuid'], }));
        }
    }, []);

    if(entities[props.uuid]){
        return (
            <Card>
                <Card.Content>
                    <Card.Header><DragHandle /> {entities[props.uuid].name}</Card.Header>
                    <Card.Meta>{entities[props.uuid].code}</Card.Meta>
                    <Card.Description>
                        {entities[props.uuid].uuid}
                    </Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <div className='ui two buttons'>
                        <Button basic color='red' onClick={(e) => {e.preventDefault(); props.removeProduct(props.uuid)} }>
                            Remover
                        </Button>
                    </div>
                </Card.Content>
            </Card>
        )
    }
    
    return null;
}
