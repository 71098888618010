import React, { useState, useCallback } from 'react';

import { useDispatch } from "react-redux";
import slugify from 'react-slugify';

import { Button, Icon, Modal, Form, Input, Label} from 'semantic-ui-react';
import { Create as CreateImage } from '../../Actions/Image';
import ReactCrop from "react-image-crop";

import 'react-image-crop/dist/ReactCrop.css';

export default function ImageCropModal(props) {
    const imageWidth = props.imageWidth || 1000;
    const imageHeight = props.imageHeight || 1000;

    const [crop, setCrop] = useState({ width: 90, unit: "%", aspect: imageWidth/imageHeight, x: 5, y: 5 });
    const [croppedImage, setCroppedImage] = useState(null);
    const [imageProps, setImageProps] = useState(props.image.props);

    const dispatch = useDispatch();

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedImg(props.image.base64, croppedAreaPixels);
    }, [])

    const createImage = (base64src) => new Promise((resolve, reject) => {
        const image = new Image();
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', error => reject(error));
        image.src = base64src;
    })

    const setCroppedImg = async (imageSrc, croppedArea) => {
        const image = await createImage(imageSrc);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        canvas.width = props.imageWidth;
        canvas.height = props.imageHeight;

        ctx.drawImage(
            image,
            image.width*croppedArea.x/100,
            image.height*croppedArea.y/100,
            image.width*croppedArea.width/100,
            image.height*croppedArea.height/100,
            0,
            0,
            canvas.width,
            canvas.height
        )

        setCroppedImage(canvas.toDataURL(props.image.file.type));
    }

    const onLoad = useCallback((img) => {}, []);

    const renderCropper = () => {
        return (
            <div style={{margin: '0 auto', width: 'max-content'}}>
                <ReactCrop
                    src={props.image.base64}
                    crop={crop}
                    ruleOfThirds
                    onImageLoaded={onLoad}
                    imageStyle={{maxHeight: '500px'}}
                    onChange={(c) => setCrop(c)}
                    onComplete={(c, pc) => onCropComplete(c, pc)}
                />
                <Form style={{marginTop: '15px'}}>
                    <Form.Group>
                        <Input labelPosition='right' type='text' placeholder='Amount'>
                            <Label basic>Nome:</Label>
                            <input
                                name='filename'
                                type='text'
                                value={imageProps.filename}
                                onChange={(e) => {setImageProps({...imageProps, ...{filename: slugify(e.target.value)}})}}/>
                            <Label>.{imageProps.extension}</Label>
                        </Input>
                    </Form.Group>
                </Form>
            </div>
        )
    }

    const saveImage = () => {
        dispatch(CreateImage(
            {...{filebase: croppedImage}, ...imageProps}
        ));
    }

    return (
        <Modal
        open={true}
        size='large'>

            <Modal.Content>
                {renderCropper()}
            </Modal.Content>

            <Modal.Actions>
                <Button inverted color='green' onClick={() => { saveImage(); props.cancel(); }}>
                    <Icon name='plus' /> Salvar
                </Button>
                <Button inverted color='red' onClick={() => { props.cancel() }}>
                    <Icon name='plus' /> Cancelar
                </Button>

            </Modal.Actions>

        </Modal>
    );
}