import _ from 'lodash';

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { Grid, Button } from 'semantic-ui-react';

import { Read as ReadImage, Use as UseImage } from '../../../../Actions/Image';
import FormSection from '../../../../Components/Card/FormSection';
import ImageCard from '../../../../Components/Card/ImageCard';

export default function Tab02(props) {
    const formValues = useSelector(state => state.form[props.form].values);
    const images = useSelector(state => state.images);
    const newImage = useSelector(state => state.imagesNew);

    const dispatch = useDispatch();

    useEffect(() => {
        formValues.images.forEach(imageUuid => {
            if(!images[imageUuid]){
                dispatch(ReadImage(imageUuid));
            }
        })
    }, [formValues.images]);

    useEffect(() => {
        if(newImage){
            let newImages = Object.assign([], formValues.images)

            _.keys(newImage).forEach((key) => {
                newImages.push(key);
                dispatch(UseImage(key));
            });
            
            props.change('images', newImages);
        }
    }, [newImage]);

    const removeImage = imageUuid => {
        props.change('images', _.remove(formValues.images, function(n) { return n !== imageUuid }));
    }

    const getImageCard = (tag, imageWidth, imageHeight) => {
        const imageProps = {
            tags: [tag]
        }

        let imageSelectedUuid = null;

        formValues.images.forEach(imageUuid => {
            if(images[imageUuid] && images[imageUuid].tags.includes(tag)){
                imageSelectedUuid = imageUuid;
            }
        })

        const buttons = (
            <div className='ui two buttons'>
                <Button basic color='red' onClick={(()=>{removeImage(imageSelectedUuid)})}>
                    Remover/Alterar Imagem
                </Button>
            </div>
        )

        return <ImageCard
                uuid={imageSelectedUuid}
                extra={buttons}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                imageProps={imageProps}/>
    }

    return (
        <Grid columns={'equal'} style={{margin: '10px 0px'}}>
            <Grid.Column width={8}>
                <FormSection fluid color="green" title="Desktop (1920x500)">
                    {getImageCard('BANNER_DESKTOP', 1920, 500)}
                </FormSection>
            </Grid.Column>
            <Grid.Column width={8}>
                <FormSection fluid color="blue" title="Mobile (600x300)">
                    {getImageCard('BANNER_MOBILE', 600, 300)}
                </FormSection>
            </Grid.Column>
        </Grid>
    )
}
