import _ from 'lodash';

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { List } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';
import ImageListItem from '../../../../Components/Entity/ImageListItem';
import ImageDropzone from '../../../../Components/Input/ImageDropzone';

import { Use as UseImage } from '../../../../Actions/Image';

export default function Tab04(props) {
    const formValues = useSelector(state => state.form[props.form].values);
    const newImage = useSelector(state => state.imagesNew);
    const dispatch = useDispatch();

    useEffect(() => {
        if(newImage){
            let newImages = Object.assign([], formValues.images)

            _.keys(newImage).forEach((key) => {
                newImages.push(key);
                dispatch(UseImage(key));
            });
            
            props.change('images', newImages);
        }
    }, [newImage]);

    const removeImage = imageUuid => {
        props.change('images', _.remove(formValues.images, function(n) { return n !== imageUuid }));
    }

    const changeImagePosition = (imageUuid, shift) => {
        let newImages = Object.assign([], formValues.images);

        let index = newImages.indexOf(imageUuid);
        shift = index + shift;

        if(shift >= 0 && shift < formValues.images.length){
            let shiftUuid = newImages[shift];
            newImages[shift] = imageUuid;
            newImages[index] = shiftUuid;
    
            props.change('images', newImages);
        }
    }

    return (
        <React.Fragment>
            <FormSection fluid color="green">
                <ImageDropzone imageWidth={1000} imageHeight={1000} />

                <List ordered>
                    {
                        formValues.images.map((imageUuid) => {
                            return <ImageListItem
                                        key={`image-${imageUuid}`}
                                        uuid={imageUuid}
                                        shiftUpAction={() => {changeImagePosition(imageUuid, -1)}}
                                        shiftDownAction={() => {changeImagePosition(imageUuid, 1)}}
                                        removeAction={() => {removeImage(imageUuid)}} />
                        })
                    }
                </List>
            </FormSection>
        </React.Fragment>
    )
}
