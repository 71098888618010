export const tableConf = {
    searchFields: ['id', 'name']
}

export const tableHeader = [
    {
        field: 'id',
        label: 'ID',
        dataType: 'integer',
        sortable: true
    },
    {
        field: 'active',
        label: 'Ativo',
        dataType: 'boolean',
        sortable: true
    },
    {
        field: 'createdAt',
        label: 'Data de Criação',
        dataType: 'datetime',
        sortable: true
    },
    {
        field: 'name',
        label: 'Nome',
        dataType: 'string',
        sortable: true
    },
    {
        field: 'keys',
        label: 'Filtro',
        dataType: 'array',
        sortable: true,
        map: {
            FILTER: 'Sim'
        }
    },
];