import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { List } from 'semantic-ui-react';

import { Read as ReadAddress } from '../../../../Actions/Address';

import { normalizeZipcode } from '../../../../Utils/NormalizeUtils';
import { datetimeIsoToPtBr } from '../../../../Utils/FunctionsUtils';

export default function BillingInfo(props) {
    const addresses = useSelector(state => state.addresses);

    const dispatch = useDispatch();

    useEffect(() => {
        if(props.initialValues.billingAddress && !addresses[props.initialValues.billingAddress]){
            dispatch(ReadAddress(props.initialValues.billingAddress))
        }    
    }, []);

    const getBillingAddressInfo = () => {
        if(addresses[props.initialValues.billingAddress]){
            const address = addresses[props.initialValues.billingAddress];

            return (
                <List.Item>
                    <List.Content>
                        <List.Header>Endereço de Faturamento</List.Header>
                        <List.Description>
                            {address.street}, {address.number} {address.complement}, {address.neighborhood}
                            <br />
                            {normalizeZipcode(address.zipcode)}
                            <br />
                            {address.city} / {address.state}
                        </List.Description>
                    </List.Content>
                </List.Item>
            )
        }
        return null;
    }

    if(props.initialValues.billing){
        return (
            <List relaxed>
                <List.Item>
                    <List.Content floated='right'>
                        <a href={props.initialValues.billing} target="_blank">
                            <List.Icon name='file alternate outline' /> Boleto
                        </a>
                    </List.Content>
    
                    <List.Content>
                        <List.Header>Tipo</List.Header>
                    </List.Content>
                </List.Item>

                <List.Item>
                    <List.Content floated='right'>
                        x{props.initialValues.installments}
                    </List.Content>

                    <List.Content>
                        <List.Header>Parcelas</List.Header>
                    </List.Content>
                </List.Item>

                <List.Item>
                    <List.Content floated='right'>
                        { datetimeIsoToPtBr(props.initialValues.billingExpiration).split(' ')[0] }
                    </List.Content>

                    <List.Content>
                        <List.Header>Data de Vencimento</List.Header>
                    </List.Content>
                </List.Item>

                {getBillingAddressInfo()}
            </List>
        )
    }

    return null;
}
