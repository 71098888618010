import { 
    List as GenericList,
    Create as GenericCreate,
    Read as GenericRead,
    Update as GenericUpdate,
    Delete  as GenericDelete
} from './Generic';

export const LIST = 'LIST_ADDRESS';
export const CREATE = 'CREATE_ADDRESS';
export const READ = 'READ_ADDRESS';
export const UPDATE = 'UPDATE_ADDRESS';
export const DELETE = 'DELETE_ADDRESS';
export const USE = 'USE_ADDRESS';

const PATH = '/address';

export const List = (query={}) => async (dispatch) => {
    const response = await GenericList(dispatch, PATH, query);
    return dispatch({ type: LIST, statusCode: response.status, payload: response.data });
}

export const Create = (formValues) => async (dispatch) => {
    const response = await GenericCreate(dispatch, PATH, formValues);
    return dispatch({ type: CREATE, statusCode: response.status, payload: response.data });
}

export const Read = (id) => async dispatch => {
    const response = await GenericRead(dispatch, PATH, id);
    return dispatch({ type: READ, statusCode: response.status, payload: response.data });
}

export const Update = (id, formValues) => async dispatch => {
    const response = await GenericUpdate(dispatch, PATH, id, formValues);
    return dispatch({ type: UPDATE, statusCode: response.status, payload: response.data });
}

export const Delete = (id) => async dispatch => {
    const response = await GenericDelete(dispatch, PATH, id);
    return dispatch({ type: DELETE, statusCode: response.status, payload: {uuid: id} });
}

export const Use = (id) => {
    return { type: USE, payload: {uuid: id} };
}