import _ from 'lodash';

import React, { useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { change } from 'redux-form';

import { Form, Checkbox } from 'semantic-ui-react';
import { getInputError } from '../../Utils/FunctionsUtils';

export default function InputCheckboxKey(props) {
    const { input, label, meta, style, keyValue } = props;
    const dispatch = useDispatch();    

    const formValues = useSelector(state => state.form[props.meta.form].values[input.name]);
    const [check, setCheck] = useState(formValues && formValues.includes(keyValue));

    const handleChange = (e, checked) => {
        let newValues = Object.assign([], formValues);

        if(checked && !check){
            newValues.push(keyValue)
        }
        else if(!checked && check){
            _.remove(newValues, function(n) {
                return n === keyValue;
            });
        }

        dispatch(change(meta.form, input.name, newValues));
        setCheck(checked);
    }

    return (
        <Form.Field
            toggle
            style={style || {top: '40%'}}
            {...input}
            checked={check}
            onChange={(e, { checked }) => handleChange(e, checked)}
            onBlur={e => {}}
            label={label}
            control={Checkbox}
            error={getInputError(meta)} />
    )
}
