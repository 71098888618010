export { List as ListTable, Create, Read, Update, Delete } from '../../../Actions/Banner';

export * from './List';
export * from './Edit';
export * from './Create';
export * from './Validation';

export const entityReducer = 'banners';
export const entityListReducer = 'bannerList';

export const verboseName = 'Banner'
export const verboseNamePlural = 'Banners'
export const icon = 'images'
export const displayPropertyName = 'title';

export const editPath = '/banner'
export const listPath = '/banners'

export const createFormName = 'bannerCreate';
export const editFormName = 'bannerUpdate';

export const permission = 'banner';