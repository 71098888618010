import React from 'react';
import { Header } from 'semantic-ui-react';

export default function ToastMessage(props) {
    return (
        <Header
            inverted
            as='h3'
            content={props.header}
            subheader={props.content}
        />
    )
}