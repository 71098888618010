import _ from 'lodash';

import React, { useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { change } from "redux-form";

import Tree from 'react-d3-tree';
import { Segment, Grid, Button, Modal, Icon, Form } from 'semantic-ui-react';

import GlobalContext from '../../Context/GlobalContext';
import history from '../../history';

import DefaultTableToolbar from './DefaultTableToolbar'

import { List, Update } from '../../Actions/Category';

export default function CategoryTable(props) {
    const { body, verboseName, verboseNamePlural, icon } = props;
    const context = useContext(GlobalContext);

    const [nodeSelected, setNodeSelected] = useState(null);

    const dispatch = useDispatch();

    const getFlatCategories = (catArray) => {
        let flatCategories = []

        catArray.forEach(cat => {
            flatCategories.push({
                key: `cat-${cat.uuid}`,
                text: cat.nameSelect,
                value: cat.uuid
            });

            if(cat.categories.length > 0){
                flatCategories = _.concat(flatCategories, getFlatCategories(cat.categories))
            }
        })

        return flatCategories;
    }

    const changeMainCategory = (e, value) => {
        dispatch(Update(nodeSelected.uuid, {mainCategory: value.value})).then(() => {
            dispatch(List(context.table));
        })

        setNodeSelected(null);
    }

    const clickEdit = () => {
        history.push('/categoria/'+nodeSelected.uuid);
    }

    const clickSubCategory = () => {
        dispatch(change(props.form, 'mainCategory', nodeSelected.uuid))
        setNodeSelected(null);

        context.change({
            createModalOpen: true
        })
    }

    const clickDelete = () => {
        setNodeSelected(null);

        context.change({
            deleteModalOpen: true,
            table: {
                deleteList: [nodeSelected]
            }
        })
    }

    useEffect(() => {
        if(!context.createModalOpen){
            dispatch(change(props.form, 'mainCategory', null));
        }
    }, [context.createModalOpen]);

    const mountTreeData = (catArray) => {
        let tree = []

        catArray.forEach(cat => {
            let catObj = {
                uuid: cat.uuid,
                name: cat.name,
                nameSelect: cat.nameSelect,
                mainCategory: cat.mainCategory,
                attributes: {
                    ID: cat.id,
                    Home: cat.keys.includes('DISPLAY_HOME') ? 'Sim': 'Não',
                    Menu: cat.keys.includes('DISPLAY_MENU') ? 'Sim': 'Não'
                }
            }

            if(cat.categories.length > 0){
                catObj.children = mountTreeData(cat.categories)
            }

            tree.push(catObj);
        })

        return tree;
    }

    const treeData = {
        name: 'CATEGORIAS',
        children: mountTreeData(body.results)
    };

    const renderModalMenu = () => {
        if(nodeSelected && nodeSelected.uuid){
            return (
                <Modal size='small' open={true}>
                    <Modal.Header>Alterar Categoria</Modal.Header>
                    <Modal.Content>
                        {props.permissions.update &&
                            <Form.Field>
                                <Form.Select
                                    label='Categoria Principal'
                                    search
                                    fluid
                                    placeholder='Alterar Categoria Principal'
                                    defaultValue={nodeSelected.mainCategory}
                                    options={_.concat([{key: 'cat-null', text: 'Principal', value: null}], getFlatCategories(body.results))}
                                    onChange={(e, value) => {changeMainCategory(e, value)}}
                                />
                            </Form.Field>
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        {props.permissions.update && <Button icon labelPosition='left' color='green' onClick={clickEdit}><Icon name='edit' />Editar</Button>}
                        {(!props.permissions.update && props.permissions.read) && <Button icon labelPosition='left' color='green' onClick={clickEdit}><Icon name='eye' />Visualizar</Button>}
                        {props.permissions.create && <Button icon labelPosition='left' color='blue' onClick={clickSubCategory}><Icon name='plus' />Subcategoria</Button>}
                        {props.permissions.delete && <Button icon labelPosition='left' color='red' onClick={clickDelete}><Icon name='times' /> Excluir</Button>}
                        <Button icon labelPosition='left' color='grey' onClick={()=>{setNodeSelected(null)}}><Icon name='arrow left' />Voltar</Button>
                    </Modal.Actions>
                </Modal>
            )
        }
    }

    return (
        <Segment>
            <DefaultTableToolbar
                {...props}

                body={body}
                icon={icon}
                verboseName={verboseName}
                verboseNamePlural={verboseNamePlural} />

            {renderModalMenu()}

            <Grid id="treeWrapper" className='treeWrapper' centered>
                <Tree
                    data={treeData}
                    collapsible={false}
                    orientation='vertical'
                    translate={{x: 500, y: 50}}
                    rootNodeClassName="node__root"
                    branchNodeClassName="node__branch"
                    leafNodeClassName="node__leaf"
                    onNodeClick={(el) => { setNodeSelected(el) }}
                />
            </Grid>
        </Segment>
    )
}
