import React from 'react';
import { useSelector } from "react-redux";
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputString,
    renderInputCheckbox,
    renderInputVoucherKeys,
    renderInputInteger,
    renderSelectMerchant
} from '../../../../Utils/RenderUtils';

import { normalizeInteger } from '../../../../Utils/NormalizeUtils';

export default function Tab01(props) {
    const formValues = useSelector(state => state.form[props.form].values);

    return (
        <React.Fragment>
            <FormSection fluid color="blue" title="Informações Gerais">
                <Form.Group widths={4}>
                    <Field name="active" label="Ativo" component={renderInputCheckbox} type="checkbox" />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Field name="name" label="Nome" component={renderInputString} />
                    <Field name="code" label="Código" component={renderInputString} />
                </Form.Group>
                <Form.Group widths='3'>
                    {!formValues.keys.includes('CAMPAIGN') && <Field name="amount" label="Quantidade de Utilizações" component={renderInputInteger} normalize={normalizeInteger} />}
                    <Field name="keys" label="Tipo" component={renderInputVoucherKeys} />
                    <Field name="merchant" label="Loja" component={renderSelectMerchant} />
                </Form.Group>
            </FormSection>
        </React.Fragment>
    )
}
