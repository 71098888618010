import {
    SIGN_IN,
    SIGN_OUT,
    SIGN_DATA,
    SIGN_REFRESH,
    SIGN_ERROR
} from '../Actions/Auth';

import {
    UPDATE
} from '../Actions/Administrator';

const INITIAL_STATE = () => {
    return {
        access: localStorage.getItem('accessToken'),
        refresh: localStorage.getItem('refreshToken'),
        customer: {},
        administrator: {}
    }
}

const clearLocalStorage = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
}

const setLocalStorage = (access, refresh) => {
    if(access){
        localStorage.setItem('accessToken', access);
    }

    if(refresh){
        localStorage.setItem('refreshToken', refresh);
    }
}

const AuthReducer = (state = INITIAL_STATE(), action) => {
    switch(action.type){
        case SIGN_IN:
            setLocalStorage(action.payload.access, action.payload.refresh);
            return { ...state, ...action.payload }
        case SIGN_OUT:
            clearLocalStorage();
            return INITIAL_STATE();
        case SIGN_DATA:
            return { ...state, ...action.payload }
        case SIGN_REFRESH:
            setLocalStorage(action.payload.access);
            return { ...state, ...action.payload }
        case SIGN_ERROR:
            clearLocalStorage();
            return { ...state, ...action.payload }
        case UPDATE:
            if(state.administrator.uuid === action.payload.uuid){
                return { ...state, ...{administrator: action.payload} }
            }
    }

    return state;
}

export default AuthReducer;