import React from 'react'
import { Button, Header, Icon, Modal } from 'semantic-ui-react'

export default function AlertModal(props) {
    const type = props.type || 'success';

    const properties = {
        success: {
            icon: 'check',
            color: 'green'
        },
        warning: {
            icon: 'exclamation',
            color: 'yellow'
        },
        error: {
            icon: 'times',
            color: 'red'
        },
        info: {
            icon: 'info',
            color: 'blue'
        },
        question: {
            icon: 'question',
            color: 'orange'
        },
    }

    return (
        <Modal
        dimmer='blurring'
        size='tiny'
        open={true}
        onClose={() => props.handleClose()}>

            <Header icon={properties[type].icon} color={properties[type].color} content={props.title} />

            <Modal.Content>
                <p>{props.message}</p>
            </Modal.Content>

            <Modal.Actions>
                <Button color={properties[type].color} onClick={() => props.handleClose()}>
                    <Icon name={properties[type].icon} /> Entendi
                </Button>
            </Modal.Actions>
        </Modal>
    )
}
