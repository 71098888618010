export const tableConf = {
    searchFields: ['id', 'name']
}

const usedAmount = (el) => {
    return `${el.used} / ${el.amount}`
}

export const tableHeader = [
    {
        field: 'id',
        label: 'ID',
        dataType: 'integer',
        sortable: true
    },
    {
        field: 'active',
        label: 'Ativo',
        dataType: 'boolean',
        sortable: true
    },
    {
        field: 'createdAt',
        label: 'Data de Criação',
        dataType: 'datetime',
        sortable: true
    },
    {
        field: 'name',
        label: 'Nome',
        dataType: 'string',
        sortable: true
    },
    {
        field: 'keys',
        label: 'Tipo',
        dataType: 'array',
        sortable: true,
        map: {
            CAMPAIGN: 'Campanha',
            BATCH: 'Lote',
            SINGLE: 'Único',
            FIRST_ORDER: 'Primeiro Pedido'
        }
    },
    {
        field: 'initDate',
        label: 'Data de Início',
        dataType: 'datetime',
        sortable: true,
    },
    {
        field: 'endDate',
        label: 'Data de Encerramento',
        dataType: 'datetime',
        sortable: true,
        ifNull: 'Sem data limite'
    },
    {
        label: 'Utlizado',
        dataType: 'function',
        renderFunction: usedAmount,
        sortable: false
    },
];