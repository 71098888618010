export { List as ListTable, Create, Read, Update, Delete } from '../../../Actions/Category';

export * from './List';
export * from './Edit';
export * from './Create';
export * from './Validation';

export const entityReducer = 'categories';
export const entityListReducer = 'categoryList';

export const verboseName = 'Categoria'
export const verboseNamePlural = 'Categorias'
export const icon = 'sort alphabet up'
export const displayPropertyName = 'nameSelect';

export const editPath = '/categoria'
export const listPath = '/categorias'

export const createFormName = 'categoryCreate';
export const editFormName = 'categoryUpdate';

export const permission = 'category';