export const tableConf = {
    searchFields: ['id', 'code', 'customer__first_name', 'customer__last_name', 'customer__user__email', 'customer__user__username'],
    order: '-',
    orderBy: 'createdAt',
    expand: 'customer'
}

const customerName = (el) => {
    return `${el.customer.firstName || el.customer} ${el.customer.lastName || ''}`
}

export const tableHeader = [
    {
        field: 'id',
        label: 'ID',
        dataType: 'integer',
        sortable: true
    },
    {
        field: 'active',
        label: 'Ativo',
        dataType: 'boolean',
        sortable: true
    },
    {
        field: 'createdAt',
        label: 'Data de Criação',
        dataType: 'datetime',
        sortable: true
    },
    {
        field: 'code',
        label: 'Código',
        sortable: true
    },
    {
        field: 'customer',
        label: 'Cliente',
        dataType: 'function',
        renderFunction: customerName,
        sortable: true
    },
    {
        field: 'total',
        label: 'Total',
        dataType: 'decimal',
        sortable: true,
        prefix: 'R$',
        toFixed: 2
    },
    {
        field: 'status',
        label: 'Status',
        dataType: 'map',
        sortable: true,
        map: {
            CART: 'Carrinho',
            WAITING_PAYMENT: 'Aguardando Pagamento',
            NEW: 'Novo',
            PAID: 'Novo',
            INVOICED: 'Faturado',
            PROCESSED: 'Separado/Processado',
            SHIPPED: 'Enviado',
            DONE: 'Finalizado',
            FAILED: 'Falha no Pagamento',
            CANCELLED: 'Cancelado',
            REFUNDED: 'Estornado',
        }
    }
];
