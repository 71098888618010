export const tableConf = {
    searchFields: ['id', 'groupName', 'title']
}

export const tableHeader = [
    {
        field: 'id',
        label: 'ID',
        dataType: 'integer',
        sortable: true
    },
    {
        field: 'active',
        label: 'Ativo',
        dataType: 'boolean',
        sortable: true
    },
    {
        field: 'createdAt',
        label: 'Data de Criação',
        dataType: 'datetime',
        sortable: true
    },
    {
        field: 'groupName',
        label: 'Grupo',
        dataType: 'string',
        sortable: true
    },
    {
        field: 'title',
        label: 'Título',
        dataType: 'string',
        sortable: true,
        className: 'big-content-cell'
    },
    {
        field: 'slug',
        label: 'URL',
        dataType: 'string',
        sortable: false
    }
];
