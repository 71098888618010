import React from 'react';

import DefaultTableToolbar from './DefaultTableToolbar'
import DefaultTableHeader from './DefaultTableHeader'
import DefaultTableBody from './DefaultTableBody'
import DefaultTableFooter from './DefaultTableFooter'

import { Table, Segment } from 'semantic-ui-react';


export default function DefaultTable(props) {
    const { head, body, verboseName, verboseNamePlural, icon, editPath } = props;

    return (
        <Segment>
            <DefaultTableToolbar
                {...props}

                body={body}
                icon={icon}
                verboseName={verboseName}
                verboseNamePlural={verboseNamePlural} />

            <Table compact celled sortable>
                <DefaultTableHeader
                    {...props}

                    head={head}
                    body={body} />

                <DefaultTableBody
                    {...props}

                    head={head}
                    body={body}
                    editPath={editPath} />

                <DefaultTableFooter
                    {...props}

                    body={body}
                    head={head}
                    verboseNamePlural={verboseNamePlural} />
            </Table>
        </Segment>
    )
}
