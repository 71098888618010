import React from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputDecimal,
    renderInputString,
    renderInputInteger,
    renderMultiSelectCategory,
    renderMultiSelectProduct,
    renderInputCheckboxKey,
} from '../../../../Utils/RenderUtils';

import {
    normalizeDecimal,
    normalizeInteger,
    normalizeZipcode
} from '../../../../Utils/NormalizeUtils';

export default function Tab03() {
    return (
        <React.Fragment>
            <FormSection fluid color="green" title="Valor e Quantidade">
                <Form.Group widths={4}>
                    <Field name="keys" label="Somente Primeiro Pedido" keyValue="FIRST_ORDER" component={renderInputCheckboxKey} type="checkbox" />
                    <Field name="triggerValue" label="Valor Mínimo do Pedido (R$)" component={renderInputDecimal} normalize={normalizeDecimal} />
                    <Field name="triggerAmount" label="Quantidade Mínima de Itens no Pedido" component={renderInputInteger} normalize={normalizeInteger} />
                </Form.Group>
            </FormSection>

            <FormSection fluid color="blue" title="Faixa de Cep">
                <Form.Group widths={4}>
                    <Field name="triggerZipcodeInit" label="Cep Inicial" component={renderInputString} normalize={normalizeZipcode} format={normalizeZipcode} />
                    <Field name="triggerZipcodeEnd" label="Cep Final" component={renderInputString} normalize={normalizeZipcode} format={normalizeZipcode} />
                </Form.Group>
            </FormSection>

            <FormSection fluid color="yellow" title="Regras da Loja">
                <Field name="categories" label="Categorias Específicas" component={renderMultiSelectCategory} />
                <Field name="products" label="Produtos Específicos" component={renderMultiSelectProduct} />
            </FormSection>
        </React.Fragment>
    )
}
