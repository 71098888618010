import React from 'react';
import { Form, Select } from 'semantic-ui-react'
import slugify from 'react-slugify';

import { getInputError } from '../../Utils/FunctionsUtils';

export default function InputSelect(props) {
    const { input, label, meta, disabled, keyOptions} = props;

    const getOptions = () => {
        let options = [];

        keyOptions.forEach((item, index) => {
            options.push({
                key: `${item.value}-option-${index}-${slugify(label)}`,
                text: `${item.label}`,
                value: item.value
            })
        })

        return options;        
    }

    return (
        <Form.Field
            {...input}
            disabled={disabled}
            control={Select}
            label={{ children: label, htmlFor: `form-select-control-${slugify(label)}` }}
            options={getOptions()}
            onChange={(param, data) => input.onChange(data.value)}
            onBlur={(param, data) => input.onBlur(data.value)}
            error={getInputError(meta)} />
    )
}
