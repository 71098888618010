export { List as ListTable, Create, Read, Update, Delete } from '../../../Actions/Merchant';

export * from './List';
export * from './Edit';
export * from './Create';
export * from './Validation';

export const entityReducer = 'merchants';
export const entityListReducer = 'merchantList';

export const verboseName = 'Loja'
export const verboseNamePlural = 'Lojas'
export const icon = 'warehouse'
export const displayPropertyName = 'tradingName';

export const editPath = '/loja'
export const listPath = '/lojas'

export const createFormName = 'merchantCreate';
export const editFormName = 'merchantUpdate';

export const permission = 'merchant';