import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Input, Menu, Icon, Dropdown } from 'semantic-ui-react';

import GlobalContext from '../../Context/GlobalContext';

import { useSelector, useDispatch } from "react-redux";

import { signOut } from '../../Actions/Auth';

export default function Navbar(props) {
    const context = useContext(GlobalContext);

    const auth = useSelector(state => state.auth);

    const dispatch = useDispatch();

    const logoutClick = () => {
        dispatch(signOut());
    }

    if(auth.administrator.name){
        return (
            <Menu inverted>
                <Menu.Item onClick={() => { context.change({ displaySidebar: !context.displaySidebar }) }}>
                    <Icon name="bars" /> Menu
                </Menu.Item>

                <Menu.Menu position='right'>
                    <Menu.Item>
                        <Input icon='search' placeholder='Search...' />
                    </Menu.Item>

                    <Menu.Item as='a'>
                        <Icon name="alarm" />
                    </Menu.Item>

                    <Dropdown item simple icon="user">
                        <Dropdown.Menu>
                            <Link to={`/administrador/${auth.administrator.uuid}`} className='item'>
                                <Icon name="info" /> Perfil
                            </Link>

                            <Dropdown.Item onClick={logoutClick}>
                                <Icon name="settings" /> Configurações
                            </Dropdown.Item>

                            <Dropdown.Item onClick={logoutClick}>
                                <Icon name="sign-out" /> Sair
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Menu>
            </Menu>
        )
    }

    return null;
}