import React, {  useState } from 'react';
import { Card, Grid, List, Divider, Icon } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import StatusSteps from './StatusSteps';
import CreditCardInfo from './CreditCardInfo';
import BillingInfo from './BillingInfo';
import ShippingInfo from './ShippingInfo';
import CustomerInfo from './CustomerInfo';
import ChangeStatusModal from './ChangeStatusModal';

export default function Tab01(props) {
    const [changeStatus, setChangeStatus] = useState({});

    const getDiscountsSum = () => {
        return props.initialValues.discounts.map(el => {
            return el.amount;
        }).reduce((a, b) => a + b, 0)
    }

    const getAdditionsSum = () => {
        return props.initialValues.additions.map(el => {
            return el.amount;
        }).reduce((a, b) => a + b, 0)
    }

    const getPaymentInfo = () => {
        if(props.initialValues.paymentType === 'CREDIT_CARD'){
            return <CreditCardInfo {...props} />;
        }

        if(props.initialValues.paymentType === 'BILLING' || props.initialValues.paymentType === 'PIX'){
            return <BillingInfo {...props} />;
        }
        
        return <BillingInfo {...props} />
    }
    return (
        <React.Fragment>
            <StatusSteps {...props} setChangeStatus={setChangeStatus}/>

            <ChangeStatusModal {...props} changeStatus={changeStatus} setChangeStatus={setChangeStatus} />

            <FormSection fluid color="blue">
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Card fluid>
                                <Card.Content>
                                    <Card.Header><Icon name='dollar'/> Resumo</Card.Header>
                                </Card.Content>
                                <Card.Content>
                                    <List relaxed>
                                        <List.Item className="order-green-list-item">
                                            <List.Content floated='right'>
                                                R$ {props.initialValues.productsValue.toFixed(2)}
                                            </List.Content>

                                            <List.Icon name='boxes' />

                                            <List.Content>
                                                Valor dos Produtos
                                            </List.Content>
                                        </List.Item>

                                        <List.Item className="order-green-list-item">
                                            <List.Content floated='right'>
                                                R$ {props.initialValues.shippingValue.toFixed(2)}
                                            </List.Content>

                                            <List.Icon name='shipping' />

                                            <List.Content>
                                                Valor do Frete
                                            </List.Content>
                                        </List.Item>

                                        <List.Item className="order-green-list-item">
                                            <List.Content floated='right'>
                                                R$ {getAdditionsSum().toFixed(2)}
                                            </List.Content>

                                            <List.Icon name='plus' />

                                            <List.Content>
                                                Acréscimos
                                            </List.Content>
                                        </List.Item>

                                        <List.Item className="order-red-list-item">
                                            <List.Content floated='right'>
                                                - R$ {getDiscountsSum().toFixed(2)}
                                            </List.Content>

                                            <List.Icon name='minus' />

                                            <List.Content>
                                                Descontos
                                            </List.Content>
                                        </List.Item>

                                        <Divider />

                                        <List.Item>
                                            <List.Content floated='right'>
                                                R$ {props.initialValues.total.toFixed(2)}
                                            </List.Content>

                                            <List.Icon name='dollar' />

                                            <List.Content>
                                                Total
                                            </List.Content>
                                        </List.Item>
                                    </List>
                                </Card.Content>
                            </Card>
                        </Grid.Column>

                        <Grid.Column width={8}>
                            <Card fluid>
                                <Card.Content>
                                    <Card.Header><Icon name='user'/> Informações do Cliente</Card.Header>
                                </Card.Content>
                                <Card.Content>
                                    <CustomerInfo {...props} />
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Card fluid>
                                <Card.Content>
                                    <Card.Header><Icon name='plus'/> Acréscimos</Card.Header>
                                </Card.Content>
                                <Card.Content>
                                    <List divided relaxed>
                                        {
                                            props.initialValues.additions.map( el => {
                                                return (
                                                    <List.Item>
                                                        <List.Content floated='right'>
                                                            R$ {el.amount.toFixed(2)}
                                                        </List.Content>
            
                                                        <List.Content>
                                                            <List.Header>{el.key}</List.Header>
                                                            <List.Description>{el.info}</List.Description>
                                                        </List.Content>
                                                    </List.Item>
                                                )
                                            })
                                        }
                                    </List>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Card fluid>
                                <Card.Content>
                                    <Card.Header><Icon name='minus'/> Descontos</Card.Header>
                                </Card.Content>
                                <Card.Content>
                                    <List divided relaxed>
                                        {props.initialValues.discounts.map( el => {
                                            return (
                                                <List.Item>
                                                    <List.Content floated='right'>
                                                        R$ {el.amount.toFixed(2)}
                                                    </List.Content>
        
                                                    <List.Content>
                                                        <List.Header>{el.key}</List.Header>
                                                        <List.Description>{el.info}</List.Description>
                                                    </List.Content>
                                                </List.Item>
                                            )
                                        })}
                                    </List>
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Card fluid>
                                <Card.Content>
                                    <Card.Header><Icon name='credit card'/> Informações de Pagamento</Card.Header>
                                </Card.Content>
                                <Card.Content>
                                    {getPaymentInfo()}
                                </Card.Content>
                            </Card>
                        </Grid.Column>

                        <Grid.Column width={8}>
                            <Card fluid>
                                <Card.Content>
                                    <Card.Header><Icon name='shipping'/> Informações de Entrega</Card.Header>
                                </Card.Content>
                                <Card.Content>
                                    <ShippingInfo {...props} />
                                </Card.Content>
                            </Card>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </FormSection>

            
        </React.Fragment>
    )
}
