export { List as ListTable, Create, Read, Update, Delete } from '../../../Actions/Manufacturer';

export * from './List';
export * from './Edit';
export * from './Create';
export * from './Validation';

export const entityReducer = 'manufacturers';
export const entityListReducer = 'manufacturerList';

export const verboseName = 'Fabricante'
export const verboseNamePlural = 'Fabricantes'
export const icon = 'industry'
export const displayPropertyName = 'name';

export const editPath = '/fabricante'
export const listPath = '/fabricantes'

export const createFormName = 'manufacturerCreate';
export const editFormName = 'manufacturerUpdate';

export const permission = 'manufacturer';