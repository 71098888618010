import React, { useEffect } from 'react';

import { Form, Select, Input } from 'semantic-ui-react';

import { useSelector, useDispatch } from "react-redux";
import { Read } from '../../Actions/PropertyGroup'

import { getInputError } from '../../Utils/FunctionsUtils';

export default function SelectPropertyGroup(props) {
    const { input, label, meta, width, uuid } = props;

    const propertyGroups = useSelector(state => state.propertyGroups);
    const dispatch = useDispatch();

    let { propertyGroup } = props;

    useEffect(() => {
        if(!propertyGroup){
            if(!propertyGroups[uuid]){
                dispatch(Read(uuid, {fields: ['uuid', 'name', 'properties']}));
            }

            propertyGroup = propertyGroups[uuid];
        }

    }, [propertyGroups]);

    const getOptions = () => {
        let options = [];

        if(propertyGroup){
            options.push({
                key: `${propertyGroup.uuid}-entity-propertyGroup-rows`,
                text: '--- Nenhum ---',
                value: null
            });
    
            (propertyGroup.properties || []).forEach((item) => {
                options.push({
                    key: `${item.uuid}-entity-property-rows`,
                    text: `${item.name}`,
                    value: item.uuid
                })
            })
        }

        return options;        
    }

    if(propertyGroup){
        return (
            <Form.Field
                {...input}
                control={Select}
                search
                searchInput={{ id: `form-select-control-propertygroup-${propertyGroup.uuid}` }}
                label={{ children: label, htmlFor: `form-select-control-propertygroup-${propertyGroup.uuid}` }}
                options={getOptions()}
                onChange={(param, data) => input.onChange(data.value)}
                onBlur={(param, data) => input.onBlur(data.value)}
                error={getInputError(meta)}
                width={width}/>
        )
    } else {
        return (<Input loading disabled placeholder='Carregando...' />)
    }
}
