import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { List } from 'semantic-ui-react';

import { Read as ReadAddress } from '../../../../Actions/Address';
import { Read as ReadShipping } from '../../../../Actions/Shipping';

import { normalizeZipcode } from '../../../../Utils/NormalizeUtils';
import { datetimeIsoToPtBr } from '../../../../Utils/FunctionsUtils';

export default function BillingInfo(props) {
    const addresses = useSelector(state => state.addresses);
    const shippings = useSelector(state => state.shippings);

    const dispatch = useDispatch();

    useEffect(() => {
        if(props.initialValues.shipping && !shippings[props.initialValues.shipping]){
            dispatch(ReadShipping(props.initialValues.shipping))
        }

        if(props.initialValues.shippingAddress && !addresses[props.initialValues.shippingAddress]){
            dispatch(ReadAddress(props.initialValues.shippingAddress))
        }
    }, []);

    const getShippingAddressInfo = () => {
        if(addresses[props.initialValues.shippingAddress]){
            const address = addresses[props.initialValues.shippingAddress];

            return (
                <List.Item>
                    <List.Content>
                        <List.Header>Endereço de Entrega</List.Header>
                        <List.Description>
                            {address.street}, {address.number} {address.complement}, {address.neighborhood} - {address.reference}
                            <br />
                            {normalizeZipcode(address.zipcode)}
                            <br />
                            {address.city} / {address.state}
                        </List.Description>
                    </List.Content>
                </List.Item>
            )
        }
        return null;
    }

    if(shippings[props.initialValues.shipping] && addresses[props.initialValues.shippingAddress]){
        return (
            <List relaxed>
                <List.Item>
                    <List.Content floated='right'>
                        {shippings[props.initialValues.shipping].name}
                    </List.Content>
    
                    <List.Content>
                        <List.Header>Transportadora</List.Header>
                    </List.Content>
                </List.Item>

                <List.Item>
                    <List.Content floated='right'>
                        R$ {props.initialValues.shippingValue.toFixed(2)}
                    </List.Content>

                    <List.Content>
                        <List.Header>Valor do Frete</List.Header>
                    </List.Content>
                </List.Item>

                <List.Item>
                    <List.Content floated='right'>
                        {(props.initialValues.shippingDeliveryTime/60).toFixed(0)} horas
                    </List.Content>

                    <List.Content>
                        <List.Header>Tempo de Entrega</List.Header>
                    </List.Content>
                </List.Item>

                <List.Item>
                    <List.Content floated='right'>
                        { datetimeIsoToPtBr(props.initialValues.deliveryDate) }
                    </List.Content>

                    <List.Content>
                        <List.Header>Data Limite de Entrega</List.Header>
                    </List.Content>
                </List.Item>

                {getShippingAddressInfo()}
            </List>
        )
    }

    return null;
}
