import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { Icon, Menu, Image, Grid, Header } from 'semantic-ui-react';

import GlobalContext from '../../Context/GlobalContext';
import * as Constants from '../../Constants';

import { Read as ReadImage } from '../../Actions/Image';
import { signOut } from '../../Actions/Auth';

export default function APICommerceSidebar() {
    const context = useContext(GlobalContext);
    const auth = useSelector(state => state.auth);
    const images = useSelector(state => state.images);

    const allPermissions = useSelector(state => state.auth.administrator.permissions);
    const superAdmin = allPermissions ? allPermissions?.superAdmin || false : false;

    const dispatch = useDispatch();

    useEffect(() => {
        if(auth.administrator && auth.administrator.images){
            auth.administrator.images.forEach(imageUuid => {
                if(!images[imageUuid]){
                    dispatch(ReadImage(imageUuid));
                }
            })
        }
    }, [auth.administrator]);

    const getAvatarImage = () => {
        let avatarImage = {
            urls: {
                default: '/logo-novo.png'
            },
            publicFolder: true
        }

        if(auth.administrator && auth.administrator.images){
            auth.administrator.images.forEach(imageUuid => {
                if(images[imageUuid] && images[imageUuid].tags.includes('AVATAR')){
                    avatarImage = images[imageUuid];
                }
            })
        }

        return avatarImage;
    }

    const renderAvatarCard = () => {
        let imgCtx = Constants.IMAGES_CTX;
        if(getAvatarImage().publicFolder){
            imgCtx = '';
        }

        if(auth.administrator && auth.administrator.name){
            return (
                <Menu.Item>
                    <Grid centered columns={2}>
                        <Grid.Column>
                            <Image src={imgCtx+getAvatarImage().urls.default} size='tiny' circular />
                        </Grid.Column>
                    </Grid>
                    
                    <Header as='h3' textAlign='center' inverted>
                        {auth.administrator.name}

                        <Header.Subheader>
                            <br/>
                            <Link to={`/administrador/${auth.administrator.uuid}`}>
                                <Icon name="info"/>Perfil
                            </Link>
                            {'\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'}
                            <Link to='/entrar'>
                                <span onClick={() => { dispatch(signOut()) }}><Icon name="sign-out" /> Sair</span>
                            </Link>
                        </Header.Subheader>
                    </Header>
                </Menu.Item>
            )
        }
    }

    if(allPermissions){
        return (
            <Menu inverted vertical style={context.displaySidebar ? {} : {display: 'none'}}>
                {renderAvatarCard()}
    
                {(superAdmin || allPermissions?.merchant.list || allPermissions?.banner.list) &&
                    <Menu.Item>
                        <Icon name='warehouse'/> Lojas
    
                        <Menu.Menu>
                            {(superAdmin || allPermissions?.merchant.list) &&<Link to='/lojas' className='item'><span className="spaced"><Icon name='warehouse'/> Lojas</span></Link>}
                            {(superAdmin || allPermissions?.banner.list) && <Link to='/banners' className='item'><span className="spaced"><Icon name='images'/> Banners</span></Link>}
                            {(superAdmin || allPermissions?.institutional?.list) && <Link to='/institucionais' className='item'><span className="spaced"><Icon name='clipboard outline'/> Páginas Institucionais</span></Link>}
                        </Menu.Menu>
                    </Menu.Item>
    
                }
    
                {(superAdmin || allPermissions?.administrator.list) &&
                    <Link to='/administradores' className='item'>
                        <Icon name='user secret'/> Administradores
                    </Link>
                }
    
                {(superAdmin || allPermissions?.product.list || allPermissions?.manufacturer.list || allPermissions?.property.list) &&
                    <Menu.Item>
                        <Icon name='boxes'/> Produtos
    
                        <Menu.Menu>
                            {(superAdmin || allPermissions?.product.list) && <Link to='/produtos' className='item'><span className="spaced"><Icon name='boxes'/> Produtos</span></Link>}
                            {(superAdmin || allPermissions?.manufacturer.list) && <Link to='/fabricantes' className='item'><span className="spaced"><Icon name='industry'/> Fabricantes</span></Link>}
                            {(superAdmin || allPermissions?.property.list) && <Link to='/propriedades' className='item'><span className="spaced"><Icon name='configure'/> Propriedades</span></Link>}
                        </Menu.Menu>
                    </Menu.Item>
                }
    
                {(superAdmin || allPermissions?.category.list) &&
                    <Link to='/categorias' className='item'>
                        <Icon name='sort alphabet up'/> Categorias
                    </Link>
                }
    
                {(superAdmin || allPermissions?.customer?.list || allPermissions?.newsletter?.list) &&
                    <Menu.Item>
                        <Icon name='users'/> Clientes
    
                        <Menu.Menu>
                            {(superAdmin || allPermissions?.customer?.list) && <Link to='/clientes' className='item'><span className="spaced"><Icon name='users'/> Clientes</span></Link>}
                            {(superAdmin || allPermissions?.newsletter?.list) && <Link to='/newsletters' className='item'><span className="spaced"><Icon name='newspaper'/> Newsletter</span></Link>}
                        </Menu.Menu>
                    </Menu.Item>
                }
    
                {(superAdmin || allPermissions?.order.list) &&
                    <Link to='/pedidos' className='item'>
                        <Icon name='shopping cart'/> Pedidos
                    </Link>
                }
    
                {(superAdmin || allPermissions?.shipping.list) &&
                    <Link to='/transportadoras' className='item'>
                        <Icon name='shipping fast'/> Transportadoras
                    </Link>
                }
    
                {(superAdmin || allPermissions?.voucher.list) &&
                    <Link to='/cupons' className='item'>
                        <Icon name='ticket'/> Descontos
                    </Link>
                }
    
                {superAdmin &&
                    <Menu.Item>
                        <Icon name='calendar'/> Assinaturas
    
                        <Menu.Menu>
                            <Link to='/' className='item'><span className="spaced"><Icon name='calendar'/> Planos de Assinatura</span></Link>
                            <Link to='/' className='item'><span className="spaced"><Icon name='clipboard'/> Assinaturas Ativas</span></Link>
                        </Menu.Menu>
                    </Menu.Item>
                }
            </Menu>
        )
    }

    return null;
}
