import React from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputString,
    renderInputCheckbox,
    renderSelectMerchant,
    renderInputCheckboxKey
} from '../../../../Utils/RenderUtils';

import { normalizeInteger } from '../../../../Utils/NormalizeUtils';

export default function Tab01() {
    return (
        <React.Fragment>
            <FormSection fluid color="green" title="Informações Gerais">
                <Form.Group widths={3}>
                    <Field name="active" label="Ativo" component={renderInputCheckbox} type="checkbox" />
                    <Field name="keys" label="Filtro" keyValue="FILTER" component={renderInputCheckboxKey} type="checkbox" />
                    <Field name="externalId" label="Código Externo (ERP)" component={renderInputString} />
                </Form.Group>

                <Form.Group widths='equal'>
                    <Field name="name" label="Nome" component={renderInputString} />
                    <Field name="merchant" label="Loja" component={renderSelectMerchant} />
                    <Field name="sort" label="Prioridade" component={renderInputString} normalize={normalizeInteger} />
                </Form.Group>
            </FormSection>
        </React.Fragment>
    )
}
