import Tab01 from '../Components/UpdateForm/Tab01';
import Tab02 from '../Components/UpdateForm/Tab02';

export const preFilter = (formValues, initialValues, dispatch) => {
    formValues.properties.forEach((prop, index) => {
        prop.sort = index;
    })

    return formValues;
};
export const preDispatch = null;

export const mountEditPanes = (props) => {
    return [
        {
            menuItem: { key: 'general', icon: 'info', content: 'Informações Gerais' },
            render: () => <Tab01 {...props} />
        },
        {
            menuItem: { key: 'values', icon: 'list', content: 'Valores' },
            render: () => <Tab02 {...props} />
        }
    ]
}