import _ from 'lodash';
import history from '../../history';

import React from 'react';
import { reduxForm } from 'redux-form';
import { useDispatch, useSelector } from "react-redux";
import { Form, Tab, Segment } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import { filterFormDifferences } from '../../Utils/FunctionsUtils';

import { getToast } from '../../Utils/ToastUtils';
import ToastMessage from '../../Components/Modal/ToastMessage';

function FormUpdate(props) {
    const { Update, verboseName, listPath, mountEditPanes, preFilter, preDispatch } = props;
    const formValues = useSelector(state => state.form[props.form].values);

    const dispatch = useDispatch();

    const onSubmit = (submitFormValues) => {
        if(submitFormValues){
            let filteredValues = _.cloneDeep(submitFormValues);
    
            if(preFilter){
                filteredValues = preFilter(filteredValues, props.initialValues, dispatch);
            }
    
            filteredValues = filterFormDifferences(filteredValues, props.initialValues);
    
            if(preDispatch){
                filteredValues = preDispatch(filteredValues, props.initialValues, dispatch);
            }
    
            dispatch(Update(props.uuid, filteredValues)).then((result) => {
                if(result.statusCode === 200){
                    getToast(<ToastMessage header='Sucesso!' content={`${verboseName} alterado`}/>, toast.TYPE.SUCCESS)();
                    history.push(listPath)
                }
            }).catch((err) => {
                getToast(<ToastMessage header='Erro!' content={`${err.response.data.field}: ${err.response.data.detail}`}/>, toast.TYPE.ERROR)();
            });
        }
    }

    return (
        <Form onSubmit={props.handleSubmit(onSubmit)}>

            <Segment>
                <Tab menu={{ secondary: true, pointing: true }} panes={mountEditPanes(Object.assign({formValues: formValues}, props))} />
            </Segment>

            <button id='hiddenSubmit' style={{display: 'none'}} type='submit'>Enviar</button>
        </Form>
    )
}

export default reduxForm()(FormUpdate);
