import _ from 'lodash';

import React from 'react';

import { Form, Dropdown, Input } from 'semantic-ui-react'

import { useEffect, useState } from 'react';

import { useSelector, useDispatch } from "react-redux";
import { List } from '../../Actions/Merchant'

export default function MultiSelectMerchant(props) {
    const { input, label, meta } = props;

    const entityList = useSelector(state => state.merchantList);
    const [options, setOptions] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        if(!entityList || !entityList.results || entityList.count > entityList.results.length){
            dispatch(List({ rowsPerPage: 9999, orderBy: 'tradingName', fields: ['uuid', 'trading_name'] }));
        }
    }, []);

    useEffect(() => {
        if(entityList.results){
            let optionsArray = [];

            entityList.results.forEach((item) => {
                optionsArray.push({
                    key: `${item.uuid}-entity-merchant-rows`,
                    text: `${item.tradingName}`,
                    value: item.uuid
                })
            })

            setOptions(optionsArray);
        }
    }, [entityList]);

    const onChange = (param, data) => {
        input.onChange(data.value);
    }

    const onBlur = (param, data) => {
        input.onBlur(data.value);
    }

    if(entityList && entityList.results && entityList.count === entityList.results.length){
        return (
            <Form.Field>
                <label>{label}</label>

                <Dropdown
                    {...input}
                    fluid
                    multiple
                    search
                    selection
                    onChange={(param, data) => onChange(param, data)}
                    onBlur={(param, data) => onBlur(param, data)}
                    options={options}
                />
            </Form.Field>
        )
    } else {
        return (<Input fluid loading disabled placeholder='Carregando...' />)
    }
}
