export { List as ListTable, Create, Read, Update, Delete } from '../../../Actions/Order';

export * from './List';
export * from './Edit';
export * from './Create';
export * from './Validation';

export const entityReducer = 'orders';
export const entityListReducer = 'orderList';

export const verboseName = 'Pedido'
export const verboseNamePlural = 'Pedidos'
export const icon = 'shopping cart'
export const displayPropertyName = 'code';

export const editPath = '/pedido'
export const listPath = '/pedidos'

export const createFormName = 'orderCreate';
export const editFormName = 'orderUpdate';

export const permission = 'order';