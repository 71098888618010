import React, { useState, useCallback } from 'react';
import { Button, Header, Icon, Segment, Dimmer, Loader } from 'semantic-ui-react'
import { useDropzone } from 'react-dropzone';
import slugify from 'react-slugify';

import ImageCropModal from '../Modal/ImageCropModal';
import AlertModal from '../Modal/AlertModal';

export default function ImageDropzone(props) {
    const [fileCrop, setFileCrop] = useState(null);
    const [showLoad, setShowLoad] = useState(false);
    const [alertModal, setAlertModal] = useState(null);

    const onDrop = useCallback(acceptedFiles => {
        setShowLoad(true);

        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
      
            reader.onabort = () => {
                setShowLoad(false);
                setAlertModal({
                    title: "Envio cancelado",
                    type: "info",
                    message: "O envio das imagens foi cancelado."
                });
            };
            reader.onerror = () => {
                setShowLoad(false);
                setAlertModal({
                    title: "Erro no envio",
                    type: "error",
                    message: "Não foi possível fazer o upload das imagens. Verifique o formato do arquivo e tente novamente."
                });
            };

            reader.onload = () => {
                setShowLoad(false);

                let extension = file.name.split(".")[file.name.split(".").length - 1];
                let filename = slugify(file.name.replace("."+extension, ""));

                setFileCrop({
                    base64: reader.result,
                    file: file,
                    props: {...{
                        filename: filename,
                        extension: extension
                    }, ...(props.imageProps || {})}
                })
            }

            reader.readAsDataURL(file);
          })
    }, [])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    const renderAlertModal = () => {
        if(alertModal){
            return <AlertModal
                handleClose={() => {setAlertModal(null)}}
                title={alertModal.title}
                type={alertModal.type}
                message={alertModal.message}
            />
        }
    }

    const renderLoad = () => {
        if(showLoad){
            return (
                <Dimmer active>
                    <Loader indeterminate>Carregando arquivos</Loader>
                </Dimmer>
            )
        }
    }
    const renderDropLayout = () => {
        if(!isDragActive){
            return (
                <Segment placeholder>
                    {renderLoad()}

                    <Header icon>
                        <Icon name='images outline'/>
                        Arraste a imagem ou clique no botão
                    </Header>
                    <Button primary onClick={e => {e.preventDefault()}}>Adicionar foto</Button>
                </Segment>
            )
        }
        return (
            <Segment placeholder>
                <Header icon>
                    <Icon name='images outline'/>
                    Solte o arquivo...
                </Header>
            </Segment>
        )
    }

    const renderCropModal = () => {
        if(fileCrop){
            return <ImageCropModal {...props} image={fileCrop} cancel={() => {setFileCrop(null)}}/>
        }
    }

    return (
        <React.Fragment>
            {renderAlertModal()}
            {renderCropModal()}

            <div {...getRootProps()} style={{margin: '10px', border:'1px solid gray'}}>
                <input {...getInputProps()} />
    
                {renderDropLayout()}
            </div>
        </React.Fragment>
    )
}
