import ApiCommerce from '../Api/ApiCommerce';

import { DEFAULT_TABLE_CONF } from '../Context/GlobalContext';
import { generateQueryString } from '../Utils/FunctionsUtils';

export const LIST = 'LIST_GENERIC';
export const CREATE = 'CREATE_GENERIC';
export const READ = 'READ_GENERIC';
export const UPDATE = 'UPDATE_GENERIC';
export const DELETE = 'DELETE_GENERIC';

export const List = (dispatch, path, query) => {
    const queryParams = {
        ...DEFAULT_TABLE_CONF,
        ...query
    }

    dispatch({ type: LIST, payload: {path: path, query: queryParams }});
    return new ApiCommerce().get(`${path}/${generateQueryString(queryParams)}&${(new URLSearchParams(queryParams)).toString()}`);
}

export const Create = (dispatch, path, formValues) => {
    dispatch({ type: CREATE, payload: { path: path, formValues: formValues }});
    return new ApiCommerce().post(`${path}/`, formValues);
}

export const Read = (dispatch, path, id, queryParams) => {
    dispatch({ type: READ, payload: { path: path, id: id } });
    return new ApiCommerce().get(`${path}/${id}/${generateQueryString(queryParams)}&${(new URLSearchParams(queryParams)).toString()}`);
}

export const Update = (dispatch, path, id, formValues) => {
    dispatch({ type: UPDATE, payload: { path: path, id: id, formValues: formValues }});
    return new ApiCommerce().patch(`${path}/${id}/`, formValues);
}

export const Delete = (dispatch, path, id, formValues) => {
    dispatch({ type: DELETE, payload: { id: id }});
    return new ApiCommerce().delete(`${path}/${id}/`, formValues);
}
