import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { List } from 'semantic-ui-react';

import { Read as ReadCreditCard } from '../../../../Actions/CreditCard';
import { Read as ReadAddress } from '../../../../Actions/Address';

import { normalizeZipcode } from '../../../../Utils/NormalizeUtils';

export default function CreditCardInfo(props) {
    const creditCards = useSelector(state => state.creditCards);
    const addresses = useSelector(state => state.addresses);

    const dispatch = useDispatch();

    useEffect(() => {
        if(props.initialValues.creditCard && !creditCards[props.initialValues.creditCard]){
            dispatch(ReadCreditCard(props.initialValues.creditCard))
        }

        if(props.initialValues.billingAddress && !addresses[props.initialValues.billingAddress]){
            dispatch(ReadAddress(props.initialValues.billingAddress))
        }    
    }, []);

    const creditCardMap = {
        VISA: {
            name: 'Visa',
            icon: 'cc visa',
        },
        MASTERCARD: {
            name: 'Mastercard',
            icon: 'cc mastercard',
        },
        AMEX: {
            name: 'American Express',
            icon: 'cc mastercard',
        }
    }

    const getBillingAddressInfo = () => {
        if(addresses[props.initialValues.billingAddress]){
            const address = addresses[props.initialValues.billingAddress];

            return (
                <List.Item>
                    <List.Content>
                        <List.Header>Endereço de Faturamento</List.Header>
                        <List.Description>
                            {address.street}, {address.number} {address.complement}, {address.neighborhood}
                            <br />
                            {normalizeZipcode(address.zipcode)}
                            <br />
                            {address.city} / {address.state}
                        </List.Description>
                    </List.Content>
                </List.Item>
            )
        }
        return null;
    }

    if(props.initialValues.creditCard && creditCards[props.initialValues.creditCard]){
        return (
            <List relaxed>
                <List.Item>
                    <List.Content floated='right'>
                        Cartão de Crédito
                    </List.Content>
    
                    <List.Content>
                        <List.Header>Tipo</List.Header>
                    </List.Content>
                </List.Item>

                <List.Item>
                    <List.Content floated='right'>
                        <List.Icon name={creditCardMap[creditCards[props.initialValues.creditCard].brand].icon} />
                        {creditCardMap[creditCards[props.initialValues.creditCard].brand].name}
                    </List.Content>

                    <List.Content>
                        <List.Header>Bandeira</List.Header>
                    </List.Content>
                </List.Item>

                <List.Item>
                    <List.Content floated='right'>
                        x{props.initialValues.installments}
                    </List.Content>

                    <List.Content>
                        <List.Header>Parcelas</List.Header>
                    </List.Content>
                </List.Item>

                <List.Item>
                    <List.Content floated='right'>
                        R$ {(props.initialValues.total/props.initialValues.installments).toFixed(2)}
                    </List.Content>

                    <List.Content>
                        <List.Header>Valor das Parcelas</List.Header>
                    </List.Content>
                </List.Item>

                {getBillingAddressInfo()}
            </List>
        )
    }

    return null;
}
