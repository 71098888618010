import _ from 'lodash'

import React from 'react';

import { Form, Select } from 'semantic-ui-react'

import { useEffect } from 'react';

import { useSelector, useDispatch } from "react-redux";
import { List } from '../../Actions/Category'

import { DEFAULT_TABLE_CONF } from '../../Context/GlobalContext';
import { getInputError } from '../../Utils/FunctionsUtils';

export default function SelectCategory(props) {
    const { input, label, meta } = props;
    const selectorKey = props.selectorKey || 'uuid';

    const entityList = useSelector(state => state.categoryList);

    const dispatch = useDispatch();

    useEffect(() => {
        if(!entityList || !entityList.results || entityList.count > entityList.results.length){
            const queryString = {
                ...DEFAULT_TABLE_CONF,
                ...{
                    rowsPerPage: 9999,
                    orderBy: 'name',
                    expand: true,
                    fields: ['uuid', 'name_select', 'categories', selectorKey]
                }
            }
            dispatch(List(queryString));
        }
    }, []);

    const getFlatCategories = (catArray) => {
        let flatCategories = []

        catArray.forEach(cat => {
            flatCategories.push(cat);

            if(cat.categories.length > 0){
                flatCategories = _.concat(flatCategories, getFlatCategories(cat.categories))
            }
        })

        return flatCategories;
    }

    const getOptions = () => {
        let options = [];

        options.push({
            key: `entity-category-rows`,
            text: '--- Principal ---',
            value: null
        });

        getFlatCategories((entityList.results || [])).forEach((item) => {
            options.push({
                key: `${item.uuid}-entity-category-rows`,
                text: `${item.nameSelect}`,
                value: item[selectorKey]
            })
        })

        return options;        
    }

    return (
        <Form.Field
            {...input}
            control={Select}
            search
            searchInput={{ id: 'form-select-control-category' }}
            label={{ children: label, htmlFor: 'form-select-control-category' }}
            options={getOptions()}
            onChange={(param, data) => input.onChange(data.value)}
            onBlur={(param, data) => input.onBlur(data.value)}
            error={getInputError(meta)} />
    )
}
