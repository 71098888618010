import React from 'react';
import { Field } from 'redux-form';
import { Grid, Form, Header } from 'semantic-ui-react';
import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputString,
    renderInputTemplateSelect,
    renderInputWysiwyg
} from '../../../../Utils/RenderUtils';

export default function Tab06(props) {
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column width={4}>
                    <FormSection fluid color="green" title="Template">
                        <Form.Group widths='equal'>
                            <Field name="settings.public.template.name" label="Tema" component={renderInputTemplateSelect} />
                        </Form.Group>
                    </FormSection>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column width={16}>
                    <FormSection fluid color="blue" title="Color Scheme">
                        <Header size='small'>Primária</Header>
                        <Form.Group widths='equal'>
                            <Field name="settings.public.template.pallete.primary.main" label="Main" component={renderInputString} />
                            <Field name="settings.public.template.pallete.primary.light" label="Light" component={renderInputString} />
                            <Field name="settings.public.template.pallete.primary.dark" label="Dark" component={renderInputString} />
                            <Field name="settings.public.template.pallete.primary.contrastText" label="Text" component={renderInputString} />
                        </Form.Group>

                        <Header size='small'>Secundária</Header>
                        <Form.Group widths='equal'>
                            <Field name="settings.public.template.pallete.secondary.main" label="Main" component={renderInputString} />
                            <Field name="settings.public.template.pallete.secondary.light" label="Light" component={renderInputString} />
                            <Field name="settings.public.template.pallete.secondary.dark" label="Dark" component={renderInputString} />
                            <Field name="settings.public.template.pallete.secondary.contrastText" label="Text" component={renderInputString} />
                        </Form.Group>

                        <Header size='small'>Positive</Header>
                        <Form.Group widths='equal'>
                            <Field name="settings.public.template.pallete.positive.main" label="Main" component={renderInputString} />
                            <Field name="settings.public.template.pallete.positive.light" label="Light" component={renderInputString} />
                            <Field name="settings.public.template.pallete.positive.dark" label="Dark" component={renderInputString} />
                            <Field name="settings.public.template.pallete.positive.contrastText" label="Text" component={renderInputString} />
                        </Form.Group>

                        <Header size='small'>Negative</Header>
                        <Form.Group widths='equal'>
                            <Field name="settings.public.template.pallete.negative.main" label="Main" component={renderInputString} />
                            <Field name="settings.public.template.pallete.negative.light" label="Light" component={renderInputString} />
                            <Field name="settings.public.template.pallete.negative.dark" label="Dark" component={renderInputString} />
                            <Field name="settings.public.template.pallete.negative.contrastText" label="Text" component={renderInputString} />
                        </Form.Group>
                    </FormSection>
                </Grid.Column>
            </Grid.Row>

            <Grid.Row>
                <Grid.Column width={16}>
                    <FormSection fluid color="blue" title="Informações da página">
                        <Form.Group widths='equal'>
                            <Field name="settings.public.template.info.footer" label="Rodapé" component={renderInputWysiwyg} />
                        </Form.Group>
                    </FormSection>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}
