import axios from 'axios';
import jwt_decode from "jwt-decode";

import * as Constants from '../Constants';

export default class ApiCommerce {
    async getAuthHeader(){
        if(localStorage.getItem('accessToken')){
            let accessToken = localStorage.getItem('accessToken');
        
            let expDate = new Date(jwt_decode(accessToken).exp * 1000);
            if(expDate < new Date()){
                await axios.post(`${Constants.API_URL}/auth/refresh/`, {
                    refresh: localStorage.getItem('refreshToken')
                }).then(
                    (response) => {
                        accessToken = response.data.access;
                        localStorage.setItem('accessToken', accessToken);
                    }
                ).catch(
                    (error) => {
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('refreshToken');
                        console.log(error);
                    }
                )
            }
    
            return { 'Authorization': `Bearer ${accessToken}` };
        }
    
        return {};
    }

    async getAxios(){
        return axios.create({
            baseURL: Constants.API_URL,
            headers: await this.getAuthHeader(),
        });
    }

    get(URI){
        return this.getAxios().then(a => {return a.get(URI)});
    }

    post(URI, data){
        return this.getAxios().then(a => {return a.post(URI, data)});
    }

    put(URI, data){
        return this.getAxios().then(a => {return a.put(URI, data)});
    }

    patch(URI, data){
        return this.getAxios().then(a => {return a.patch(URI, data)});
    }

    delete(URI, data){
        return this.getAxios().then(a => {return a.delete(URI, {data: data})});
    }

}