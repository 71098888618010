import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { List, Divider, Button} from 'semantic-ui-react';

import { Read as ReadCustomer } from '../../../../Actions/Customer';

import { normalizeDocument } from '../../../../Utils/NormalizeUtils';
import { datetimeIsoToPtBr } from '../../../../Utils/FunctionsUtils';

export default function CustomerInfo(props) {
    const customers = useSelector(state => state.customers);

    const dispatch = useDispatch();

    useEffect(() => {
        if(props.initialValues.customer && !customers[props.initialValues.customer]){
            dispatch(ReadCustomer(props.initialValues.customer))
        }
    }, []);

    const genderList = {
        MALE: 'Masculino',
        FEMALE: 'Feminino',
        OTHER: 'Outro'
    }

    if(customers[props.initialValues.customer]){
        return (
            <List relaxed>
                <List.Item>
                    <List.Content floated='right'>
                        {customers[props.initialValues.customer].firstName+' '+(customers[props.initialValues.customer].lastName || '')}
                    </List.Content>
    
                    <List.Content>
                        <List.Header>Nome</List.Header>
                    </List.Content>
                </List.Item>

                <List.Item>
                    <List.Content floated='right'>
                        {customers[props.initialValues.customer].email}
                    </List.Content>

                    <List.Content>
                        <List.Header>Email</List.Header>
                    </List.Content>
                </List.Item>

                <List.Item>
                    <List.Content floated='right'>
                        {normalizeDocument(customers[props.initialValues.customer].document, null, {entity: customers[props.initialValues.customer].entity})}
                    </List.Content>

                    <List.Content>
                        <List.Header>CPF / CNPJ</List.Header>
                    </List.Content>
                </List.Item>

                <List.Item>
                    <List.Content floated='right'>
                        { datetimeIsoToPtBr(customers[props.initialValues.customer].birthdate) }
                    </List.Content>

                    <List.Content>
                        <List.Header>Nascimento</List.Header>
                    </List.Content>
                </List.Item>

                <List.Item>
                    <List.Content floated='right'>
                        { genderList[customers[props.initialValues.customer].gender] }
                    </List.Content>

                    <List.Content>
                        <List.Header>Sexo</List.Header>
                    </List.Content>
                </List.Item>

                <Divider />

                <Link to={`/cliente/${props.initialValues.customer}`}>
                    <div className='ui two buttons'>
                        <Button basic color='green'>
                            Ver Cliente
                        </Button>
                    </div>
                </Link>
            </List>
        )
    }

    return null;
}
