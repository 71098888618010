import React from 'react';
import { Form, Radio } from 'semantic-ui-react'
import { getInputError } from '../../Utils/FunctionsUtils';

export default function InputEntity(props) {
    const { input, label, meta } = props;

    return (
        <Form.Group inline style={props.style || {marginTop: '32px', paddingLeft: '8px'}} {...input} error={getInputError(meta)}>
            <label>{label}</label>

            <Form.Field
                control={Radio}
                label='Fisica'
                value='PHYSICAL'
                checked={input.value === 'PHYSICAL'}
                onFocus={(e) => { e.target.blur() }}
            />
            <Form.Field
                control={Radio}
                label='Jurídica'
                value='JURIDICAL'
                checked={input.value === 'JURIDICAL'}
                onFocus={(e) => { e.target.blur() }}
            />
        </Form.Group>
    )
}
