import React from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputString,
    renderInputPassword,
    renderMultiSelectMerchant
} from '../../../../Utils/RenderUtils';

export default function Tab01(props) {

    return (
        <React.Fragment>
            <FormSection fluid color="green" title="Informações de Acesso">
                <Form.Group widths='equal'>
                    <Field name="merchants" label="Lojas" component={renderMultiSelectMerchant} />
                    <Field name="email" label="E-mail" component={renderInputString} />
                    <Field name="name" label="Nome" component={renderInputString} />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Field name="password" label="Senha" component={renderInputPassword} />
                    <Field name="passwordConfirmation" label="Confirmação de Senha" component={renderInputPassword} />
                </Form.Group>
            </FormSection>
        </React.Fragment>
    )
}
