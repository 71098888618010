import React from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputString,
    renderInputCheckbox
} from '../../../../Utils/RenderUtils';

import {
    normalizeCellphone
} from '../../../../Utils/NormalizeUtils';

export default function Tab01(props) {
    return (
        <React.Fragment>
            <FormSection fluid color="green" title="Dados de Contato">
                <Form.Group widths={3}>
                    <Field name="active" label="Ativo" component={renderInputCheckbox} type="checkbox" />
                </Form.Group>
                <Form.Group widths='equal'>
                    <Field name="name" label="Nome" component={renderInputString} />
                    <Field name="email" label="E-mail" component={renderInputString} />
                    <Field name="mobile" label="Telefone/Celular" component={renderInputString} normalize={normalizeCellphone} format={normalizeCellphone} />
                </Form.Group>
            </FormSection>
        </React.Fragment>
    )
}
