import React from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';
import slugify from 'react-slugify';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputString,
    renderSelectMerchant
} from '../../../../Utils/RenderUtils';

import {
    normalizeSlug
} from '../../../../Utils/NormalizeUtils';

export default function Tab01(props) {
    return (
        <FormSection fluid color="green" title="Informações Gerais">
            <Form.Group widths='equal'>
                <Field name="name" label="Nome" component={renderInputString} onChange={(e) => props.change('slug', slugify(e.target.value))} />
                <Field name="slug" label="URL" component={renderInputString} normalize={normalizeSlug} />
                <Field name="merchant" label="Loja" component={renderSelectMerchant}/>
            </Form.Group>
        </FormSection>
    )
}
