import _ from 'lodash';

import React from 'react';

import { Form, Dropdown, Input } from 'semantic-ui-react'

import { useEffect, useState } from 'react';

import { useSelector, useDispatch } from "react-redux";
import { List } from '../../Actions/Category'

export default function MultiSelectCategory(props) {
    const { input, label, meta } = props;

    const entityList = useSelector(state => state.categoryList);
    const [oldValue, setOldValue] = useState(input.value);
    const [options, setOptions] = useState([]);
    const [optionsDict, setOptionsDict] = useState({});

    const dispatch = useDispatch();

    useEffect(() => {
        if(!entityList || !entityList.results || entityList.count > entityList.results.length){
            dispatch(List({ rowsPerPage: 9999, orderBy: 'name', fields: ['uuid', 'categories', 'name_select'] }));
        }
    }, []);

    const getListDownTree = (results) => {
        let listDown = [];

        results.forEach((item) => {
            listDown.push(item);

            if(item.categories){
                listDown = listDown.concat(getListDownTree(item.categories))
            }
        });

        return listDown;
    }

    useEffect(() => {
        if(entityList.results){
            let listDownTree = getListDownTree(entityList.results);

            setOptionsDict(_.mapKeys(listDownTree, 'uuid'));

            let optionsArray = [];
            listDownTree.forEach((item) => {
                optionsArray.push({
                    key: `${item.uuid}-entity-category-rows`,
                    text: `${item.nameSelect}`,
                    value: item.uuid
                })
            })
            setOptions(optionsArray);
        }
    }, [entityList]);

    const complementValue = (data) => {
        let deleted = _.difference(oldValue, data.value);

        deleted.forEach((uuid) => {
            _.remove(data.value, function(n) { return optionsDict[uuid].uuidChildList.includes(n) ;});
        });

        data.value.forEach((uuid) => {
            optionsDict[uuid].uuidParentList.forEach((newUuid) => {
                if(!data.value.includes(newUuid)){
                    data.value.push(newUuid);
                }
            })
        });

        setOldValue(data.value);
        return data.value;
    }

    const onChange = (param, data) => {
        input.onChange(complementValue(data));
    }

    const onBlur = (param, data) => {
        input.onBlur(complementValue(data));
    }

    if(entityList && entityList.results && entityList.count === entityList.results.length){
        return (
            <Form.Field>
                <label>{label}</label>

                <Dropdown
                    {...input}
                    fluid
                    multiple
                    search
                    selection
                    onChange={(param, data) => onChange(param, data)}
                    onBlur={(param, data) => onBlur(param, data)}
                    options={options}
                />
            </Form.Field>
        )
    } else {
        return (<Input fluid loading disabled placeholder='Carregando...' />)
    }
}
