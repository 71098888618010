import React, { useContext } from 'react';
import GlobalContext from '../../Context/GlobalContext';

import { Table, Pagination, Form } from 'semantic-ui-react';

export default function DefaultTableFooter(props) {    
    const { head, body } = props;

    const context = useContext(GlobalContext);

    const onChangePage = (event, { activePage }) => {
        context.change({
            table: {
                pageSelected: activePage
            }
        });
    }

    const onChangeRowsPerPage = (event, { value }) => {
        context.change({
            table: {
                pageSelected: 1,
                rowsPerPage: parseInt(value, 10)
            }
        });
    }

    const getOptionsRowsPerPage = () => {
        let options = [];
        
        (context.table.rowsPerPageOptions || []).forEach((item) => {
            options.push({
                key: `${item}-rows`,
                text: `${item}`,
                value: item
            })
        })

        return options;
    }
        
    const renderPagination = () => {
        if(body.pages > 1){
            return (
                <Pagination
                    boundaryRange={1}
                    siblingRange={2}
                    activePage={context.table.pageSelected}
                    totalPages={body.pages}
                    onPageChange={onChangePage}
                    prevItem={false}
                    nextItem={false}
                    firstItem={false}
                    lastItem={false}/>
            )
        }
    }

    return (
        <Table.Footer fullWidth>
            <Table.Row>
                <Table.HeaderCell />
                <Table.HeaderCell colSpan={head.length + 1}>
                    <Form floated='right' style={{float: 'right'}}>
                        <Form.Group inline>
                            <Form.Dropdown
                                options={getOptionsRowsPerPage()}
                                value={context.table.rowsPerPage}
                                onChange={onChangeRowsPerPage}
                                label={`${props.verboseNamePlural} por página`}
                                selection/>

                            {renderPagination()}
                        </Form.Group>
                    </Form>
                </Table.HeaderCell>
            </Table.Row>
        </Table.Footer>
    )
}
