import React from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputString,
    renderInputCheckbox,
    renderInputWysiwyg,
    renderInputDateTimePicker,
    renderInputVoucherKeys,
    renderSelectMerchant
} from '../../../../Utils/RenderUtils';

export default function Tab01() {
    return (
        <React.Fragment>
            <FormSection fluid color="blue" title="Informações Gerais">
                <Form.Group widths={4}>
                    <Field name="active" label="Ativo" component={renderInputCheckbox} type="checkbox" />
                    <Field disabled name="code" label="Código" component={renderInputString} />
                    <Field name="initDate" label="Data de Início" component={renderInputDateTimePicker} />
                    <Field name="endDate" label="Data de Encerramento" component={renderInputDateTimePicker} />
                </Form.Group>

                <Form.Group widths={16}>
                    <Field name="name" label="Nome" component={renderInputString} width={6}/>
                    <Field disabled name="keys" label="Tipo" component={renderInputVoucherKeys} />
                    <Field name="merchant" label="Loja" component={renderSelectMerchant} />
                </Form.Group>

                <Form.Group widths='equal'>
                    <Field name="description" label="Descrição" component={renderInputWysiwyg} />
                </Form.Group>
            </FormSection>
        </React.Fragment>
    )
}
