export const tableConf = {
    searchFields: ['id', 'name', 'email', 'mobile']
}

export const tableHeader = [
    {
        field: 'id',
        label: 'ID',
        dataType: 'integer',
        sortable: true
    },
    {
        field: 'active',
        label: 'Ativo',
        dataType: 'boolean',
        sortable: true
    },
    {
        field: 'modifiedAt',
        label: 'Data de Alteração',
        dataType: 'datetime',
        sortable: true
    },
    {
        field: 'name',
        label: 'Nome',
        sortable: true
    },
    {
        field: 'email',
        label: 'E-mail',
        sortable: true
    },
];
