import React, { useState } from 'react';

import { List as GenericList } from '../Generic/List';
import CategoryTable from '../../Components/Table/CategoryTable';

import {
    entityListReducer,
    verboseName,
    verboseNamePlural,
    icon,
    editPath,
    tableHeader,
    ListTable,
    tableConf,
    mountCreatePanes,
    createFormName,
    ValidateCreate,
    createInitialValues,
    Delete,
    Create,
    displayPropertyName,
    permission
} from './Config'

import './style.css';

export default function List(props) {
    const [mainCategorySelected, setMainCategorySelected] = useState(null);

    const initialValues = {...createInitialValues, ...{mainCategory: mainCategorySelected}}

    const renderCategoryTable = (p, body) => {
        return <CategoryTable {...p} body={body} setMainCategorySelected={setMainCategorySelected} />
    }

    return (
        <GenericList
            {...props}

            ListTable={ListTable}
            Create={Create}
            Delete={Delete}

            verboseName={verboseName}
            verboseNamePlural={verboseNamePlural}
            icon={icon}
            head={tableHeader}
            editPath={editPath}
            displayPropertyName={displayPropertyName}

            mountCreatePanes={mountCreatePanes}

            entityListReducer={entityListReducer}
            tableConf={tableConf}

            form={createFormName}
            validate={ValidateCreate}
            initialValues={initialValues}
            renderTableComponent={renderCategoryTable}
            listMaxSize={9999}

            permission={permission}
        />
    )
}
