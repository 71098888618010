export const ValidateCreate = (formValues) => {
    const errors = {};

    if(!formValues.code){
        errors.code = 'Esse campo é obrigatório'
    }

    if(!formValues.name){
        errors.name = 'Esse campo é obrigatório'
    }

    if(!formValues.slug){
        errors.slug = 'Esse campo é obrigatório'
    }

    if(!formValues.weight){
        errors.weight = 'Esse campo é obrigatório'
    }

    if(!formValues.height){
        errors.height = 'Esse campo é obrigatório'
    }

    if(!formValues.width){
        errors.width = 'Esse campo é obrigatório'
    }

    if(!formValues.length){
        errors.length = 'Esse campo é obrigatório'
    }

    if(!formValues.prices || !formValues.prices[0].price){
        errors.prices = [];
        errors.prices[0] = {};
        errors.prices[0].price = 'Esse campo é obrigatório'
    }

    if(!formValues.merchant){
        errors.merchant = 'Selecione a loja desse produto'
    }

    return errors;
}

export const ValidateUpdate = (formValues) => {
    return ValidateCreate(formValues);
}
