import React, {useContext} from 'react';
import { useDispatch } from "react-redux";

import GlobalContext from '../../Context/GlobalContext';

import DeleteConfirmationModal from '../../Components/Modal/DeleteConfirmationModal';

async function asyncDeleteList(context, dispatch, Delete){
    let awaitList = [];

    context.table.deleteList.forEach((item) => {
        awaitList.push(dispatch(Delete(item.uuid)));
    });

    return await Promise.all(awaitList);
}

export default function FormDelete(props) {
    const { icon, verboseNamePlural, displayPropertyName, Delete } = props;

    const context = useContext(GlobalContext);
    const dispatch = useDispatch();

    const onCancel = (newContext) => {
        const revertContext = newContext || {
            deleteModalOpen: false,
            table: {
                deleteList: context.table.deleteList.length > 1 ? context.table.deleteList : []
            }
        }

        context.change(revertContext);
    }

    const onDelete = () => {
        asyncDeleteList(context, dispatch, Delete).then((response) => {
            onCancel({
                deleteModalOpen: false,
                table: {
                    deleteList: [],
                }
            })
        })
    }

    const deleteModalText = () => {
        return context.table.deleteList.length === 1 ? `Você tem certeza que deseja remover o item "${context.table.deleteList[0][displayPropertyName]}"?` : `Você tem certeza que deseja remover os (${context.table.deleteList.length}) itens selecionados?`;
    }
    
    return (
        <DeleteConfirmationModal
            icon={icon}
            title={`Removendo ${verboseNamePlural.toLowerCase()}`}
            text={deleteModalText()}
            onDelete={props.onDelete || onDelete}
            onCancel={props.onCancel || onCancel} />
    )
}
