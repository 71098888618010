export { List as ListTable, Create, Read, Update, Delete } from '../../../Actions/Customer';

export * from './List';
export * from './Edit';
export * from './Create';
export * from './Validation';

export const entityReducer = 'customers';
export const entityListReducer = 'customerList';

export const verboseName = 'Cliente'
export const verboseNamePlural = 'Clientes'
export const icon = 'user'
export const displayPropertyName = 'firstName';

export const editPath = '/cliente'
export const listPath = '/clientes'

export const createFormName = 'customerCreate';
export const editFormName = 'customerUpdate';

export const permission = 'customer';