import React from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputString,
    renderSelectMerchant,
    renderInputCheckbox,
    renderInputWysiwyg,
    renderInputDateTimePicker,
    renderInputText,
    renderSelectManufacturer,
    renderMultiSelectCategory,
    renderInputCheckboxKey
} from '../../../../Utils/RenderUtils';

import {
    normalizeSlug
} from '../../../../Utils/NormalizeUtils';

export default function Tab01() {
    return (
        <React.Fragment>
            <FormSection fluid color="blue" title="Códigos">
                <Form.Group widths={4}>
                    <Field name="active" label="Ativo" component={renderInputCheckbox} type="checkbox" />
                    <Field name="releaseDate" label="Data de Lançamento" component={renderInputDateTimePicker} />
                    <Field name="keys" label="Esconder da Listagem?" keyValue="HIDE_LIST" component={renderInputCheckboxKey} type="checkbox" />
                    <Field name="keys" label="Esconder da Busca?" keyValue="HIDE_SEARCH" component={renderInputCheckboxKey} type="checkbox" />
                </Form.Group>

                <Form.Group widths='equal'>
                    <Field name="code" label="SKU" component={renderInputString} />
                    <Field name="manufacturerCode" label="SKU Fabricante" component={renderInputString} />
                    <Field name="externalId" label="Código Externo (ERP)" component={renderInputString} />
                    <Field name="barcode" label="Código de Barras (EAN13)" component={renderInputString} />
                </Form.Group>
            </FormSection>

            <FormSection fluid color="green" title="Informações Gerais">
                <Form.Group widths='equal'>
                    <Field name="name" label="Nome Completo" component={renderInputString} />
                    <Field name="slug" label="URL" component={renderInputString} normalize={normalizeSlug} />
                    <Field name="fields.shortName" label="Nome Curto" component={renderInputString} />
                </Form.Group>

                <Form.Group widths='equal'>
                    <Field name="merchant" label="Loja" component={renderSelectMerchant} />
                    <Field name="manufacturer" label="Fabricante" component={renderSelectManufacturer} />
                </Form.Group>

                <Form.Group widths='equal'>
                    <Field name="categories" label="Categorias" component={renderMultiSelectCategory} />
                </Form.Group>

            </FormSection>

            <FormSection fluid color="yellow" title="Descrições">
                <Form.Group widths='equal'>
                    <Field name="description" label="Descrição Completa" component={renderInputWysiwyg} />
                </Form.Group>

                <Form.Group widths={2}>
                    <Field name="fields.shortDescription" label="Descrição Simplificada" component={renderInputText} />
                </Form.Group>
            </FormSection>
        </React.Fragment>
    )
}
