export const tableConf = {
    searchFields: ['id', 'name', 'merchant__trading_name']
}

export const tableHeader = [
    {
        field: 'id',
        label: 'ID',
        dataType: 'integer',
        sortable: true
    },
    {
        field: 'active',
        label: 'Ativo',
        dataType: 'boolean',
        sortable: true
    },
    {
        field: 'createdAt',
        label: 'Data de Criação',
        dataType: 'datetime',
        sortable: true
    },
    {
        field: 'name',
        label: 'Nome',
        sortable: true
    },
    {
        field: 'merchant',
        label: 'Lojista',
        dataType: 'merchant',
        sortable: true,
        sortQuery: 'merchant__trading_name'
    }
];
