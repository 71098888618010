import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { change } from 'redux-form';

import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import { Label} from 'semantic-ui-react'

import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const toolbar = {
    options: ['inline', 'fontSize', 'list', 'textAlign', 'colorPicker', 'link', 'embedded']
}

export default function InputWysiwyg(props) {
    const { input, label, meta } = props;
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const dispatch = useDispatch();

    useEffect(() => {
        if(input.value){
            const contentState = ContentState.createFromBlockArray(htmlToDraft(input.value))
            setEditorState(EditorState.createWithContent(contentState))
        }
    }, []);

    const setFormContent = (es) => {
        setEditorState(es);

        const htmlValue = draftToHtml(convertToRaw(es.getCurrentContent()));
        dispatch(change(meta.form, input.name, htmlValue));
    }

    return (
        <div style={{width: '100%'}}>
            <Label basic style={{fontSize: '.92857143em', border: 'none'}}>{label}</Label>
            <Editor
                wrapperStyle={{
                    border: '1px solid rgba(34,36,38,.15)',
                    padding: '8px',
                    margin: '0 8px',
                    borderRadius: '.28571429rem'
                }}
                editorState={editorState}
                toolbar={toolbar}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={(es) => setFormContent(es)}
            />
        </div>
    )
}
