import Tab01 from '../Components/UpdateForm/Tab01';
import Tab02 from '../Components/UpdateForm/Tab02';
import Tab03 from '../Components/UpdateForm/Tab03';
import Tab04 from '../Components/UpdateForm/Tab04';
import Tab05 from '../Components/UpdateForm/Tab05';

export const preFilter = (formValues, initialValues, dispatch) => {
    if(formValues && formValues.codes){
        formValues.codes.forEach(code => {
            if(code.new){
                delete code.new;
                delete code.code;
            }
        })
    }

    return formValues;
};

export const preDispatch = null;

export const mountEditPanes = (props) => {
    return [
        {
            menuItem: { key: 'general', icon: 'info', content: 'Informações Gerais' },
            render: () => <Tab01 {...props} />
        },
        {
            menuItem: { key: 'discount', icon: 'dollar', content: 'Descontos e Brindes' },
            render: () => <Tab02 {...props} />
        },
        {
            menuItem: { key: 'signing', icon: 'signing', content: 'Regras de Aplicação' },
            render: () => <Tab03 {...props} />
        },
        {
            menuItem: { key: 'images', icon: 'images', content: 'Fotos' },
            render: () => <Tab04 {...props} />
        },
        {
            menuItem: { key: 'qrcode', icon: 'qrcode', content: 'Códigos' },
            render: () => <Tab05 {...props} />
        }
    ]
}