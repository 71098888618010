import _ from 'lodash';

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";

import { Grid, Button, List } from 'semantic-ui-react';

import { Read as ReadImage, Use as UseImage } from '../../../../Actions/Image';
import FormSection from '../../../../Components/Card/FormSection';
import ImageCard from '../../../../Components/Card/ImageCard';
import ImageDropzone from '../../../../Components/Input/ImageDropzone';
import ImageListItem from '../../../../Components/Entity/ImageListItem';

export default function Tab02(props) {
    const formValues = useSelector(state => state.form[props.form].values);
    const images = useSelector(state => state.images);
    const newImage = useSelector(state => state.imagesNew);

    const dispatch = useDispatch();

    useEffect(() => {
        formValues.images.forEach(imageUuid => {
            if(!images[imageUuid]){
                dispatch(ReadImage(imageUuid));
            }
        })
    }, [formValues.images]);

    useEffect(() => {
        if(newImage){
            let newImages = Object.assign([], formValues.images)

            _.keys(newImage).forEach((key) => {
                newImages.push(key);
                dispatch(UseImage(key));
            });
            
            props.change('images', newImages);
        }
    }, [newImage]);

    const removeImage = imageUuid => {
        props.change('images', _.remove(formValues.images, function(n) { return n !== imageUuid }));
    }

    const getImageCard = (tag, imageWidth, imageHeight) => {
        const imageProps = {
            tags: [tag]
        }

        let imageSelectedUuid = null;

        formValues.images.forEach(imageUuid => {
            if(images[imageUuid] && images[imageUuid].tags.includes(tag)){
                imageSelectedUuid = imageUuid;
            }
        })

        const buttons = (
            <div className='ui two buttons'>
                <Button basic color='red' onClick={(()=>{removeImage(imageSelectedUuid)})}>
                    Remover/Alterar Imagem
                </Button>
            </div>
        )

        return <ImageCard
                uuid={imageSelectedUuid}
                extra={buttons}
                imageWidth={imageWidth}
                imageHeight={imageHeight}
                imageProps={imageProps}/>
    }

    const getImageCardLogo = () => {
        return getImageCard('LOGO', 1000, 400);
    }

    const changeImagePosition = (imageUuid, shift) => {
        let newImages = Object.assign([], formValues.images);

        let index = newImages.indexOf(imageUuid);
        shift = index + shift;

        if(shift >= 0 && shift < formValues.images.length){
            let shiftUuid = newImages[shift];
            newImages[shift] = imageUuid;
            newImages[index] = shiftUuid;
    
            props.change('images', newImages);
        }
    }

    return (
        <Grid columns={'equal'} style={{margin: '10px 0px'}}>
            <Grid.Column>
                <FormSection fluid color="green" title="Logo">
                        {getImageCardLogo()}
                </FormSection>
            </Grid.Column>

            <Grid.Column width={8}>
                <FormSection fluid color="blue" title="Favicon">
                        {getImageCard('FAVICON', 50, 50)}
                </FormSection>
            </Grid.Column>
            
            <Grid.Column width={8}>
                <FormSection fluid color="yellow" title="Cartões Aceitos">
                    <ImageDropzone imageWidth={256} imageHeight={256} imageProps={{tags: ['CREDIT_CARD']}} />

                    <List ordered>
                        {
                            formValues.images.map((imageUuid) => {
                                return <ImageListItem
                                            key={`image-${imageUuid}`}
                                            uuid={imageUuid}
                                            tag='CREDIT_CARD'
                                            shiftUpAction={() => {changeImagePosition(imageUuid, -1)}}
                                            shiftDownAction={() => {changeImagePosition(imageUuid, 1)}}
                                            removeAction={() => {removeImage(imageUuid)}} />
                            })
                        }
                    </List>
                </FormSection>
            </Grid.Column>

            <Grid.Column width={8}>
                <FormSection fluid color="red" title="Stickers">
                    <ImageDropzone imageWidth={256} imageHeight={256} imageProps={{tags: ['STICKER']}} />

                    <List ordered>
                        {
                            formValues.images.map((imageUuid) => {
                                return <ImageListItem
                                            key={`image-${imageUuid}`}
                                            uuid={imageUuid}
                                            tag='STICKER'
                                            shiftUpAction={() => {changeImagePosition(imageUuid, -1)}}
                                            shiftDownAction={() => {changeImagePosition(imageUuid, 1)}}
                                            removeAction={() => {removeImage(imageUuid)}} />
                            })
                        }
                    </List>
                </FormSection>
            </Grid.Column>
        </Grid>
    )
}
