import React from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputString,
    renderSelectMerchant,
    renderInputCheckbox,
    renderInputWysiwyg,
    renderInputText,
    renderSelectCategory,
    renderInputCheckboxKey
} from '../../../../Utils/RenderUtils';

import {
    normalizeSlug,
    normalizeInteger
} from '../../../../Utils/NormalizeUtils';

export default function Tab01() {
    return (
        <React.Fragment>
            <FormSection fluid color="blue" title="Códigos">
                <Form.Group widths={3}>
                    <Field name="active" label="Ativo" component={renderInputCheckbox} type="checkbox" />
                    <Field name="keys" label="Mostrar no Menu?" keyValue="DISPLAY_MENU" component={renderInputCheckboxKey} type="checkbox" />
                    <Field name="keys" label="Mostrar na Home?" keyValue="DISPLAY_HOME" component={renderInputCheckboxKey} type="checkbox" />
                </Form.Group>

                <Form.Group widths='equal'>
                    <Field name="externalId" label="Código Externo (ERP)" component={renderInputString} />
                    <Field name="sort" label="prioridade" component={renderInputString} normalize={normalizeInteger} />
                    <Field name="mainCategory" label="Categoria Principal" component={renderSelectCategory} />
                    <Field name="merchant" label="Loja" component={renderSelectMerchant} />
                </Form.Group>
            </FormSection>

            <FormSection fluid color="green" title="Informações Gerais">
                <Form.Group widths='equal'>
                    <Field name="name" label="Nome Completo" component={renderInputString} />
                    <Field name="fields.shortName" label="Nome Curto" component={renderInputString} />
                    <Field name="slug" label="URL" component={renderInputString} normalize={normalizeSlug} />
                </Form.Group>
            </FormSection>

            <FormSection fluid color="yellow" title="Descrições">
                <Form.Group widths='equal'>
                    <Field name="description" label="Descrição Completa" component={renderInputWysiwyg} />
                </Form.Group>

                <Form.Group widths={2}>
                    <Field name="fields.shortDescription" label="Descrição Simplificada" component={renderInputText} />
                </Form.Group>
            </FormSection>
        </React.Fragment>
    )
}
