import React, {useContext } from 'react';

import { Button, Icon, Header, Modal } from 'semantic-ui-react';

import GlobalContext from '../../Context/GlobalContext';

export default function DeleteConfirmationModal(props) {
    const { size, icon, title, text, onDelete, onCancel } = props;

    const context = useContext(GlobalContext);

    return (
        <Modal
            basic={true}
            open={context.deleteModalOpen || false}
            size={size}>

            <Header icon>
                <Icon name={icon} />
                {title}
            </Header>

            <Modal.Content>
                {text}
            </Modal.Content>

            <Modal.Actions>
                <Button inverted color='red' onClick={() => { onDelete(); }}>
                    <Icon name='remove' /> Excluir
                </Button>
                <Button inverted onClick={() => { onCancel(); }}>
                    Cancelar
                </Button>
            </Modal.Actions>

        </Modal>
    );
}