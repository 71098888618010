import React, { useContext } from 'react';
import GlobalContext from '../Context/GlobalContext';

import Navbar from '../Components/Menu/Navbar'
import Sidebar from '../Components/Menu/Sidebar'
import { Segment, Grid, Container } from 'semantic-ui-react'

export default function Body(props) {
    const context = useContext(GlobalContext);

    const sidebarMinWidth = '245px';

    const renderSidebar = () => {
        if(context.displaySidebar){
            return (
                <Grid.Column width={2} style={{paddingRight: '0', minWidth: sidebarMinWidth}}>
                    <Sidebar />
                </Grid.Column>
            )
        }
    }

    const renderBody = () => {
        let bodyConf = {
            columnWidth: 16,
            maxWidth: '100%'
        }

        if(context.displaySidebar){
            bodyConf.columnWidth = 14;
            bodyConf.maxWidth = `calc(100% - ${sidebarMinWidth})`;
        }

        return (
            <Grid.Column width={bodyConf.columnWidth} style={{maxWidth: bodyConf.maxWidth}}>
                <Navbar />

                <Container style={{margin: '25px auto'}}>
                    <Segment.Group style={{minHeight: '15vh'}}>
                        {props.children}
                    </Segment.Group>
                </Container>
            </Grid.Column>
        )
    }

    return (
        <Grid style={{height: '100%'}}>
            {renderSidebar()}
            {renderBody()}
        </Grid>
    );
}
