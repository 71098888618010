import _ from 'lodash';

export function generateQueryString(state={}){
    let queryString = '';

    queryString += `page=${state.pageSelected || '1'}`;
    queryString += `&pageSize=${state.rowsPerPage || '10'}`;
    queryString += `&sort=${state.order || ''}${state.orderBy || ''}`;
    queryString += `&search=${state.searchTerm || ''}`;

    if(state.display){
        queryString += `&display=${state.display}`;
    }

    (state.searchFields || [] ).forEach((item) => {
        queryString += `&searchField=${item}`;
    });

    return '?'+queryString;
}

export function getInputError(meta){
    if(meta.error && meta.touched){
        return meta.error;
    }
    return null;
}

export function datetimePtBrToIso(dateTimeString){
    try{
        let dateString = dateTimeString.split(' ')[0];
        let timeString = dateTimeString.split(' ')[1];
    
        return `${dateString.split('/')[2]}-${dateString.split('/')[1]}-${dateString.split('/')[0]}T${timeString.split(':')[0]}:${timeString.split(':')[1]}:00`
    }catch(e){
        return null;
    }
}

export function datetimeIsoToPtBr(dateTimeString){
    try{
        let dateString = dateTimeString.split('T')[0];
        let timeString = dateTimeString.split('T')[1];
    
        return `${dateString.split('-')[2]}/${dateString.split('-')[1]}/${dateString.split('-')[0]} ${timeString.split(':')[0]}:${timeString.split(':')[1]}`
    } catch(e){
        return null;
    }
}

export function datePtBrToIso(dateString){
    try{
        let datetimeString = datetimePtBrToIso(dateString+' 00:00');
        return datetimeString.split('T')[0];
    }catch(e){
        return null;
    }
}

export function dateIsoToPtBr(dateString){
    try{
        let datetimeString = datetimeIsoToPtBr(dateString+'T00:00');
        return datetimeString.split(' ')[0];
    } catch(e){
        return null;
    }
}

export function filterFormDifferences(newValues, initialValues){
    let filteredValues = Object.assign({}, newValues);

    // Clear null values from array
    Object.keys(filteredValues).forEach(function(key) {
        if(Array.isArray(filteredValues[key])){
            _.remove(filteredValues[key], (n) => {return !n});
        }
    });

    // Filter only altered keys
    filteredValues = _.pickBy(filteredValues, (value, key) => {
        if(value instanceof Array || value instanceof Object){
            return JSON.stringify(value) !== JSON.stringify(initialValues[key]);
        }

        if(datetimePtBrToIso(value) && !isNaN((new Date(datetimePtBrToIso(value))).getTime())){
            value = datetimePtBrToIso(value);
        }

        return value !== initialValues[key]
    });

    return filteredValues;
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}