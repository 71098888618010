export const tableConf = {
    searchFields: ['id', 'name', 'code']
}

export const tableHeader = [
    {
        field: 'id',
        label: 'ID',
        dataType: 'integer',
        sortable: true
    },
    {
        field: 'active',
        label: 'Ativo',
        dataType: 'boolean',
        sortable: true
    },
    {
        field: 'createdAt',
        label: 'Data de Criação',
        dataType: 'datetime',
        sortable: true
    },
    {
        field: 'code',
        label: 'Código',
        dataType: 'string',
        sortable: true
    },
    {
        field: 'name',
        label: 'Nome',
        dataType: 'string',
        sortable: true
    },
    {
        field: 'keys',
        label: 'Tipo',
        dataType: 'array',
        sortable: true,
        map: {
            ZipRange: 'Faixas de Frete',
            RadiusRange: 'Raio de Distância',
            CorreiosPac: 'PAC',
            CorreiosSedex: 'Sedex',
            CorreiosSedex10: 'Sedex 10',
            CorreiosSedex12: 'Sedex 12',
            CorreiosSedexHoje: 'Sedex Hoje',
        }
    }
];