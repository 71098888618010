import _ from 'lodash';

import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { Field } from 'redux-form';
import { Form, Button, Icon} from 'semantic-ui-react';

import FormSection from '../../../../Components/Card/FormSection';

import {
    renderInputInteger,
    renderInputCheckbox,
} from '../../../../Utils/RenderUtils';

import {
    normalizeInteger,
} from '../../../../Utils/NormalizeUtils';

export default function Tab02(props) {
    const formValues = useSelector(state => state.form[props.form].values);

    const changePosition = (index, shift) => {
        let newList = _.cloneDeep(formValues.properties);

        shift = index + shift;

        if(shift >= 0 && shift < formValues.properties.length){
            let shiftElement = newList[index];
            newList[index] = newList[shift];
            newList[shift] = shiftElement;
    
            props.change('properties', newList);
        }
    }

    const removeElement = (index) => {
        let newList = _.cloneDeep(formValues.properties);
        newList.splice(index, 1);
        props.change('properties', newList);
    }

    const renderPropertyField = (index) => {
        return (
            <Form.Group key={`properties-${index}`}>
                <Field name={`properties[${index}].name`} label="Nome" component={renderInputInteger} normalize={normalizeInteger} />
                <Field name={`properties[${index}].active`} label="Ativo" component={renderInputCheckbox} type="checkbox" />
                <Button.Group style={{maxHeight: '36px', marginTop: '18px'}}>
                    <Button icon onClick={(e) => {changePosition(index, -1); e.preventDefault()}}>
                        <Icon name='arrow up' />
                    </Button>
                    <Button icon onClick={(e) => {changePosition(index, 1); e.preventDefault()}}>
                        <Icon name='arrow down' />
                    </Button>
                    <Button icon color="red" onClick={(e) => {removeElement(index); e.preventDefault()}}>
                        <Icon name='times' />
                    </Button>
                </Button.Group>
            </Form.Group>
        )
    }

    return (
        <React.Fragment>
            <FormSection fluid color="green" title="Valores" key={`allproperties-${formValues.properties.length}`}>
                {
                    formValues.properties.map((property, index) => {
                        return renderPropertyField(index);
                    })
                }

                <Button positive onClick={(e) => {props.change('properties', _.concat(formValues.properties, [{active: true}])); e.preventDefault();}}>
                    <Icon name='list' />
                    Adicionar Propriedade
                </Button>
            </FormSection>
        </React.Fragment>
    )
}
