import { 
    List as GenericList,
    Create as GenericCreate,
    Read as GenericRead,
    Update as GenericUpdate,
    Delete  as GenericDelete
} from './Generic';

export const LIST = 'LIST_VOUCHER';
export const CREATE = 'CREATE_VOUCHER';
export const READ = 'READ_VOUCHER';
export const UPDATE = 'UPDATE_VOUCHER';
export const DELETE = 'DELETE_VOUCHER';
export const USE = 'USE_VOUCHER';

const PATH = '/voucher';

export const List = (query={}) => async (dispatch) => {
    const response = await GenericList(dispatch, PATH, query);
    return dispatch({ type: LIST, statusCode: response.status, payload: response.data });
}

export const Create = (formValues) => async (dispatch) => {
    const response = await GenericCreate(dispatch, PATH, formValues);
    return dispatch({ type: CREATE, statusCode: response.status, payload: response.data });
}

export const Read = (id) => async dispatch => {
    const response = await GenericRead(dispatch, PATH, id);
    return dispatch({ type: READ, statusCode: response.status, payload: response.data });
}

export const Update = (id, formValues) => async dispatch => {
    const response = await GenericUpdate(dispatch, PATH, id, formValues);
    return dispatch({ type: UPDATE, statusCode: response.status, payload: response.data });
}

export const Delete = (id) => async dispatch => {
    const response = await GenericDelete(dispatch, PATH, id);
    return dispatch({ type: DELETE, statusCode: response.status, payload: {uuid: id} });
}

export const Use = (id) => {
    return { type: USE, payload: {uuid: id} };
}